import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  MaxWidthText,
} from '@makeably/creativex-design-system';
import VideoInfo from 'components/atoms/VideoInfo';
import AssetModal from 'components/molecules/AssetModal';
import UuidDisplay from 'components/molecules/UuidDisplay';
import styles from './CoreAssetDetails.module.css';

export const coreAssetProps = {
  assetUrl: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  campaignName: PropTypes.string.isRequired,
  partner: PropTypes.string.isRequired,
  uuid: PropTypes.string.isRequired,
  videoLength: PropTypes.number,
  videoSource: PropTypes.string,
};

const propTypes = {
  activationDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.node.isRequired,
      value: PropTypes.node.isRequired,
    }),
  ).isRequired,
  coreAsset: PropTypes.shape(coreAssetProps).isRequired,
  isEditing: PropTypes.bool,
  moreButton: PropTypes.element,
};

const defaultProps = {
  isEditing: false,
  moreButton: undefined,
};

const coreAssetDetails = [
  {
    key: 'uuid',
    label: 'Core Asset ID',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'partner',
    label: 'Production Partner',
  },
  {
    key: 'campaignName',
    label: 'Campaign Name',
  },
];

function renderDetail(label, value) {
  const val = typeof value === 'object' ? value : <MaxWidthText size="custom" text={value} />;

  return (
    <div key={label} className={styles.detail}>
      <div className="t-label-1">{ label }</div>
      <h5 role="presentation">{ val }</h5>
    </div>
  );
}

function renderCoreAssetDetail(key, label, value) {
  const val = key === 'uuid' ? <UuidDisplay uuid={value} /> : value;

  return renderDetail(label, val);
}

function CoreAssetDetails({
  activationDetails,
  coreAsset,
  isEditing,
  moreButton,
}) {
  const [modalShown, setModalShown] = useState(false);

  return (
    <>
      <div className={styles.coreAsset}>
        <button
          className={styles.image}
          type="button"
          onClick={() => setModalShown(true)}
        >
          <img alt="Core Asset Preview" src={coreAsset.assetUrl} />
          <VideoInfo
            length={coreAsset.videoLength}
            showPlay
          />
        </button>
        <Card className={styles.card}>
          <div className={styles.more}>
            { moreButton }
          </div>
          <div className={styles.details}>
            <div className={styles.fullWidth}>
              <h5>Core Asset Details</h5>
            </div>
            { coreAssetDetails
              .map(({ key, label }) => renderCoreAssetDetail(key, label, coreAsset[key])) }
            <div className={styles.fullWidth}>
              <Divider />
            </div>
            <div className={styles.fullWidth}>
              <h5>Localization Details</h5>
            </div>
          </div>
          <div className={styles.localizations}>
            { activationDetails.map(({ label, value }) => renderDetail(label, value)) }
          </div>
          { isEditing && <div className={styles.overlay} /> }
        </Card>
      </div>
      <AssetModal
        isOpen={modalShown}
        url={coreAsset.assetUrl}
        videoSource={coreAsset.videoSource}
        onClose={() => setModalShown(false)}
      />
    </>
  );
}

CoreAssetDetails.propTypes = propTypes;
CoreAssetDetails.defaultProps = defaultProps;

export default CoreAssetDetails;
