import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  CopyButton,
  Icon,
  Spinner,
} from '@makeably/creativex-design-system';
import PostInfo, { propTypes as postInfoPropTypes } from 'components/admin/review_queue/post_info/PostInfo';
import EvaluationBanner from 'components/admin/review_queue/preflight/PreflightEvaluationBanner';
import ReviewGuide, { guideProps } from 'components/internal/review_guides/ReviewGuide';
import styles from './CheckDisplay.module.css';

export const checkProps = PropTypes.shape({
  brand: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  customer: PropTypes.string.isRequired,
  definition: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  isBranded: PropTypes.bool.isRequired,
  market: PropTypes.string.isRequired,
  postInfo: PropTypes.shape(postInfoPropTypes).isRequired,
  reviewGuides: PropTypes.arrayOf(guideProps).isRequired,
  reviewability: PropTypes.string.isRequired,
  rule: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

export const propTypes = {
  check: checkProps.isRequired,
  isBusy: PropTypes.bool.isRequired,
  needsValidation: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  onNeedsValidationChange: PropTypes.func.isRequired,
  onPassedChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isPreflight: PropTypes.bool,
  passed: PropTypes.bool,
  showPreflightBanner: PropTypes.bool,
  onAssetReady: PropTypes.func,
};

const defaultProps = {
  disabled: false,
  isPreflight: false,
  onAssetReady: undefined,
  passed: null,
  showPreflightBanner: false,
};

const details = [
  {
    key: 'customer',
    label: 'Customer',
  },
  {
    key: 'id',
    label: 'Guideline Check ID',
  },
  {
    key: 'auditAssetId',
    label: 'Audit Asset ID',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
  {
    key: 'type',
    label: 'Type',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'reviewability',
    label: 'Reviewability',
  },
];

function renderDetail(key, label, check) {
  return (
    <div key={key}>
      <div className={`t-overline ${styles.label}`}>
        { label }
      </div>
      <h4>{ check[key] }</h4>
    </div>
  );
}

function renderIcon(passed) {
  switch (passed) {
    case true:
      return <Icon color="green" name="checkCircle" noWrapper />;
    case false:
      return <Icon color="red" name="xCircle" noWrapper />;
    default:
      return <Icon color="orange" name="questionCircle" noWrapper />;
  }
}

function CheckDisplay({
  check,
  disabled,
  needsValidation,
  isBusy,
  isPreflight,
  passed,
  onAssetReady,
  onNeedsValidationChange,
  onPassedChange,
  url,
}) {
  const {
    definition,
    description,
    isBranded,
    postInfo,
    reviewGuides,
    rule,
  } = check;

  const handleClick = () => {
    if (needsValidation) {
      onNeedsValidationChange(false);
    } else {
      onPassedChange(!passed);
    }
  };

  const buttonClasses = classNames(
    styles.evalButton,
    { [styles.needsValidation]: needsValidation },
  );

  return (
    <div className={styles.content}>
      <div className={styles.postInfo}>
        <PostInfo {...postInfo} onAssetReady={onAssetReady} />
      </div>
      <div className={styles.data}>
        <div className={styles.link}>
          <a href={url} rel="noreferrer" target="_blank">Link</a>
          <CopyButton text={url} />
        </div>
        <div className={styles.details}>
          { details.map(({ key, label }) => renderDetail(key, label, check)) }
        </div>
        <div className={styles.passed}>
          <div>
            <button
              className={buttonClasses}
              disabled={disabled || isBusy}
              type="button"
              onClick={handleClick}
            >
              { renderIcon(passed) }
            </button>
          </div>
          <div>
            <h5>{ `${rule}${definition ? '-' : ''}${definition ?? ''}` }</h5>
            <div className={styles.description}>
              <div className={styles.descriptionText}>
                { description }
              </div>
              <div>
                <CopyButton text={description} />
              </div>
            </div>
          </div>
        </div>
        { isPreflight && isBranded && <EvaluationBanner /> }
        { reviewGuides.length > 0 && (
          <div className={styles.reviewGuides}>
            { reviewGuides.map((guide) => (
              <ReviewGuide
                key={guide.id}
                guide={guide}
                initialIsOpen
              />
            )) }
          </div>
        ) }
      </div>
      { isBusy && (
        <div className={styles.busy}>
          <div className={styles.spinner}>
            <Spinner />
          </div>
        </div>
      ) }
    </div>
  );
}

CheckDisplay.propTypes = propTypes;
CheckDisplay.defaultProps = defaultProps;

export default CheckDisplay;
