import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Tooltip,
} from '@makeably/creativex-design-system';
import styles from './Notifier.module.css';

const buttonPropTypes = {
  action: PropTypes.oneOf(['link', 'callback']),
  actionItem: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  buttonText: PropTypes.string,
};

const propTypes = {
  body: PropTypes.node.isRequired,
  buttonProps: PropTypes.shape(buttonPropTypes).isRequired,
  subtitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  backButtonProps: PropTypes.shape(buttonPropTypes),
  titleIcon: PropTypes.node,
  tooltip: PropTypes.string,
};

const defaultProps = {
  backButtonProps: undefined,
  titleIcon: undefined,
  tooltip: undefined,
};

function renderButton(buttonProps, style) {
  const {
    action,
    actionItem,
    buttonText,
  } = buttonProps;

  const variant = style === 'primary' ? 'primary' : 'tertiary';

  if (action === 'link') {
    return (
      <a href={actionItem}>
        <Button
          label={buttonText}
          variant={variant}
        />
      </a>
    );
  }

  return (
    <Button
      label={buttonText}
      variant={variant}
      onClick={actionItem}
    />
  );
}

function Notifier({
  backButtonProps,
  body,
  buttonProps,
  subtitle,
  title,
  tooltip,
  titleIcon,
}) {
  return (
    // TODO: Replace with design system Card component
    <div className={`u-elevation-1 ${styles.container}`}>
      <div className="u-flexRow u-flexAlignCenter">
        { titleIcon }
        <h5 className="u-marginHorizontalSm">{ title }</h5>
        { tooltip && <Tooltip label={tooltip} /> }
      </div>
      <div className="t-subtitle">{ subtitle }</div>
      <div className={styles.contentContainer}>
        { body }
      </div>
      <div className={styles.buttonContainer}>
        { backButtonProps && renderButton(backButtonProps, 'back') }
        { renderButton(buttonProps, 'primary') }
      </div>
    </div>
  );
}

Notifier.propTypes = propTypes;
Notifier.defaultProps = defaultProps;

export default Notifier;
