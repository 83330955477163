import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  TextArea,
  Toggle,
  Tooltip,
} from '@makeably/creativex-design-system';
import HelperTextInput from 'components/molecules/HelperTextInput';
import styles from './ScoreDetailInputs.module.css';

const propTypes = {
  canEditVisibility: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  qualityRate: PropTypes.string.isRequired,
  qualitySpendRate: PropTypes.string.isRequired,
  scoreDenyList: PropTypes.arrayOf(PropTypes.string).isRequired,
  setIsNextDisabled: PropTypes.func.isRequired,
  showInDashboard: PropTypes.bool.isRequired,
  showInReporting: PropTypes.bool.isRequired,
  showInScorecard: PropTypes.bool.isRequired,
  onDescriptionChange: PropTypes.func.isRequired,
  onLabelChange: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onQualityRateChange: PropTypes.func.isRequired,
  onQualitySpendRateChange: PropTypes.func.isRequired,
  onShowInDashboardChange: PropTypes.func.isRequired,
  onShowInReportingChange: PropTypes.func.isRequired,
  onShowInScorecardChange: PropTypes.func.isRequired,
  initialScoreValues: PropTypes.shape({
    description: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    qualityRate: PropTypes.string,
    qualitySpendRate: PropTypes.string,
    showInDashboard: PropTypes.bool,
    showInReporting: PropTypes.bool,
    showInScorecard: PropTypes.bool,
  }),
  isDefault: PropTypes.bool,
  isEditing: PropTypes.bool,
  isStandard: PropTypes.bool,
};

const defaultProps = {
  initialScoreValues: {
    description: undefined,
    label: undefined,
    name: undefined,
    qualityRate: undefined,
    qualitySpendRate: undefined,
    showInDashboard: undefined,
    showInReporting: undefined,
    showInScorecard: undefined,
  },
  isDefault: false,
  isEditing: false,
  isStandard: false,
};

function getErrors(isError) {
  if (!isError) return [];

  return [{ value: 'The suggested name is already in use. Please rename.' }];
}

function ScoreDetailInputs({
  canEditVisibility,
  description,
  initialScoreValues,
  isDefault,
  isEditing,
  isStandard,
  label,
  name,
  qualityRate,
  qualitySpendRate,
  setIsNextDisabled,
  scoreDenyList,
  showInDashboard,
  showInReporting,
  showInScorecard,
  onDescriptionChange,
  onLabelChange,
  onNameChange,
  onQualityRateChange,
  onQualitySpendRateChange,
  onShowInDashboardChange,
  onShowInReportingChange,
  onShowInScorecardChange,
}) {
  const [advancedVisible, setAdvancedVisible] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);
  const [qualityRateChanged, setQualityRateChanged] = useState(false);
  const [qualitySpendRateChanged, setQualitySpendRateChanged] = useState(false);

  const validateItem = (item, referenceList) => {
    if (isStandard) return false;

    const itemLowerCase = item.toLowerCase();
    const referenceListLowerCase = referenceList.map((v) => v.toLowerCase());
    const isDenied = scoreDenyList.includes(itemLowerCase);
    const isUsed = itemLowerCase !== '' && referenceListLowerCase.includes(itemLowerCase);
    return isDenied || isUsed;
  };

  const labelError = validateItem(label, [name, qualityRate, qualitySpendRate]);
  const nameError = validateItem(name, [label, qualityRate, qualitySpendRate]);
  const qualityRateError = validateItem(qualityRate, [label, name, qualitySpendRate]);
  const qualitySpendRateError = validateItem(qualitySpendRate, [label, name, qualityRate]);

  const inputEmpty = [label, name, qualityRate, qualitySpendRate].some((v) => v === '');
  const inputNotChanged = (
    initialScoreValues.label === label
      && initialScoreValues.description === description
      && initialScoreValues.name === name
      && initialScoreValues.qualityRate === qualityRate
      && initialScoreValues.qualitySpendRate === qualitySpendRate
      && initialScoreValues.showInDashboard === showInDashboard
      && initialScoreValues.showInReporting === showInReporting
      && initialScoreValues.showInScorecard === showInScorecard
  );

  const nextDisabled = (
    labelError
      || nameError
      || qualityRateError
      || qualitySpendRateError
      || inputEmpty
      || inputNotChanged
  );

  useEffect(() => {
    setIsNextDisabled(nextDisabled);
  }, [
    label,
    description,
    name,
    qualityRate,
    qualitySpendRate,
    showInDashboard,
    showInReporting,
    showInScorecard,
  ]);

  const onScoreLabelChange = (newLabel) => {
    onLabelChange(newLabel);

    if (isEditing) return;

    if (!nameChanged) onNameChange(`${newLabel} Score`);
    if (!qualityRateChanged) onQualityRateChange(`${newLabel}: Quality Rate`);
    if (!qualitySpendRateChanged) onQualitySpendRateChange(`${newLabel}: Quality Spend Rate`);
  };

  const onScoreNameChange = (newName) => {
    onNameChange(newName);
    setNameChanged(true);
  };

  const onRateChange = (newRate) => {
    onQualityRateChange(newRate);
    setQualityRateChanged(true);
  };

  const onSpendRateChange = (newSpendRate) => {
    onQualitySpendRateChange(newSpendRate);
    setQualitySpendRateChanged(true);
  };

  return (
    <div className={styles.container}>
      { !isStandard && (
        <>
          <HelperTextInput
            helperText={getErrors(labelError)}
            isError={labelError}
            label="Label"
            placeholder=""
            size="large"
            value={label}
            onChange={onScoreLabelChange}
          />
          <TextArea
            label="Description"
            placeholder="Optional"
            size="large"
            value={description}
            onChange={(newDescription) => onDescriptionChange(newDescription)}
          />
        </>
      ) }
      { canEditVisibility && (
        <div className="u-flexColumn u-gap-16">
          <div className="u-flexRow u-gap-8 u-alignCenter">
            <Toggle
              checked={showInDashboard}
              disabled={isDefault}
              label="Show in Dashboards"
              right
              onChange={(e) => onShowInDashboardChange(e.target.checked)}
            />
            <Tooltip label="This score will be available in Dashboards." />
          </div>
          <div className="u-flexRow u-gap-8 u-alignCenter">
            <Toggle
              checked={showInScorecard}
              disabled={isDefault}
              label="Show in Pre-Flight Scorecard PDF"
              right
              onChange={(e) => onShowInScorecardChange(e.target.checked)}
            />
            <Tooltip label="This score will be available in Pre-Flight PDF Scorecards." />
          </div>
        </div>
      ) }
      <>
        <div className={styles.settingsHeader}>
          <Button
            iconLeft="settingsGear"
            iconRight={advancedVisible ? 'chevronDown' : 'chevronUp'}
            label="Advanced Metric Settings"
            variant="tertiary"
            onClick={() => setAdvancedVisible((prevState) => !prevState)}
          />
        </div>
        { advancedVisible && (
          <div className={styles.settingsBody}>
            { canEditVisibility && (
              <div className="u-flexRow u-gap-8 u-alignCenter">
                <Toggle
                  checked={showInReporting}
                  disabled={isDefault}
                  label="Show in Reporting"
                  right
                  onChange={(e) => onShowInReportingChange(e.target.checked)}
                />
                <Tooltip label="This score will be hidden from the Creatives page and all reports, including exports and API integrations." />
              </div>
            ) }
            { !isStandard && (
              <>
                <HelperTextInput
                  helperText={getErrors(nameError)}
                  isError={nameError}
                  label="Custom Score Name"
                  size="large"
                  value={name}
                  onChange={onScoreNameChange}
                />
                <HelperTextInput
                  helperText={getErrors(qualityRateError)}
                  isError={qualityRateError}
                  label="Custom Quality Rate"
                  size="large"
                  value={qualityRate}
                  onChange={onRateChange}
                />
                <HelperTextInput
                  helperText={getErrors(qualitySpendRateError)}
                  isError={qualitySpendRateError}
                  label="Custom Quality Spend Rate"
                  size="large"
                  value={qualitySpendRate}
                  onChange={onSpendRateChange}
                />
              </>
            ) }
          </div>
        ) }
      </>
    </div>
  );
}

ScoreDetailInputs.propTypes = propTypes;
ScoreDetailInputs.defaultProps = defaultProps;

export default ScoreDetailInputs;
