// Helper functions for reading and writing data to and from files, including encoding
import { getItemsCsv } from 'utilities/item';
import { dateToString } from 'utilities/string';

export function loadFiles(accept) {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept;
    input.multiple = true;

    input.onchange = () => {
      resolve([...input.files]);
    };

    input.click();
  });
}

export function loadFile(accept) {
  return new Promise((resolve) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = accept;

    input.onchange = () => {
      resolve(input.files[0]);
    };

    input.click();
  });
}

export function loadTextFile(accept) {
  return loadFile(accept).then((file) => (
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };

      reader.readAsText(file);
    })
  ));
}

export function saveUrlFile(url, name) {
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = url;
  link.download = name;

  // It needs to be added to the DOM so it can be clicked
  document.body.appendChild(link);
  link.click();

  // To make this work on Firefox we need to wait
  // a little while before removing it.
  setTimeout(() => {
    URL.revokeObjectURL(link.href);
    link.parentNode.removeChild(link);
  }, 0);
}

export function saveFile(filePath, text) {
  const type = 'text/plain';
  const blob = new Blob([text], { type });
  const url = URL.createObjectURL(blob);

  saveUrlFile(url, filePath);
}

export function saveItemsCsvFile(title, items, headers) {
  const date = dateToString(new Date());
  const fileName = `${title}_${date}.csv`;
  const csv = getItemsCsv(items, headers);

  saveFile(fileName, csv);
}
