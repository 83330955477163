import React from 'react';
import PropTypes from 'prop-types';
import { MultipleDropdown } from '@makeably/creativex-design-system';
import Slider from 'components/molecules/Slider';
import styles from 'components/filters/FilterDropdowns.module.css';
import { titleize } from '../../utilities/string';

const optionProps = PropTypes.shape({
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  label: PropTypes.string,
});
const dropdownProps = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(optionProps).isRequired,
});
const propTypes = {
  filters: PropTypes.arrayOf(dropdownProps).isRequired,
  selections: PropTypes.objectOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
    ),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

const allOption = {
  value: 'all',
  label: 'All',
};

// Returns array of values
function updateSelection(allOptions, existing, selected) {
  if (selected === allOption.value) {
    // Click on All, select every other value
    return allOptions.filter((value) => value !== allOption.value);
  } if (existing.includes(selected)) {
    // Removing a selected value
    return existing.filter((value) => value !== selected);
  }
  // Selecting a value
  return [...existing, selected];
}

function parseSliderValue(value, display) {
  const defaultValue = [display.min, display.max];

  if (!value) {
    return defaultValue;
  }
  if (Array.isArray(value)) {
    if (value.length === 0) {
      return defaultValue;
    }
    return value.map((str) => parseInt(str));
  }
  return parseInt(value);
}

function formatSliderValue(value) {
  if (Array.isArray(value)) {
    return value.map((num) => num.toString());
  }
  return value.toString();
}

function renderSlider(filter, selections, onChange) {
  const {
    key,
    label,
    display,
  } = filter;
  const existingSelections = selections[key] ?? [];

  const sliderOptions = {
    ...display,
    label,
  };
  const sliderValue = parseSliderValue(existingSelections, display);

  return (
    <div key={key} className={styles.slider}>
      <Slider
        key={key}
        options={sliderOptions}
        value={sliderValue}
        onChange={(value) => onChange(key, formatSliderValue(value))}
      />
    </div>
  );
}

function renderDropdown({
  key, label, options,
}, selections, onChange) {
  const existingSelections = selections[key] ?? [];

  let activeOption;
  if (existingSelections.length > 0) {
    activeOption = options.filter(({ value }) => existingSelections.includes(value));

    if (activeOption.length === 0) activeOption = [allOption];
  } else {
    activeOption = [allOption];
  }

  const allOptions = [allOption, ...options];
  const allOptionValues = allOptions.map(({ value }) => value);

  return (
    <div key={key} className="u-marginRight">
      <MultipleDropdown
        label={titleize(label)}
        options={allOptions}
        selected={activeOption}
        size="small"
        search
        onChange={
          (option) => (
            onChange(key, updateSelection(allOptionValues, existingSelections, option.value))
          )
        }
      />
    </div>
  );
}

function renderFilter(filter, selections, onChange) {
  if (filter.display?.type === 'slider') {
    return renderSlider(filter, selections, onChange);
  }
  return renderDropdown(filter, selections, onChange);
}

function renderClearButton(filters, handleFilterClear) {
  if (filters.length === 0) {
    return null;
  }

  return (
    <button
      className={styles.clearButton}
      type="button"
      onClick={() => handleFilterClear()}
    >
      Clear All
    </button>
  );
}

function FilterDropdowns({
  filters,
  selections,
  onChange,
}) {
  const handleFilterClear = () => {
    filters.map((filter) => onChange(filter.key, []));
  };

  return (
    <div className="u-flexRow">
      { filters.map((filter) => renderFilter(filter, selections, onChange)) }
      { renderClearButton(filters, handleFilterClear) }
    </div>
  );
}

FilterDropdowns.propTypes = propTypes;

export default FilterDropdowns;
