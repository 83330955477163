import React from "react"
import PropTypes from "prop-types"
import AuditFilter from "components/audit/audit_filter"
import {
  Button as DuikButton,
  Select
} from  "@duik/it"
import {
  Button,
  Icon,
} from '@makeably/creativex-design-system'
import MixpanelTracker from "components/utils/MixpanelTracker"
import styles from './AuditFilterCollection.module.css'

class AuditFilterCollection extends React.Component
  @propTypes =
    authenticityToken: PropTypes.string
    dateRangeProps: PropTypes.object
    defaultFilters: PropTypes.arrayOf(PropTypes.object) # Objects are AuditFilter props
    existingFilters: PropTypes.arrayOf(PropTypes.object) # Objects are AuditFilter props
    filterOptions: PropTypes.arrayOf(
      PropTypes.shape(
        filters: PropTypes.arrayOf(
          PropTypes.shape(
            disabled: PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.bool,
            ])
            inputName: PropTypes.string
            options: PropTypes.oneOfType([
              PropTypes.array,
              PropTypes.object
            ])
            type: PropTypes.oneOfType([
              PropTypes.number,
              PropTypes.string
            ])
            value: PropTypes.oneOfType([
              PropTypes.number,
              PropTypes.string
            ])
          )
        ),
        optgroup: PropTypes.string
      )
    )
    filtersForExports: PropTypes.bool
    formProps: PropTypes.object
    redirectOptions: PropTypes.object
    startWithFilter: PropTypes.bool

  @defaultProps =
    defaultFilters: undefined
    existingFilters: []
    filtersForExports: false
    startWithFilter: true

  constructor: (props) ->
    super(props)

    selectedDateRange =
      if props.dateRangeProps
        props.dateRangeProps.selected
      else
        undefined

    filters =
      if props.existingFilters.length >= 1
        props.existingFilters
      else if props.filtersForExports
        [
          {
            id: 1
            inputOptions: []
            options: props.filterOptions
          }
        ]
      else
        []

    allowFilterAdd =
      if props.filtersForExports && props.existingFilters.length is 0
        false
      else
        true


    filterCounts = props.filterOptions.map(({ filters }) -> filters.length)
    @totalPossibleFilters = filterCounts.reduce((sum, count) -> sum + count)

    @state =
      allowFilterAdd: allowFilterAdd
      filters: filters
      dateRange: selectedDateRange

  # NB: We only want to submit the form if you have removed an existing
  # (persisted) filter If you remove a non-persisted filter, we simply remove
  # it from the DOM
  submitForm: (removedExistingFilter) =>
    =>
      # requestSubmit() generates the submit event that the loader watches for
      document.getElementById(@props.formProps.id)?.requestSubmit() if removedExistingFilter

  addFilter: =>
    filterId = @state.filters.length + 1
    newFilter =
      id: filterId
      inputOptions: []
      options: @props.filterOptions

    newFilters = $.map(@state.filters, (obj) -> $.extend(true, {}, obj))
    newFilters.push(newFilter)

    if @props.filtersForExports
      @setState(filters: newFilters)
    else
      @setState(filters: newFilters, allowFilterAdd: false)

  removeFilter: (filterId) =>
    filters = $.map(@state.filters, (obj) -> $.extend(true, {}, obj))
    filter = _.findWhere(filters, { id: filterId })
    filter.removed = true

    @setState(filters: filters, allowFilterAdd: true, @submitForm(filter.existing && !@props.filtersForExports))

  removeAllFilters: =>
    filters = $.map(@state.filters, (obj) -> $.extend(true, {}, obj, { removed: true }))
    anyPersistedFilters = @state.filters.filter((obj) -> obj.existing).length > 0

    @setState(filters: filters, allowFilterAdd: true, @submitForm(anyPersistedFilters))

  restoreDefaultFilters: =>
    @setState(filters: @props.defaultFilters, allowFilterAdd: true, @submitForm(true))

  updateInputOptions: (filterId, value, type) =>
    filters = $.map(@state.filters, (obj) -> $.extend(true, {}, obj))
    filter = _.findWhere(filters, { id: filterId })

    if type is 'filterValue'
      filter.selectedInput = value
    else
      allFilterOptions = _.map(@props.filterOptions, (option) -> option.filters).flatten()
      filterOption = _.findWhere(allFilterOptions, { value: value })

      # NB: Let's clear the selectedInput when we change filter type
      filter.selectedInput = undefined
      filter.type = filterOption.type
      filter.selected = value
      filter.inputOptions = filterOption.options
      filter.inputName = filterOption.inputName
      filter.searchByLabel = filterOption.searchByLabel

    @setState(filters: filters)

  onDateChange: (selectedValue) =>
    dateRange = selectedValue.value

    MixpanelTracker.trackFilterSelection('Date', dateRange)
    if @props.filtersForExports
      @setState(dateRange: dateRange)
    else
      @setState(dateRange: dateRange, @submitForm(true))

  renderDateRangeHiddenInput: ->
    return unless @state.dateRange
    `<input type="hidden" name="date_range" value={this.state.dateRange}/>`

  renderAddFilterButton: ->
    return unless @state.allowFilterAdd

    `<DuikButton
      className='dimensionFilter-actionButton'
      sm
      square
      onClick={this.addFilter}
      type='button'
      data-testid='auditFilters:plus'
    >
      <Icon name='plus' color='darkPurple' noWrapper/>
    </DuikButton>`

  renderClearAllFiltersButton: ->
    return unless @state.filters.filter((obj) -> obj.existing).length > 1
    return if @props.filtersForExports

    `<DuikButton
      className='dimensionFilter-clearButton'
      onClick={this.removeAllFilters}
      type='button'
    >
      Clear All
    </DuikButton>`

  renderApplyDefaultFiltersButton: ->
    return if @props.filtersForExports
    return unless @props.defaultFilters && @state.filters.filter((obj) -> obj.existing || !obj.removed).length == 0

    `<DuikButton
      className='dimensionFilter-defaultFiltersButton'
      onClick={this.restoreDefaultFilters}
      type='button'
    >
      Apply Default Filters
    </DuikButton>`

  renderFilter: (filter, idx) =>
    return if filter.removed
    disabledOptions = _.without(
      _.pluck(
        _.reject(@state.filters, (filter) -> filter.removed),
        'selected'
      ),
      filter.selected
    )

    `<AuditFilter
      key={idx}
      addFilter={this.addFilter}
      disabledOptions={disabledOptions}
      onChange={this.updateInputOptions}
      onRemove={this.removeFilter}
      filtersForExports={this.props.filtersForExports}
      {...filter}
    />`

  renderDateSelector: (menuPosition = 'bottom-left') ->
    return unless @props.dateRangeProps
    # TODO: This is a hack for optgroups, no longer supported
    options = _.flatten(_.pluck(@props.dateRangeProps.options, 'options'))
    activeOption = _.findWhere(options, value: @state.dateRange)

    selectProps =
      activeOption: activeOption
      className: 'dimensionFilterDateSelect'
      menuPosition: menuPosition
      onOptionClick: @onDateChange
      options: options
      placeholder: @props.dateRangeProps.placeholder

    `<Select {...selectProps}/>`

  renderRedirectOption: (value, optionKey) ->
    name = "redirect_options[#{optionKey}]"
    `<input key={optionKey} type="hidden" name={name} value={value}/>`

  renderCsvExportFilterContent: ->
    activeFilters = @state.filters.filter((f) -> !f.removed)
    allFiltersCompleted = activeFilters.every((f) ->
      selectionFilledOut =
        if typeof f.selectedInput is 'string'
          f.selectedInput
        else
          f.selectedInput and f.selectedInput.length > 0

      f.selected and selectionFilledOut
    )
    allowNewFilter = activeFilters.length < @totalPossibleFilters

    `<div>
      <div className={styles.datePicker}>
        <div className='u-marginRight'>
          <Icon name='calendar' color='darkPurple'/>
        </div>
        <div>
          { this.renderDateSelector('bottom-right') }
        </div>
      </div>
      <div className='u-marginBelow'>
        <div className='u-flexRow'>
          <div className='u-marginRight u-marginAboveSm'>
            <Icon name='filter' color='darkPurple'/>
          </div>
          <div className={styles.filters}>
            { this.state.filters.map(this.renderFilter) }
          </div>
        </div>
        { allowNewFilter &&
          <Button
            disabled={!allFiltersCompleted}
            iconLeft='plus'
            label='Add New'
            variant='tertiary'
            onClick={this.addFilter}
          />
        }
      </div>
    </div>`

  renderContent: ->
    `<div className='dimensionFilterContainer'>
      { _.map(this.props.redirectOptions, this.renderRedirectOption) }
      <Icon name='filter' color='darkPurple'/>
      <div className='dimensionFilterContainer-filterSection'>
        <div className='dimensionFilterContainer-filters'>
          {this.state.filters.map(this.renderFilter)}
        </div>
        {this.renderAddFilterButton()}
        {this.renderClearAllFiltersButton()}
        {this.renderApplyDefaultFiltersButton()}
      </div>
      {this.renderDateSelector()}
    </div>`

  render: ->
    content = if @props.filtersForExports then @renderCsvExportFilterContent() else @renderContent()

    `<div className='row auditReport-filterContainer'>
      <div className='col s12'>
        <form {...this.props.formProps} method='post'>
          <input type="hidden" name="authenticity_token" value={this.props.authenticityToken}/>
          { this.renderDateRangeHiddenInput() }
          { content }
        </form>
      </div>
    </div>`

export default AuditFilterCollection
