import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  TextInput,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import {
  destroy,
  patch,
} from 'utilities/requests';
import {
  adminIndustriesPath,
  adminIndustryPath,
} from 'utilities/routes';
import styles from './IndustryConfiguration.module.css';

const propTypes = {
  id: PropTypes.number.isRequired,
  isEditable: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

async function updateIndustry(id, name) {
  const url = adminIndustryPath(id);

  const formData = new FormData();
  formData.append('name', name);

  const response = await patch(url, formData);
  return response.data;
}

async function deleteIndustry(id) {
  const url = adminIndustryPath(id);

  const response = await destroy(url);
  return response.data;
}

function IndustryConfiguration({
  id,
  isEditable,
  name: initialName,
}) {
  const [name, setName] = useState(initialName);

  const onSave = async () => {
    const data = await updateIndustry(id, name);

    if (data.success) {
      addToast('Industry name successfully updated!');
    } else {
      addToast('Industry name already exists', { type: 'error' });
    }

    window.location.href = adminIndustriesPath();
  };

  const onDelete = async () => {
    const data = await deleteIndustry(id);

    if (data.success) {
      addToast('Industry successfully deleted');
    } else {
      addToast('Industry already applied to company/brand. Cannot be deleted', { type: 'error' });
    }

    window.location.href = adminIndustriesPath();
  };

  return (
    <Card className={styles.card}>
      <div className="u-flexColumn u-gap-16">
        <h5>Edit Industry</h5>
        <Divider />
        <TextInput
          disabled={!isEditable}
          label="Name"
          placeholder="Industry Name"
          size="large"
          value={name}
          onChange={(value) => setName(value)}
        />
        <div className="u-flexRow u-justifyEnd">
          <div className="u-buttonGroup">
            <Button
              disabled={name && name === initialName}
              label="Edit Name"
              onClick={onSave}
            />
            <Button
              disabled={name && !isEditable}
              label="Delete"
              variant="destructive"
              onClick={onDelete}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

IndustryConfiguration.propTypes = propTypes;

export default IndustryConfiguration;
