import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  MaxWidthText,
  MoreButton,
  Divider,
} from '@makeably/creativex-design-system';
import ActionButton from 'components/molecules/ActionButton';
import { addToast } from 'components/organisms/Toasts';
import EditReportDetailsModal from 'components/reporting/EditReportDetailsModal';
import { arrayIf } from 'utilities/array';
import { track } from 'utilities/mixpanel';
import {
  patch,
  post,
} from 'utilities/requests';
import {
  reportingReportsPath,
  reportingReportPath,
} from 'utilities/routes';
import styles from './ConfigureReport.module.css';

const propTypes = {
  hasChanged: PropTypes.bool.isRequired,
  initialDescription: PropTypes.string.isRequired,
  initialTitle: PropTypes.string.isRequired,
  propertiesJson: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onExportCsv: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  uuid: PropTypes.string,
};

const defaultProps = {
  uuid: undefined,
};

async function updateReport(report) {
  const response = await patch(reportingReportPath(report.uuid), report);

  if (!response.isError) return response.data.uuid;

  return null;
}

async function createReport(report) {
  const response = await post(reportingReportsPath(), report);

  if (!response.isError) return response.data.uuid;

  return null;
}

function ConfigureReport({
  hasChanged,
  initialDescription,
  initialTitle,
  onExportCsv,
  onSave,
  propertiesJson,
  type,
  uuid,
}) {
  const [title, setTitle] = useState(initialTitle);
  const [description, setDescription] = useState(initialDescription);
  const [modalOpen, setModalOpen] = useState(false);
  const [reportSaving, setReportSaving] = useState(false);
  const reportChanged = hasChanged || title !== initialTitle || description !== initialDescription;

  const editReport = (updatedFields) => {
    setTitle(updatedFields.title);
    setDescription(updatedFields.description);
  };

  const saveReport = async ({ duplicate = false }) => {
    let reportUuid = null;
    let trackEventName;

    setReportSaving(true);
    if (uuid && !duplicate) {
      reportUuid = await updateReport({
        uuid,
        title,
        description,
        properties: propertiesJson,
      });

      trackEventName = 'save_report';
    } else {
      const savedTitle = (duplicate && title === initialTitle) ? `Copy of ${title}` : title;

      reportUuid = await createReport({
        title: savedTitle,
        description,
        properties: propertiesJson,
        type,
      });

      trackEventName = duplicate ? 'duplicate_report' : 'save_new_report';
    }

    if (reportUuid) {
      addToast('Report Saved');
      track(trackEventName, {
        type,
        uuid: reportUuid,
      });
      onSave(reportUuid);
    } else {
      addToast('Something went wrong, please try again', {
        size: 'large',
        type: 'error',
      });
    }
    setReportSaving(false);
  };

  const handleExportCsv = () => {
    track('export_csv', {
      type,
      uuid,
    });
    onExportCsv(title);
  };

  // Only show "Save as New" when editing an existing item
  const moreButtonOptions = [
    ...arrayIf(
      uuid,
      {
        label: 'Save as New',
        onClick: () => saveReport({ duplicate: true }),
      },
    ),
    {
      label: 'Export CSV',
      onClick: handleExportCsv,
    },
  ].filter(Boolean);

  return (
    <>
      <div className={styles.container}>
        <div className={styles.details}>
          <h5 className={styles.title}>{ title }</h5>
          { description !== '' && (
            <div className={styles.description}>
              <MaxWidthText
                className="t-caption-1"
                size="custom"
                text={description}
              />
            </div>
          ) }
          <Button
            ariaLabel={`Edit ${title}`}
            label="Edit"
            variant="tertiary"
            onClick={() => setModalOpen(true)}
          />
        </div>
        <div className={styles.buttons}>
          <MoreButton
            menuSize="small"
            options={moreButtonOptions}
          />
          <Divider vertical />
          <ActionButton
            active={reportSaving}
            disabled={!reportChanged}
            label={(reportChanged || !uuid) ? 'Save' : 'Saved'}
            variant="primary"
            onClick={saveReport}
          />
        </div>
      </div>
      <EditReportDetailsModal
        description={description}
        isOpen={modalOpen}
        title={title}
        onClose={() => setModalOpen(false)}
        onReportEdit={editReport}
      />
    </>
  );
}

ConfigureReport.propTypes = propTypes;
ConfigureReport.defaultProps = defaultProps;

export default ConfigureReport;
