// Renders filter options for a user list
import React from 'react';
import PropTypes from 'prop-types';
import DeprecatedTag from 'components/internal/DeprecatedTag';
import SearchBar, {
  propTypes as searchBarProps,
} from 'components/molecules/SearchBar';
import NavDropdown, {
  propTypes as navProps,
} from 'components/navigation/NavDropdown';
import SearchableNavDropdown, {
  propTypes as searchableNavProps,
} from 'components/navigation/SearchableNavDropdown';
import { internalExplorerHomePath } from 'utilities/routes';

const propTypes = {
  partnerSelectProps: PropTypes.shape(searchableNavProps).isRequired,
  profileSelectProps: PropTypes.shape(navProps).isRequired,
  companySelectProps: PropTypes.shape(searchableNavProps),
  searchProps: PropTypes.shape(searchBarProps),
};

const defaultProps = {
  companySelectProps: undefined,
  searchProps: undefined,
};

function renderSearchableNav({
  activeOption, label, options,
}) {
  return (
    <SearchableNavDropdown
      activeOption={activeOption}
      className="u-marginRight"
      label={label}
      options={options}
    />
  );
}

function UserFilters(props) {
  const {
    companySelectProps,
    partnerSelectProps,
    profileSelectProps,
    searchProps,
  } = props;

  return (
    <div className="u-flexRow u-justifySpaceBetween u-flexAlignEnd">
      <div className="u-flexRow u-flexAlignFlexEnd">
        { companySelectProps
            && (
              <div className="u-marginRight">
                { renderSearchableNav(companySelectProps) }
              </div>
            ) }
        { partnerSelectProps
            && (
              <div className="u-marginRight">
                { renderSearchableNav(partnerSelectProps) }
              </div>
            ) }
        { profileSelectProps
            && (
              <div>
                <NavDropdown
                  activeOption={profileSelectProps.activeOption}
                  label={profileSelectProps.label}
                  options={profileSelectProps.options}
                />
              </div>
            ) }
      </div>
      { searchProps
          && (
            <div className="u-flexRow u-alignCenter">
              <DeprecatedTag
                date="2025-01-02"
                level="feature"
                name="Global User Search"
                replacementUrl={internalExplorerHomePath({ search_by: 'user' })}
              />
              <SearchBar
                className="u-marginLeft"
                formActionLink={searchProps.formActionLink}
                placeholder={searchProps.placeholder}
                searchValue={searchProps.searchValue}
              />
            </div>
          ) }
    </div>
  );
}

UserFilters.propTypes = propTypes;
UserFilters.defaultProps = defaultProps;

export default UserFilters;
