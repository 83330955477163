import React from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  Card,
} from '@makeably/creativex-design-system';
import DataTable from 'components/reusable/data_table.coffee';
import { newAdminCompanyContractPath } from 'utilities/routes';

const propTypes = {
  companyId: PropTypes.number.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    }),
  ).isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        format: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      }),
    ),
  ).isRequired,
};

function renderContent(headers, rows) {
  if (rows.length === 0) return null;

  return (
    <DataTable
      headers={headers}
      rows={rows}
    />
  );
}

function ContractsPage({
  companyId, headers, rows,
}) {
  return (
    <div className="u-col-8">
      <Card>
        <div className="u-flexRow u-spaceBetween u-marginBelow u-flexAlignCenter">
          <h5>Contracts</h5>
          <AddNewButton
            label="Add New Contract"
            url={newAdminCompanyContractPath({ company_id: companyId })}
          />
        </div>
        { renderContent(headers, rows) }
      </Card>
    </div>
  );
}

ContractsPage.propTypes = propTypes;
export default ContractsPage;
