// This displays all item dimensions in a grid
import React from 'react';
import { MaxWidthText } from '@makeably/creativex-design-system';
import {
  getItemContent,
  itemProps,
} from 'utilities/item';
import styles from 'components/molecules/ItemGrid.module.css';

const propTypes = {
  item: itemProps.isRequired,
};

function renderContent(content) {
  switch (typeof content) {
    case 'object':
      return (
        <div className={styles.content}>
          { content }
        </div>
      );
    default:
      return <MaxWidthText text={content ?? ''} />;
  }
}

function renderColumn(key, content) {
  return (
    <div key={key} className={styles.column}>
      <div className="t-overline">{ key }</div>
      <div>
        { renderContent(content) }
      </div>
    </div>
  );
}

function ItemGrid({ item }) {
  return (
    <div className={`${styles.columns} x-scroll-shadows`}>
      { Object.keys(item).map((key) => renderColumn(key, getItemContent(item, key))) }
    </div>
  );
}

ItemGrid.propTypes = propTypes;

export default ItemGrid;
