import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  DataVizSkeleton,
  Divider,
  RadioGroup,
  Tooltip,
} from '@makeably/creativex-design-system';
import BenchmarksBarChart from 'components/benchmarks/BenchmarksBarChart';
import {
  getItemsForMetric,
  metricProps,
} from 'components/benchmarks/utilities';

const propTypes = {
  company: PropTypes.string.isRequired,
  companyMetrics: metricProps.isRequired,
  loading: PropTypes.bool.isRequired,
  reportMetrics: metricProps.isRequired,
};

const CQR_OPTION = 'creativeQualityRate';
const QSR_OPTION = 'qualitySpendRate';

const radioOptions = [
  {
    label: 'Creative Quality Rate',
    value: CQR_OPTION,
  },
  {
    label: 'Quality Spend Rate',
    value: QSR_OPTION,
  },
];

const industryTooltip = "Compare your company's Creative Quality Rate & Quality Spend Rate to your industry and others.";

function IndustryVisualization({
  company,
  companyMetrics,
  loading,
  reportMetrics,
}) {
  const [metric, setMetric] = useState(CQR_OPTION);
  const [items, setItems] = useState([]);

  const displayCqr = metric === CQR_OPTION;

  useEffect(() => {
    const metricForItems = displayCqr ? 'scoreRate' : 'spendRate';
    setItems(getItemsForMetric(metricForItems, company, companyMetrics, reportMetrics));
  }, [metric, companyMetrics, reportMetrics]);

  return (
    <Card>
      <div className="u-flexColumn u-gap-16">
        <div className="u-flexRow u-gap-16">
          <div className="u-flexRow u-gap-8 u-alignCenter">
            <div className="t-subtitle">
              Industry Benchmarks
            </div>
            <Tooltip label={industryTooltip} />
          </div>
          <RadioGroup
            name="metricSelectionRadioGroup"
            options={radioOptions}
            value={metric}
            horizontal
            onChange={() => setMetric(displayCqr ? QSR_OPTION : CQR_OPTION)}
          />
        </div>
        <Divider />
        { loading && (
          <div className="u-flexRow u-justifyCenter">
            <DataVizSkeleton animate />
          </div>
        ) }
        { !loading && (
          <BenchmarksBarChart
            items={items}
          />
        ) }
      </div>
    </Card>
  );
}

IndustryVisualization.propTypes = propTypes;

export default IndustryVisualization;
