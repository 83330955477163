import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Radio,
  Tooltip,
  findObjectByValue,
  removeObjectByValue,
} from '@makeably/creativex-design-system';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import {
  getBinsByTag,
  getBinItems,
  getSegmentLabel,
} from 'components/reporting/binItems';
import BreakdownDrawer from 'components/reporting/BreakdownDrawer';
import ConfigureReport from 'components/reporting/ConfigureReport';
import ReportDatePicker from 'components/reporting/ReportDatePicker';
import ReportTags from 'components/reporting/ReportTags';
import {
  customFilterProps,
  dateOptionProps,
  initialDateRange,
  mixpanelDateChange,
  propertiesProps,
  scoreProps,
} from 'components/reporting/shared';
import TimePeriodFilter from 'components/reporting/TimePeriodFilter';
import TimePeriodVisualization from 'components/reporting/TimePeriodVisualization';
import {
  calcPropertiesJson,
  getMetrics,
  getScoreMetrics,
  getScoresSegments,
  parseProperties,
  preprocessRecords,
} from 'components/reporting/utilities';
import { saveItemsCsvFile } from 'utilities/file';
import { getObjFilterTest } from 'utilities/filtering';
import { track } from 'utilities/mixpanel';
import { removeProperty } from 'utilities/object';
import { getAuthenticityToken } from 'utilities/requests';
import {
  editReportingReportPath,
  timePeriodRecordsReportingReportsPath,
} from 'utilities/routes';
import { toShortMonth } from 'utilities/string';
import styles from './TimePeriodReport.module.css';

const propTypes = {
  canViewSpend: PropTypes.bool.isRequired,
  customFilters: PropTypes.arrayOf(customFilterProps).isRequired,
  customRangeProps: PropTypes.shape({
    customDatesEnabled: PropTypes.bool,
    endDate: PropTypes.string,
    startDate: PropTypes.string,
  }).isRequired,
  dateOptions: PropTypes.arrayOf(dateOptionProps).isRequired,
  initialDescription: PropTypes.string.isRequired,
  initialProperties: propertiesProps.isRequired,
  initialTitle: PropTypes.string.isRequired,
  scores: PropTypes.arrayOf(scoreProps).isRequired,
  type: PropTypes.string.isRequired,
  uuid: PropTypes.string,
};

const defaultProps = {
  uuid: undefined,
};

const TIME_PERIODS = Object.freeze([
  {
    label: 'Year',
    value: 'year',
  },
  {
    label: 'Half Year',
    value: 'half',
  },
  {
    label: 'Quarter',
    value: 'quarter',
  },
  {
    label: 'Month',
    value: 'month',
  },
]);

async function getData(type, dateOption, timePeriod) {
  try {
    const params = {
      end_date: dateOption.endDate,
      start_date: dateOption.startDate,
      date_type: dateOption.type,
      time_period: timePeriod.value,
      type,
    };
    const headers = { 'X-CSRF-Token': getAuthenticityToken() };

    const response = await fetch(timePeriodRecordsReportingReportsPath(params), { headers });
    if (!response.ok) {
      return { error: response.status };
    }
    return { data: await response.json() };
  } catch {
    return { error: 500 };
  }
}

function getTimePeriods(selectedDateOption) {
  const dateType = selectedDateOption?.type;

  if (dateType === 'dynamic') return TIME_PERIODS;

  const match = TIME_PERIODS.findIndex((period) => period.value === dateType);

  // @note: remove all time periods the same or larger than the selectedDateOption
  return match !== -1 ? TIME_PERIODS.slice(match + 1) : TIME_PERIODS;
}

function getPeriodLabel(str) {
  if (str.startsWith('M')) {
    const monthValue = str.slice(1);
    return toShortMonth(`2000-${monthValue}-01`);
  }
  return str;
}

function getDateLabel(dateKey, type, addYear) {
  const parts = dateKey.split('::');
  const addedYear = addYear ? `\n${parts[0]}` : '';

  return `${getPeriodLabel(parts[1])}${addedYear}`;
}

function computeDateLabels(records, dateOption, timePeriod) {
  const dateSet = records.reduce((set, { dateBucket }) => set.add(dateBucket), new Set());
  const addYear = dateOption.type === 'all_time' && timePeriod?.value !== 'year';
  const labels = [...dateSet.values()].reduce((obj, dateKey) => ({
    ...obj,
    [dateKey]: getDateLabel(dateKey, timePeriod?.value, addYear),
  }), {});

  return {
    key: timePeriod?.value,
    labels,
  };
}

// @note: This sorts the time period items by segment and then time period
// It also add items for periods where there is no data, needed for the visualization
function sortAndAddMissing(items, segmentKey, dateLabels, metrics, possibleDates) {
  const itemData = items.reduce(({
    byKey,
    segments,
  }, item) => {
    const date = item.dateBucket?.value;
    const segment = item[segmentKey]?.value;
    const key = `${segment}::${date}`;

    return {
      byKey: {
        ...byKey,
        [key]: item,
      },
      segments: segments.add(segment),
    };
  }, {
    byKey: {},
    segments: new Set(),
  });

  const sortedDates = possibleDates.sort();
  const sortedSegments = [...itemData.segments].sort((a, b) => a.localeCompare(b));
  return sortedSegments.reduce((arr, segment) => {
    const segmentItems = sortedDates.map((date) => {
      const key = `${segment}::${date}`;
      const item = itemData.byKey[key];
      const segmentLabel = getSegmentLabel({ [segmentKey]: segment }, segmentKey);
      const placeholderItem = {
        id: { value: key },
        date: {
          value: date,
        },
        [dateLabels.key]: {
          value: dateLabels.labels[date],
        },
        [segmentKey]: {
          label: segmentLabel,
          value: segment,
        },
      };
      metrics.forEach((metric) => {
        placeholderItem[metric] = {
          label: 'No Data',
        };
      });

      return item ?? placeholderItem;
    });

    return [...arr, ...segmentItems];
  }, []);
}

function updateSelected(available, last) {
  if (last && findObjectByValue(available, last)) {
    return last;
  }
  return available[0];
}

function TimePeriodReport({
  canViewSpend,
  customFilters,
  customRangeProps,
  dateOptions,
  initialDescription,
  initialProperties,
  initialTitle,
  scores,
  type,
  uuid,
}) {
  const [dates, setDates] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [isCalculating, setIsCalculating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [page, setPage] = useState(1);
  const [rawRecords, setRawRecords] = useState([]);
  const [records, setRecords] = useState([]);
  const [dateLabels, setDateLabels] = useState({});
  const [segments, setSegments] = useState([]);
  const [selectedDateOption, setSelectedDateOption] = useState();
  const [selectedDateRange, setSelectedDateRange] = useState(initialDateRange());
  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedMetrics, setSelectedMetrics] = useState([]);
  const [selectedSegments, setSelectedSegments] = useState([]);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState();
  const [timePeriods, setTimePeriods] = useState([]);
  const [useCustomDates, setUseCustomDates] = useState(false);
  const [vizMetric, setVizMetric] = useState(updateSelected(selectedMetrics));
  const filterCount = Object.keys(selectedFilters).length;
  const segmentsWithoutGroups = segments.filter(({ group }) => !group);

  const propertiesJson = calcPropertiesJson({
    selectedDateOption: useCustomDates ? selectedDateRange : selectedDateOption,
    selectedFilters,
    selectedMetrics,
    selectedSegments,
    selectedTimePeriod,
    vizMetric,
  });
  const hasChanged = propertiesJson !== JSON.stringify(initialProperties);

  const getHeaders = () => {
    const segmentHeaders = [selectedTimePeriod, ...selectedSegments].map(({ label, value }) => ({
      key: value,
      label,
      sortable: false,
    }));
    const vizIndex = selectedMetrics.findIndex((metric) => metric.value === vizMetric?.value);
    const metricHeaders = selectedMetrics.map((metric, index) => {
      const {
        label, value, tooltip,
      } = metric;
      const isSelected = index === vizIndex;

      return {
        element: (
          <div className={styles.header}>
            <Radio
              ariaLabel={`Select ${label}`}
              checked={isSelected}
              name="vizMetric"
              value={label}
              onChange={() => setVizMetric(metric)}
            />
            <Tooltip label={tooltip}>{ label }</Tooltip>
          </div>
        ),
        highlighted: isSelected,
        key: value,
        label,
        sortable: false,
      };
    });

    return [
      ...segmentHeaders,
      ...metricHeaders,
    ];
  };

  useEffect(() => {
    setSegments(getScoresSegments(scores, customFilters));
    setMetrics(getMetrics(canViewSpend).concat(...getScoreMetrics(scores, canViewSpend)));
  }, [scores, customFilters]);

  useEffect(() => {
    if (metrics.length > 0) {
      const parsed = parseProperties(initialProperties, {
        customRangeProps,
        dateOptions,
        metrics,
        segments,
        timePeriods,
      });

      setSelectedDateOption(parsed.selectedDateOption);
      setSelectedDateRange(parsed.selectedDateRange);
      setUseCustomDates(parsed.useCustomDates);
      setSelectedFilters(parsed.selectedFilters);
      setSelectedMetrics(parsed.selectedMetrics);
      setSelectedSegments(parsed.selectedSegments);
      setSelectedTimePeriod(parsed.selectedTimePeriod);
      setVizMetric(parsed.vizMetric);
    }
  }, [initialProperties, dateOptions, segments, metrics]);

  useEffect(() => {
    const available = getTimePeriods(useCustomDates ? selectedDateRange : selectedDateOption);

    setTimePeriods(available);
    setSelectedTimePeriod((last) => updateSelected(available, last));
  }, [selectedDateOption, selectedDateRange]);

  useEffect(() => {
    (async () => {
      if (useCustomDates) mixpanelDateChange(selectedDateRange, 'time_period');

      const dateOption = useCustomDates ? selectedDateRange : selectedDateOption;
      if (dateOption && selectedTimePeriod) {
        setIsLoading(true);
        const responses = await Promise.all([
          getData('inflight', dateOption, selectedTimePeriod),
          getData('preflight', dateOption, selectedTimePeriod),
        ]);

        if (responses.some((resp) => resp.error)) {
          const hasTimeout = responses.some((resp) => resp.error === 504);
          const message = hasTimeout ? 'The data request has timed out' : 'The data could not be loaded';

          addToast(message, { type: 'error' });
          setRawRecords([]);
        } else {
          setRawRecords(responses.reduce((all, resp) => [...all, ...resp.data], []));
        }
        setIsLoading(false);
      }
    })();
  }, [selectedDateOption, selectedDateRange, selectedTimePeriod]);

  useEffect(() => {
    if (selectedDateOption && selectedTimePeriod) {
      const labels = computeDateLabels(rawRecords, selectedDateOption, selectedTimePeriod);
      setDates([...new Set(rawRecords.map((record) => record.dateBucket))]);
      setRecords(preprocessRecords(rawRecords, scores, customFilters, labels));
      setDateLabels(labels);
    }
  }, [rawRecords, selectedDateOption, selectedTimePeriod, scores, customFilters]);

  useEffect(() => {
    const filterTest = getObjFilterTest(selectedFilters);

    setFilteredRecords(records.filter(filterTest));
    setPage(1);
  }, [records, selectedFilters]);

  useEffect(() => {
    setIsCalculating(true);
    // @note: timeout lets component render with calculating true before calculation
    const timerId = setTimeout(() => {
      const allSegments = [selectedTimePeriod, ...selectedSegments];
      const bins = getBinsByTag(allSegments, filteredRecords);
      // @note: preserve bin date for sorting
      const allSegmentsPlusDate = [...allSegments, { value: 'dateBucket' }];
      const allItems = getBinItems(bins, allSegmentsPlusDate, scores);
      const segmentKey = selectedSegments[0]?.value;
      const sorted = sortAndAddMissing(
        allItems,
        segmentKey,
        dateLabels,
        selectedMetrics.map((metric) => metric.value),
        dates,
      );
      setIsCalculating(false);

      setPage(1);
      setItems(sorted);
    }, 10);

    return () => clearTimeout(timerId);
  }, [filteredRecords, selectedSegments, selectedMetrics, selectedTimePeriod, vizMetric, scores]);

  useEffect(() => {
    if (selectedTimePeriod) {
      setHeaders(getHeaders());
    }
  }, [selectedTimePeriod, selectedSegments, selectedMetrics, vizMetric]);

  const updateSelectedMetrics = (selected) => {
    setVizMetric((last) => updateSelected(selected, last));
    setSelectedMetrics(selected);
  };

  const removeSelectedMetric = (option) => {
    updateSelectedMetrics(removeObjectByValue(selectedMetrics, option));
  };

  const removeSelectedSegment = (option) => {
    setSelectedSegments((last) => removeObjectByValue(last, option));
  };

  const removeSelectedFilter = (key) => {
    setSelectedFilters((last) => removeProperty(last, key));
  };

  const handleFiltersChange = (filters) => {
    setSelectedFilters(filters);

    track('apply_filter', {
      filters,
      type,
    });
  };

  const handleDateRangeChange = (date, dateLabel) => {
    setSelectedDateRange((prev) => (
      {
        ...prev,
        [dateLabel]: date,
      }
    ));
  };

  const handleDateChange = (date) => {
    setSelectedDateOption(date);

    track('apply_date_change', {
      date: date.label,
      type,
    });
  };

  const handleSave = (reportUuid) => {
    window.location.href = editReportingReportPath(reportUuid);
  };

  const getEmptyStateMessage = () => {
    if (isLoading) return '';
    if (isCalculating) return 'Calculating metrics';
    if (records.length === 0) return 'No data to display';
    if (items.length === 0 && filterCount !== 0) return 'Remove filters to see data';
    return null;
  };

  const renderTable = () => {
    const message = getEmptyStateMessage();

    if (message !== null) {
      return (
        <div className={`t-empty ${styles.empty}`}>
          { message }
        </div>
      );
    }

    return (
      <ItemsTable
        headers={headers}
        items={items}
        page={page}
        onPageChange={(value) => setPage(value)}
      />
    );
  };

  return (
    <>
      <ConfigureReport
        hasChanged={hasChanged}
        initialDescription={initialDescription}
        initialTitle={initialTitle}
        propertiesJson={propertiesJson}
        type={type}
        uuid={uuid}
        onExportCsv={(title) => saveItemsCsvFile(title, items, headers)}
        onSave={handleSave}
      />
      <Card padding={false}>
        <div className={styles.top}>
          <div className={styles.controls}>
            <div className={styles.controlButtons}>
              <Button
                label="Setup"
                variant="secondary"
                onClick={() => setDrawerOpen(true)}
              />
              <TimePeriodFilter
                isOpen={filterOpen}
                records={records}
                segments={segmentsWithoutGroups}
                selections={selectedFilters}
                onClose={() => setFilterOpen(false)}
                onOpen={() => setFilterOpen(true)}
                onSelectionsChange={handleFiltersChange}
              />
            </div>
            <ReportDatePicker
              customRangeProps={customRangeProps}
              dateOptions={dateOptions}
              handleDateChange={handleDateChange}
              handleDateRangeChange={handleDateRangeChange}
              loading={isLoading}
              selectedDateOption={selectedDateOption}
              selectedDateRange={selectedDateRange}
              setUseCustomDates={setUseCustomDates}
              useCustomDates={useCustomDates}
            />
          </div>
          <ReportTags
            filters={selectedFilters}
            removeFilter={removeSelectedFilter}
            removeSelectedMetric={removeSelectedMetric}
            removeSelectedSegment={removeSelectedSegment}
            segments={segmentsWithoutGroups}
            selectedDateOption={selectedDateOption}
            selectedMetrics={selectedMetrics}
            selectedSegments={selectedSegments}
            selectedTimePeriod={selectedTimePeriod}
            setSelectedSegments={setSelectedSegments}
            onFilterClick={() => setFilterOpen(true)}
            onMetricClick={() => setDrawerOpen(true)}
            onSegmentClick={() => setDrawerOpen(true)}
          />
        </div>
        <Divider />
        <div className={styles.visualization}>
          <TimePeriodVisualization
            isLoading={isLoading || isCalculating}
            items={items}
            metric={vizMetric}
            segment={selectedSegments[0]}
            timePeriod={selectedTimePeriod}
            onAddSegment={() => setDrawerOpen(true)}
          />
        </div>
        <Divider />
        <div className={styles.table}>
          { renderTable() }
        </div>
      </Card>
      <BreakdownDrawer
        isOpen={drawerOpen}
        maxSegments={1}
        metrics={metrics}
        segments={segments}
        selectedMetrics={selectedMetrics}
        selectedSegments={selectedSegments}
        selectedTimePeriod={selectedTimePeriod}
        setSelectedMetrics={updateSelectedMetrics}
        setSelectedSegments={setSelectedSegments}
        setSelectedTimePeriod={setSelectedTimePeriod}
        timePeriods={timePeriods}
        onClose={() => setDrawerOpen(false)}
      />
    </>
  );
}

TimePeriodReport.propTypes = propTypes;
TimePeriodReport.defaultProps = defaultProps;

export default TimePeriodReport;
