import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  optionProps,
  segmentProps,
} from 'components/molecules/Filter';
import ReportFilter, { getOptions } from 'components/molecules/ReportFilter';
import { titleize } from 'utilities/string';

export const mediaSpendReportProps = PropTypes.shape({
  assetType: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  channelName: PropTypes.string.isRequired,
  marketName: PropTypes.string.isRequired,
  month: PropTypes.number.isRequired,
  spend: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  guidelines: PropTypes.arrayOf(PropTypes.string),
});

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mediaSpendReports: PropTypes.arrayOf(mediaSpendReportProps).isRequired,
  segments: PropTypes.arrayOf(segmentProps).isRequired,
  selections: PropTypes.objectOf(
    PropTypes.arrayOf(optionProps),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSelectionsChange: PropTypes.func.isRequired,
};

function getCustomOption(mediaSpendReport, key) {
  if (key === 'assetType') {
    const value = mediaSpendReport[key];
    const label = titleize(value);

    return {
      value,
      label,
    };
  } else if (key === 'guidelines') {
    const guidelines = mediaSpendReport[key];

    return guidelines.map((guideline) => ({
      label: guideline,
      value: guideline,
    }));
  }
  return null;
}

function MediaSpendFilter({
  mediaSpendReports,
  segments,
  selections,
  isOpen,
  onClose,
  onOpen,
  onSelectionsChange,
}) {
  const [options, setOptions] = useState({});

  useEffect(() => {
    setOptions(getOptions(mediaSpendReports, segments, getCustomOption));
  }, [segments, mediaSpendReports]);

  return (
    <ReportFilter
      isOpen={isOpen}
      options={options}
      segments={segments}
      selections={selections}
      onClose={onClose}
      onOpen={onOpen}
      onSelectionsChange={onSelectionsChange}
    />
  );
}

MediaSpendFilter.propTypes = propTypes;

export default MediaSpendFilter;
