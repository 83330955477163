import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Icon,
  Logo,
  MultipleDropdown,
  Tooltip,
  MoreButton,
  Search,
} from '@makeably/creativex-design-system';
import VideoInfo from 'components/atoms/VideoInfo';
import EditCampaignModal from 'components/creative_lifecycle/modal/EditCampaignModal';
import { metricTooltips } from 'components/creative_lifecycle/shared';
import ItemsTable from 'components/molecules/ItemsTable';
import { optionArrayProps } from 'components/shared';
import {
  toggleValue,
} from 'utilities/array';
import { saveFile } from 'utilities/file';
import {
  getItemSortBy,
  getItemsCsv,
} from 'utilities/item';
import {
  filterItems,
  getOptions,
  updateSelections,
} from 'utilities/itemFilter';
import { setItemText } from 'utilities/itemText';
import { useViewPage } from 'utilities/mixpanel';
import {
  newCreativeLifecycleCoreAssetUploadPath,
  creativeLifecycleCoreAssetPath,
} from 'utilities/routes';
import {
  titleize,
  clipString,
  toPercent,
  toConciseCount,
  toConciseSpend,
  toMultiplier,
} from 'utilities/string';
import styles from './CampaignDetails.module.css';

const NUM_ASSET_PER_PAGE = 10;
const FILE_NAME_MAX_CHARS = 84;

const propTypes = {
  campaign: PropTypes.shape({
    brand: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  canViewSpend: PropTypes.bool.isRequired,
  coreAssets: PropTypes.arrayOf(
    PropTypes.shape({
      activated: PropTypes.bool,
      assetType: PropTypes.oneOf(['image', 'video']),
      fileName: PropTypes.string,
      impressions: PropTypes.number,
      measurementPartner: PropTypes.string,
      partner: PropTypes.string,
      spend: PropTypes.number,
      uploadInfo: PropTypes.string,
      url: PropTypes.string,
      uuid: PropTypes.string,
      videoLength: PropTypes.number,
    }),
  ).isRequired,
  measurementPartners: optionArrayProps.isRequired,
  partners: optionArrayProps.isRequired,
  summaryMetrics: PropTypes.shape({
    impressions: PropTypes.number.isRequired,
    spend: PropTypes.number.isRequired,
  }).isRequired,
};

const filterDimensions = [
  {
    key: 'activated',
    label: 'Activated',
  },
  {
    key: 'type',
    label: 'Asset Type',
  },
  {
    key: 'partner',
    label: 'Production Partner',
  },
];

function renderEmptyTableContent() {
  return (
    <div className={classNames(styles.emptyTable, 't-empty')}>
      There are no core assets to display
    </div>
  );
}

function renderLabelWithTooltip(label, tooltip) {
  return (
    <div className={styles.headerTooltip}>
      { label }
      <Tooltip label={tooltip} />
    </div>
  );
}

function getAsset(uuid, campaignId, assetUrl, videoLength) {
  const url = creativeLifecycleCoreAssetPath(uuid, { campaign_id: campaignId });

  return {
    element: (
      <a className={styles.assetLink} href={url}>
        { assetUrl && (
          <img
            alt="Core Asset Preview"
            className={styles.asset}
            src={assetUrl}
          />
        ) }
        { !assetUrl && (
          <span className={styles.asset}>
            <Logo name="DEFAULT" />
          </span>
        ) }
        <VideoInfo length={videoLength} />
      </a>
    ),
    value: uuid,
  };
}

const tableHeaders = [
  {
    key: 'checkbox',
    label: '',
    sortable: false,
  },
  {
    key: 'asset',
    label: 'Core Asset',
    sortable: false,
  },
  {
    key: 'fileName',
    label: 'File Name',
  },
  {
    key: 'partner',
    label: 'Production\nPartner',
  },
  {
    key: 'measurementPartner',
    label: 'Measurement\nPartner',
  },
  {
    key: 'type',
    label: 'Asset\nType',
  },
  {
    key: 'activated',
    label: renderLabelWithTooltip('Activated', 'Updated in real-time'),
  },
  {
    key: 'repurposedRate',
    label: renderLabelWithTooltip('Repurposed Rate', metricTooltips.repurposedRate),
  },
  {
    key: 'reusageRate',
    label: renderLabelWithTooltip('Reusage Rate', metricTooltips.reusageRate),
  },
  {
    key: 'spend',
    label: renderLabelWithTooltip('Activated Media Spend', metricTooltips.spend),
  },
  {
    key: 'impressions',
    label: renderLabelWithTooltip('Activated Impressions', metricTooltips.impressions),
  },
];

const csvHeaders = [
  {
    key: 'asset',
    label: 'Core Asset',
    sortable: false,
  },
  {
    key: 'fileName',
    label: 'File Name',
  },
  {
    key: 'partner',
    label: 'Production\nPartner',
  },
  {
    key: 'type',
    label: 'Asset\nType',
  },
  {
    key: 'user',
    label: 'User',
  },
  {
    key: 'uploadDate',
    label: 'Upload Date',
  },
  {
    key: 'activated',
    label: 'Activated',
  },
  {
    key: 'repurposedRate',
    label: 'Repurposed Rate',
  },
  {
    key: 'reusageRate',
    label: 'Reusage Rate',
  },
  {
    key: 'spend',
    label: 'Activated Media Spend',
  },
  {
    key: 'impressions',
    label: 'Activated Impressions',
  },
];

function searchItems(items, search) {
  if (!search) return items;

  const term = search.toLowerCase();

  return items.filter((item) => (item.fileName.value ?? '').toLowerCase()
    .includes(term));
}

const filterHeaders = (headers, canViewSpend) => {
  if (canViewSpend) {
    return headers;
  }

  return headers.filter((header) => !['spend', 'impressions'].includes(header.key));
};

function CampaignDetails({
  campaign: {
    brand,
    id: campaignId,
    name,
  },
  canViewSpend,
  coreAssets,
  measurementPartners,
  partners,
  summaryMetrics,
}) {
  const [selectedUuids, setSelectedUuids] = useState([]);
  const activationRate = useMemo(() => {
    const coreAssetMetrics = coreAssets.reduce((all, coreAsset) => {
      all.coreAssets.add(coreAsset.uuid);
      if (coreAsset.activated) {
        all.activatedCoreAssets.add(coreAsset.uuid);
      }
      return all;
    }, {
      coreAssets: new Set(),
      activatedCoreAssets: new Set(),
    });
    return coreAssetMetrics.activatedCoreAssets.size / coreAssetMetrics.coreAssets.size;
  }, [coreAssets]);
  const viewableTableHeaders = useMemo(
    () => filterHeaders(tableHeaders, canViewSpend), [canViewSpend],
  );

  const getCheckbox = (uuid) => ({
    element: (
      <Checkbox
        checked={selectedUuids.includes(uuid)}
        onChange={() => setSelectedUuids((prevIds) => toggleValue(prevIds, uuid))}
      />
    ),
    value: uuid,
  });

  const getFileName = (fileName) => ({
    element: (
      <div className={styles.fileName}>
        { fileName.length > FILE_NAME_MAX_CHARS ? (
          <Tooltip label={fileName}>
            { clipString(fileName, FILE_NAME_MAX_CHARS) }
          </Tooltip>
        ) : clipString(fileName, FILE_NAME_MAX_CHARS) }

      </div>
    ),
    value: fileName,
  });

  const getActivatedIcon = (activated) => ({
    element: (
      <div className="u-textAlignCenter">
        { activated && <Icon color="green" name="checkCircle" /> }
        { !activated && <Icon color="red" name="xCircle" /> }
      </div>
    ),
    value: titleize(activated.toString()),
  });

  const formatItem = ({
    activated,
    activationCount,
    assetType: type,
    fileName,
    impressions,
    localizationCount,
    measurementPartner,
    partner,
    spend,
    uploadInfo,
    url,
    uuid,
    videoLength,
  }) => {
    const [uploadDate, user] = uploadInfo.split('::');

    return setItemText({
      activated: getActivatedIcon(activated),
      asset: getAsset(uuid, campaignId, url, videoLength),
      checkbox: getCheckbox(uuid),
      fileName: getFileName(fileName),
      id: { value: uuid },
      impressions: {
        label: toConciseCount(impressions) || 'N/A',
        value: impressions,
      },
      measurementPartner: { value: measurementPartner || 'N/A' },
      partner: { value: partner },
      repurposedRate: {
        label: toMultiplier(activationCount),
        value: activationCount,
      },
      reusageRate: {
        label: toMultiplier(localizationCount),
        value: localizationCount,
      },
      spend: {
        label: toConciseSpend(spend) || 'N/A',
        value: spend,
      },
      type: { value: titleize(type) },
      uploadDate: {
        format: 'date',
        value: uploadDate,
      },
      url,
      user: { value: user },
    });
  };

  const [allAssets, setAllAssets] = useState([]);
  const [checkedAssets, setCheckedAssets] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [searchedAssets, setSearchedAssets] = useState([]);
  const [filterSelections, setFilterSelections] = useState({});
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [sortBy, setSortBy] = useState({
    key: 'uploadDate',
    asc: false,
  });
  const [sortedAssets, setSortedAssets] = useState([]);
  const [page, setPage] = useState(1);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState('');

  useViewPage();

  useEffect(() => {
    const items = coreAssets.map((asset) => formatItem(asset));

    setFilterOptions(getOptions(filterDimensions, items));
    setAllAssets(items);
  }, [coreAssets]);

  useEffect(() => {
    setCheckedAssets(allAssets.map((item) => ({
      ...item,
      checkbox: getCheckbox(item.id.value),
    })));
  }, [allAssets, selectedUuids]);

  useEffect(() => {
    setFilteredAssets(filterItems(checkedAssets, filterSelections));
  }, [checkedAssets, filterSelections]);

  useEffect(() => {
    setSearchedAssets(searchItems(filteredAssets, search));
  }, [filteredAssets, search]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sortBy.key, sortBy.asc);
    setSortedAssets(searchedAssets.slice().sort(byKeyDir));
  }, [searchedAssets, sortBy]);

  const newAssetLink = newCreativeLifecycleCoreAssetUploadPath({ campaign_id: campaignId });
  const numSelectedAssets = selectedUuids.length;

  const handleFilterChange = (key, selected) => {
    setFilterSelections(updateSelections(filterSelections, key, selected));
  };

  const renderDetail = (label, value, tooltip = '') => (
    <div className={styles.detail}>
      <div className={`t-caption-1 ${styles.detailLabel}`}>
        { label }
        { tooltip && <Tooltip label={tooltip} /> }
      </div>
      <div className="t-subtitle">{ value }</div>
    </div>
  );

  // Manually inject the correct activated header, and the asset should be
  // the remote href, not a react node.
  const onExportCsv = () => {
    const fileName = `${name}.csv`;
    const allowedHeaders = filterHeaders(csvHeaders, canViewSpend);

    const csv = getItemsCsv(sortedAssets.map((sortedAsset) => ({
      ...sortedAsset,
      asset: { value: sortedAsset.url },
      fileName: { value: sortedAsset.fileName.value },
      activated: {
        value: titleize(sortedAsset.activated.value),
      },
    })), allowedHeaders);
    saveFile(fileName, csv);
  };

  return (
    <>
      <Card padding={false}>
        <div className={styles.detailRow}>
          <div className={styles.details}>
            { renderDetail('Brand', brand) }
            { renderDetail('Core Assets', coreAssets.length) }
            { renderDetail('Activation Rate', Number.isNaN(activationRate) ? 'N/A' : toPercent(activationRate, 1), metricTooltips.activationRate) }
            { canViewSpend && renderDetail('Total Activated Media Spend', toConciseSpend(summaryMetrics.spend) || 'N/A', metricTooltips.spend) }
            { canViewSpend && renderDetail('Total Activated Impressions', toConciseCount(summaryMetrics.impressions) || 'N/A', metricTooltips.impressions) }
          </div>
          <MoreButton options={[{
            label: 'Upload Core Asset',
            url: newAssetLink,
          }, {
            label: 'Export CSV',
            onClick: () => onExportCsv(),
          }]}
          />
        </div>
        <Divider />
        <div className={styles.editRow}>
          <div className={styles.filters}>
            { filterDimensions.map(({
              key,
              label,
            }) => (
              <MultipleDropdown
                key={key}
                label={label}
                options={filterOptions[key] ?? []}
                placeholder="All"
                selected={filterSelections[key]}
                size="small"
                onChange={(sel) => handleFilterChange(key, sel)}
              />
            )) }
            <Search
              placeholder="Search File Name"
              value={search}
              onChange={(value) => setSearch(value)}
            />
            { Object.keys(filterSelections).length > 0 && (
              <Button
                label="Clear"
                variant="tertiary"
                onClick={() => setFilterSelections({})}
              />
            ) }
          </div>
          <Button
            disabled={numSelectedAssets === 0}
            label={`Edit Selected (${numSelectedAssets})`}
            variant="tertiary"
            onClick={() => setShowModal(true)}
          />
        </div>
        <Divider />
        <div className={styles.assets}>
          <ItemsTable
            emptyTableContent={renderEmptyTableContent()}
            headers={viewableTableHeaders}
            items={sortedAssets}
            maxWidthSize="custom"
            page={page}
            perPage={NUM_ASSET_PER_PAGE}
            sort={sortBy}
            onPageChange={(p) => setPage(p)}
            onSortChange={(sort) => setSortBy(sort)}
          />
        </div>
      </Card>
      { showModal && (
        <EditCampaignModal
          campaignId={campaignId}
          isOpen={showModal}
          measurementPartners={measurementPartners}
          partners={partners}
          selectedUuids={selectedUuids}
          onClose={() => setShowModal(false)}
        />
      ) }
    </>
  );
}

CampaignDetails.propTypes = propTypes;

export default CampaignDetails;
