const channelDetails = {
  adform: {
    name: 'AdForm Ads',
    logo: 'adform',
  },
  amazon_paid: {
    name: 'Amazon Ads',
    logo: 'amazon',
  },
  dv360: {
    name: 'Display and Video 360',
    logo: 'dv360',
  },
  facebook_organic: {
    name: 'Facebook Ads',
    logo: 'facebook',
  },
  facebook_paid: {
    name: 'Facebook Ads',
    logo: 'facebook',
  },
  my_target_paid: {
    name: 'MyTarget Ads',
    logo: 'mytarget',
  },
  pinterest: {
    name: 'Pinterest Ads',
    logo: 'pinterest',
  },
  snapchat_paid: {
    name: 'Snapchat Ads',
    logo: 'snapchat',
  },
  tiktok_paid: {
    name: 'Tiktok Ads',
    logo: 'tiktok',
  },
  twitter_organic: {
    name: 'Twitter Ads',
    logo: 'twitter',
  },
  twitter_paid: {
    name: 'Twitter Ads',
    logo: 'twitter',
  },
  youtube_organic: {
    name: 'Youtube Ads',
    logo: 'youtube',
  },
  youtube_paid: {
    name: 'Google Ads',
    logo: 'google',
  },
};

export function channelName(usage) {
  return channelDetails[usage].name;
}

export function logoName(usage) {
  return channelDetails[usage].logo;
}
