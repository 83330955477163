// Get an array of css values by css var name
export function getCssVars(cssVarNames) {
  const style = getComputedStyle(document.documentElement);

  return cssVarNames.map((name) => style.getPropertyValue(name));
}

// Get an object of css values by css var name
//  Input: an object whose values are css var names as strings (i.e. '--red-500')
//  Output: an object with the same keys where the values are now
//   the values of those css var names (i.e. '#FF3239')
export function getCssVarsObj(namesObj) {
  const style = getComputedStyle(document.documentElement);

  return Object.entries(namesObj).reduce((obj, [key, name]) => (
    Object.assign(obj, { [key]: style.getPropertyValue(name) })
  ), {});
}
