import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  getStatus,
  stepProps,
  substageNames,
} from 'components/internal/PipelineStep';
import { emptyState } from 'components/internal/shared';
import ItemsTable from 'components/molecules/ItemsTable';
import { getItemSortBy } from 'utilities/item';
import { getByObjectKey } from 'utilities/sort';
import styles from './PipelineSteps.module.css';

export const stepsGroupProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  allSteps: PropTypes.arrayOf(stepProps),
  isMultiday: PropTypes.bool,
  steps: PropTypes.objectOf(stepProps),
});

const propTypes = {
  onStepSelect: PropTypes.func.isRequired,
  stepsGroup: stepsGroupProps,
};

const defaultProps = {
  stepsGroup: undefined,
};

const headers = [
  {
    key: 'stage',
    label: 'Stage',
  },
  {
    key: 'last',
    label: 'Last Operation',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'count',
    label: 'Ad Count',
  },
];

function getLast(substage) {
  const lastLetter = substage.at(-1);

  return substageNames[lastLetter] ?? 'Created';
}

function getStage(stage, substage, isMultiday, onClick) {
  const value = `${stage}-${substage}`;

  if (isMultiday) {
    return { value };
  }

  return {
    element: (
      <button
        className="button-link"
        type="button"
        onClick={onClick}
      >
        { value }
      </button>
    ),
    value,
  };
}

function getItems(group, onStepSelect) {
  const steps = group?.steps ?? {};

  const byKey = getByObjectKey('key');
  const sorted = Object.values(steps).sort(byKey);

  return sorted.map(({
    count,
    key,
    stage,
    status,
    substage,
  }) => ({
    count: { value: count },
    id: { value: key },
    last: { value: getLast(substage) },
    status: { value: getStatus(key, status) },
    stage: getStage(stage, substage, group.isMultiday, () => onStepSelect(key)),
  }));
}

function PipelineSteps({ stepsGroup, onStepSelect }) {
  const [sort, setSort] = useState(null);
  const items = useMemo(() => (
    getItems(stepsGroup, onStepSelect)
  ), [stepsGroup, onStepSelect]);
  const sortedItems = useMemo(() => {
    if (!sort) return items;

    const byKeyDir = getItemSortBy(sort.key, sort.asc);
    return items.slice().sort(byKeyDir);
  }, [items, sort]);

  return (
    <ItemsTable
      className={styles.table}
      emptyTableContent={emptyState}
      headers={headers}
      items={sortedItems}
      sort={sort}
      onSortChange={setSort}
    />
  );
}

PipelineSteps.propTypes = propTypes;
PipelineSteps.defaultProps = defaultProps;

export default PipelineSteps;
