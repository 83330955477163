// Utilities for benchmark dashboards
import PropTypes from 'prop-types';
import {
  findObjectByValue,
  sortObjectArray,
} from 'utilities/array';
import { rate } from 'utilities/number';
import {
  toConciseCount,
  toConciseSpend,
  toPercent,
} from 'utilities/string';

export const dateOptionStorageKey = 'benchmarkDateOption';
export const filterStorageKey = 'benchmarkFilters';

export const benchmarkGuidelines = [
  'Aspect Ratio',
  'Brand Early',
  'Safe Zones',
  'Sound On',
  'Supers Present',
  'Video Length',
];

const guidelineProps = PropTypes.shape({
  passed_checks: PropTypes.number.isRequired,
  total_checks: PropTypes.number.isRequired,
});

export const reportProps = PropTypes.shape({
  assetType: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  clientIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  excellentCount: PropTypes.number.isRequired,
  excellentSpend: PropTypes.number.isRequired,
  guidelineData: PropTypes.objectOf(guidelineProps).isRequired,
  industry: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  totalCount: PropTypes.number.isRequired,
  totalSpend: PropTypes.number.isRequired,
});

const brandMetricProps = PropTypes.shape({
  name: PropTypes.string,
  scoreRate: PropTypes.number,
  spendRate: PropTypes.number,
  totalCount: PropTypes.number,
  totalSpend: PropTypes.number,
});

export const metricProps = PropTypes.shape({
  brandMetrics: PropTypes.arrayOf(brandMetricProps),
  clientCount: PropTypes.number,
  scoreRate: PropTypes.number,
  spendRate: PropTypes.number,
  totalCount: PropTypes.number,
  totalSpend: PropTypes.number,
  ...benchmarkGuidelines.reduce((guidelines, guideline) => ({
    ...guidelines,
    [guideline]: PropTypes.number,
  }), {}),
});

function getGuidelineTotals(totals, report) {
  return benchmarkGuidelines.reduce((guidelines, guideline) => {
    const data = report.guidelineData[guideline];
    const totalChecks = data ? data.total_checks : 0;
    const passedChecks = data ? data.passed_checks : 0;

    return {
      ...guidelines,
      [guideline]: {
        totalChecks: totals[guideline].totalChecks + totalChecks,
        passedChecks: totals[guideline].passedChecks + passedChecks,
      },
    };
  }, {});
}

function getGuidelineDefaults() {
  return benchmarkGuidelines.reduce((guidelines, guideline) => ({
    ...guidelines,
    [guideline]: {
      totalChecks: 0,
      passedChecks: 0,
    },
  }), {});
}

// Returns accumulated metric totals given benchmark reports
// Input: array of reportProps
// Output: object in the shape of metricProps
export function getMetricTotals(reports) {
  const metrics = reports.reduce((totals, report) => ({
    totalCount: totals.totalCount + report.totalCount,
    excellentCount: totals.excellentCount + report.excellentCount,
    totalSpend: totals.totalSpend + report.totalSpend,
    excellentSpend: totals.excellentSpend + report.excellentSpend,
    clientIds: [...totals.clientIds, ...report.clientIds],
    ...getGuidelineTotals(totals, report),
  }), {
    totalCount: 0,
    excellentCount: 0,
    totalSpend: 0,
    excellentSpend: 0,
    clientIds: [],
    ...getGuidelineDefaults(),
  });

  return {
    totalCount: metrics.totalCount,
    totalSpend: metrics.totalSpend,
    scoreRate: rate(metrics.excellentCount, metrics.totalCount),
    spendRate: rate(metrics.excellentSpend, metrics.totalSpend),
    clientCount: new Set(metrics.clientIds).size,
    ...benchmarkGuidelines.reduce((guidelines, guideline) => ({
      ...guidelines,
      [guideline]: rate(metrics[guideline]?.passedChecks, metrics[guideline]?.totalChecks),
    }), {}),
  };
}

function getOtherIndustryOptions(reports, optionsForCompany) {
  const options = reports.reduce((opts, { industry }) => {
    if (!findObjectByValue(opts, industry)) {
      return [
        ...opts,
        {
          label: industry,
          value: industry,
        },
      ];
    }
    return opts;
  }, []);

  const otherOptions = options.filter((option) => (
    !optionsForCompany.find((industry) => option.value === industry.value)
  ));

  if (otherOptions.length === 0) return [];

  return [
    {
      label: 'Other Industries',
      group: true,
      value: 'otherIndustries',
    },
    ...sortObjectArray(otherOptions, 'label'),
  ];
}

function getOtherCategoryOptions(reports, optionsForCompany) {
  const options = reports.reduce((opts, { category }) => {
    if (!findObjectByValue(opts, category)) {
      return [
        ...opts,
        {
          label: category,
          value: category,
        },
      ];
    }
    return opts;
  }, []);

  const otherOptions = options.filter((option) => (
    !optionsForCompany.find((category) => option.value === category.value)
  ));

  if (otherOptions.length === 0) return [];

  return [
    {
      label: 'Other Categories',
      group: true,
      value: 'otherCategories',
    },
    ...sortObjectArray(otherOptions, 'label'),
  ];
}

// Returns grouped options for industry dropdown given benchmarks reports and industries for company
// Input: array of reportProps, array of optionProps
// Output: array of optionProps
export function getIndustryOptions(reports, optionsForCompany) {
  return [
    {
      label: 'My Industries',
      group: true,
      value: 'myIndustries',
    },
    ...optionsForCompany,
    ...getOtherIndustryOptions(reports, optionsForCompany),
  ];
}

// Returns grouped options for category dropdown given benchmarks reports and categories for company
// Input: array of reportProps, array of optionProps
// Output: array of optionProps
export function getCategoryOptions(reports, optionsForCompany) {
  return [
    {
      label: 'My Categories',
      group: true,
      value: 'myCategories',
    },
    ...optionsForCompany,
    ...getOtherCategoryOptions(reports, optionsForCompany),
  ];
}

function getItemTooltip(metrics) {
  return {
    value: [
      `Total In-Flight Posts: ${toConciseCount(metrics.totalCount)}`,
      `Total Spend: ${toConciseSpend(metrics.totalSpend)}`,
    ],
  };
}

// Returns items for display a metric for comparison between company and benchmarks
// Input: string, string, metricProps, metricProps
// Output: array of itemProps
export function getItemsForMetric(metric, company, companyMetrics, reportMetrics) {
  return [
    {
      label: { value: company },
      metric: {
        label: toPercent(companyMetrics[metric]) ?? 'N/A',
        value: companyMetrics[metric],
      },
      tooltip: getItemTooltip(companyMetrics),
    },
    {
      label: { value: 'Benchmark' },
      metric: {
        label: toPercent(reportMetrics[metric]) ?? 'N/A',
        value: reportMetrics[metric],
      },
      tooltip: getItemTooltip(reportMetrics),
    },
  ];
}

// Returns items for displaying a metric for a list of brands
// Input: string, array of brandMetricProps
// Output: array of itemProps
export function getBrandItemsForMetric(metric, brandMetrics) {
  return brandMetrics.map((brand) => ({
    label: { value: brand.name },
    metric: {
      label: toPercent(brand[metric]) ?? 'N/A',
      value: brand[metric],
    },
    tooltip: getItemTooltip(brand, 'Updated Daily'),
  }));
}
