import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Icon,
} from '@makeably/creativex-design-system';
import { positionProps as boundingBoxProps } from 'components/admin/annotation/BoundingBox';
import LogoAnnotationCard from 'components/admin/annotation/LogoAnnotationCard';

export const assetProps = {
  job: PropTypes.shape({
    id: PropTypes.number.isRequired,
    state: PropTypes.oneOf(['in_progress', 'completed']).isRequired,
    skipReason: PropTypes.string,
  }).isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

export const logoProps = {
  brandName: PropTypes.string.isRequired,
  exampleUrls: PropTypes.arrayOf(PropTypes.string).isRequired,
  label: PropTypes.string.isRequired,
};

export const propTypes = {
  asset: PropTypes.shape(assetProps).isRequired,
  boundingBoxes: PropTypes.arrayOf(PropTypes.shape(boundingBoxProps)).isRequired,
  formUrl: PropTypes.string.isRequired,
  logo: PropTypes.shape(logoProps).isRequired,
};

const ELEMENT_TYPE = 'Logos';
const REVIEW_TYPE = 'ML Training';

const goodBoundingExamples = [
  {
    label: 'Clear/in focus',
    url: 'https://picassolabs.s3.amazonaws.com/assets/annotation/clear.jpg',
  },
  {
    label: 'Unobscured',
    url: 'https://picassolabs.s3.amazonaws.com/assets/annotation/unobscured.jpg',
  },
  {
    label: 'One element per box',
    url: 'https://picassolabs.s3.amazonaws.com/assets/annotation/one-box-per-element.jpg',
  },
  {
    label: 'Tightly framed',
    url: 'https://picassolabs.s3.amazonaws.com/assets/annotation/tightly-framed.jpg',
  },
];

function renderInfoBlock(type, value) {
  return (
    <div>
      <div className="t-label-1 u-marginBottom-8">{ type }</div>
      <h5>{ value }</h5>
    </div>
  );
}

function stateText({ state, skipReason }) {
  if (state === 'in_progress') {
    return 'Needs Review';
  } else if (skipReason) {
    return 'Skipped';
  }
  return 'Completed';
}

function LogoDetection({
  asset,
  boundingBoxes,
  formUrl,
  logo,
}) {
  return (
    <div>
      <Card className="u-flexRow u-justifySpaceBetween u-marginBottom-16">
        { renderInfoBlock('Brand', logo.brandName) }
        { renderInfoBlock('Element Type', ELEMENT_TYPE) }
        { renderInfoBlock('Review Type', REVIEW_TYPE) }
        { renderInfoBlock('State', stateText(asset.job)) }
      </Card>
      <LogoAnnotationCard
        asset={asset}
        boundingBoxes={boundingBoxes}
        formUrl={formUrl}
        logo={logo}
      />
      <Card>
        <div className="u-flexRow">
          <h5>Good Bounding</h5>
          <div className="u-marginLeftSm">
            <Icon color="green" name="checkCircle" />
          </div>
        </div>
        <div className="u-flexRow u-justifySpaceBetween u-marginTop-16">
          { goodBoundingExamples.map(({
            label,
            url,
          }) => (
            <div key={label} className="u-flexColumn">
              <img
                alt={`example of ${label}`}
                className="u-col-3 u-marginBottom-8"
                src={url}
              />
              { label }
            </div>
          )) }
        </div>
      </Card>
    </div>
  );
}

LogoDetection.propTypes = propTypes;

export default LogoDetection;
