import React, {
  useMemo,
  useState,
} from 'react';
import { stepsGroupProps } from 'components/internal/PipelineSteps';
import { emptyState } from 'components/internal/shared';
import ItemsTable from 'components/molecules/ItemsTable';
import { getItemSortBy } from 'utilities/item';

const propTypes = {
  stepsGroup: stepsGroupProps.isRequired,
};

const times = [
  {
    key: '0a',
    label: 'a: Add Ad Data',
  },
  {
    key: '0b',
    label: 'b: Add Asset',
  },
  {
    key: '0c',
    label: 'c: Process CV',
  },
  {
    key: '1',
    label: 'abc: Asset & Data',
  },
  {
    key: '2',
    label: 'd: Add Audit Objects',
  },
  {
    key: '2e',
    label: 'e: Run Rules Engine',
  },
  {
    key: '2f',
    label: 'f: Create Dated Audit Posts',
  },
  {
    key: '3',
    label: 'ef: Rules & Posts',
  },
  {
    key: '3total',
    label: 'abcdef: Total',
  },
  {
    key: '4',
    label: 'Review',
  },
];

const headers = [
  {
    key: 'label',
    label: 'Operation',
  },
  {
    key: 'count',
    label: 'Ad Count',
  },
  {
    key: 'avg',
    label: 'Avg',
  },
  {
    key: 'min',
    label: 'Min',
  },
  {
    key: 'max',
    label: 'Max',
  },
];

function nullableMax(last, next) {
  if (typeof next === 'number') {
    if (typeof last === 'number') {
      return Math.max(last, next);
    }
    return next;
  }
  return last;
}

function nullableMin(last, next) {
  if (typeof next === 'number') {
    if (typeof last === 'number') {
      return Math.min(last, next);
    }
    return next;
  }
  return last;
}

function combineStepTimes(allSteps) {
  if (!allSteps) return {};

  return times.map(({ key, label }) => {
    const avgKey = `avgTime${key}`;
    const maxKey = `maxTime${key}`;
    const minKey = `minTime${key}`;

    const totals = allSteps.reduce(({
      count,
      max,
      min,
      sum,
    }, step) => {
      const isNull = typeof step[avgKey] !== 'number';
      const countAdd = isNull ? 0 : step.count;
      const sumAdd = isNull ? 0 : (step.count * step[avgKey]);

      return {
        count: count + countAdd,
        max: nullableMax(max, step[maxKey]),
        min: nullableMin(min, step[minKey]),
        sum: sum + sumAdd,
      };
    }, {
      count: 0,
      max: null,
      min: null,
      sum: 0,
    });

    return {
      avg: totals.count === 0 ? null : totals.sum / totals.count,
      count: totals.count,
      key,
      label,
      max: totals.max,
      min: totals.min,
    };
  });
}

function divideRemainder(value, bigDivisor, smallDivisor) {
  const big = Math.floor(value / bigDivisor);
  const remainder = value - (big * bigDivisor);
  const small = Math.round(remainder / smallDivisor);

  return [big, small];
}

function toShortTime(timeS) {
  if (typeof timeS !== 'number') return 'N/A';

  const oneSec = 1;
  const oneMin = 60 * oneSec;
  const oneHour = 60 * oneMin;
  const oneDay = 24 * oneHour;

  if (timeS > oneDay) {
    const [day, hr] = divideRemainder(timeS, oneDay, oneHour);
    return `${day}d ${hr}h`;
  } else if (timeS > oneHour) {
    const [hr, min] = divideRemainder(timeS, oneHour, oneMin);
    return `${hr}h ${min}m`;
  } else if (timeS > oneMin) {
    const [min, sec] = divideRemainder(timeS, oneMin, oneSec);
    return `${min}m ${sec}s`;
  }
  return `${timeS.toFixed(2)}s`;
}

function getItems(timeSteps) {
  return timeSteps.map(({
    avg,
    count,
    key,
    label,
    max,
    min,
  }) => ({
    avg: {
      label: toShortTime(avg),
      value: avg ?? 0,
    },
    count: { value: count },
    id: { value: key },
    label: {
      label,
      value: key,
    },
    max: {
      label: toShortTime(max),
      value: max ?? 0,
    },
    min: {
      label: toShortTime(min),
      value: min ?? 0,
    },
  }));
}

function PipelineTimes({ stepsGroup }) {
  const [sort, setSort] = useState({
    key: 'label',
    asc: true,
  });

  const timeSteps = useMemo(() => combineStepTimes(stepsGroup?.allSteps), [stepsGroup]);
  const items = useMemo(() => getItems(timeSteps), [timeSteps]);
  const sortedItems = useMemo(() => (
    items.slice().sort(getItemSortBy(sort.key, sort.asc))
  ), [items, sort]);

  return (
    <ItemsTable
      emptyTableContent={emptyState}
      headers={headers}
      items={sortedItems}
      sort={sort}
      onSortChange={setSort}
    />
  );
}

PipelineTimes.propTypes = propTypes;

export default PipelineTimes;
