import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { reportProps } from 'components/benchmarks/utilities';
import {
  optionProps,
  segmentProps,
} from 'components/molecules/Filter';
import ReportFilter, { getOptions } from 'components/molecules/ReportFilter';
import { titleize } from 'utilities/string';

const propTypes = {
  channelLabelMap: PropTypes.objectOf(PropTypes.string).isRequired,
  isOpen: PropTypes.bool.isRequired,
  reports: PropTypes.arrayOf(reportProps).isRequired,
  segments: PropTypes.arrayOf(segmentProps).isRequired,
  selections: PropTypes.objectOf(
    PropTypes.arrayOf(optionProps),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSelectionsChange: PropTypes.func.isRequired,
};

function BenchmarksFilter({
  channelLabelMap,
  reports,
  segments,
  selections,
  isOpen,
  onClose,
  onOpen,
  onSelectionsChange,
}) {
  const getCustomOption = (report, key) => {
    if (key === 'assetType') {
      const value = report[key];
      const label = titleize(value);

      return {
        value,
        label,
      };
    } else if (key === 'channel') {
      const value = report[key];
      const label = channelLabelMap[value];

      return {
        value,
        label,
      };
    }
    return null;
  };

  const [options, setOptions] = useState({});

  useEffect(() => {
    setOptions(getOptions(reports, segments, getCustomOption));
  }, [segments, reports]);

  return (
    <ReportFilter
      isOpen={isOpen}
      options={options}
      segments={segments}
      selections={selections}
      onClose={onClose}
      onOpen={onOpen}
      onSelectionsChange={onSelectionsChange}
    />
  );
}

BenchmarksFilter.propTypes = propTypes;

export default BenchmarksFilter;
