import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  MultipleDropdown,
  ClickableTag,
  toggleObject,
} from '@makeably/creativex-design-system';
import { optionProps } from 'components/internal/shared';
import styles from './UserPermissionOptions.module.css';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(optionProps).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};

const defaultProps = {
  error: undefined,
};

function getSelected(values, options) {
  return values.reduce((arr, val) => {
    const opt = options.find(({ value }) => value === val);

    if (opt) {
      return [...arr, opt];
    }
    return arr;
  }, []);
}

function UserPermissionOptions({
  disabled,
  error,
  label,
  values,
  onChange,
  options,
}) {
  const selected = getSelected(values, options);

  const handleChange = (option) => {
    const updated = toggleObject(selected, option);
    onChange(updated.map(({ value }) => value));
  };

  const renderTag = (option) => (
    <ClickableTag
      key={option.value}
      color="purple"
      label={option.label}
      onRemove={() => handleChange(option)}
    />
  );

  return (
    <div>
      <MultipleDropdown
        disabled={disabled}
        label={label}
        menuProps={{ size: 'large' }}
        options={options}
        selected={selected}
        size="large"
        onChange={handleChange}
      />
      { error && (
        <div className="u-flexRow u-gap-8 u-marginLeft-8 u-marginTop-8">
          <Icon color="red" name="exclamationCircle" />
          { error }
        </div>
      ) }
      <div className={styles.tags}>
        { selected.map((option) => renderTag(option)) }
      </div>
    </div>
  );
}

UserPermissionOptions.propTypes = propTypes;
UserPermissionOptions.defaultProps = defaultProps;

export default UserPermissionOptions;
