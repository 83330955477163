import { arrayIf } from 'utilities/array';

export const roleLabels = {
  lifecycleManageAndReport: 'Can upload core assets, view/edit campaigns, and report on Creative Lifecycle',
  manageTaxonomies: 'Can manage taxonomies',
  restrictedAuditChannelData: "Restrict user's data access by channel",
  restrictedBrandData: "Restrict user's data access by brand",
  restrictedMarketData: "Restrict user's data access by market",
  restrictedOrgAffiliateData: "Restrict user's data access by partner",
  viewAdditionalCreatives: 'Allow user to see additional creatives',
  viewAffiliateCreatives: 'Can view all pre-flight submissions from users of same partner',
  viewRepresentation: 'View representation dashboard',
  viewUsageDashboard: 'Can view usage dashboard',
};

export const roleOptionTypes = {
  restrictedAuditChannelData: ['channel'],
  restrictedBrandData: ['brand'],
  restrictedMarketData: ['market'],
  restrictedOrgAffiliateData: ['partner'],
  viewAdditionalCreatives: ['brand', 'market', 'partner'],
};

export function getProfileRoles(profile, isRepresentationEnabled, isLifecycleEnabled) {
  switch (profile) {
    case 'admin':
      return [
        'viewUsageDashboard',
        'manageTaxonomies',
      ];
    case 'connecting':
    case 'limited':
      return [
        'viewAffiliateCreatives',
        'viewAdditionalCreatives',
        ...arrayIf(isLifecycleEnabled, 'lifecycleManageAndReport'),
      ];
    case 'standard':
      return [
        'restrictedBrandData',
        'restrictedAuditChannelData',
        'restrictedOrgAffiliateData',
        'restrictedMarketData',
        ...arrayIf(isRepresentationEnabled, 'viewRepresentation'),
        ...arrayIf(isLifecycleEnabled, 'lifecycleManageAndReport'),
        'manageTaxonomies',
      ];
    default:
      return [];
  }
}
