import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  ClickableTag,
  Divider,
  Drawer,
  Dropdown,
  MultipleDropdown,
  removeObjectByValue,
  toggleObject,
} from '@makeably/creativex-design-system';
import { isSameByValue } from 'utilities/array';
import { track } from 'utilities/mixpanel';
import styles from './BreakdownDrawer.module.css';

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});
const optionPropsArray = PropTypes.arrayOf(optionProps);

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  metrics: optionPropsArray.isRequired,
  segments: optionPropsArray.isRequired,
  selectedMetrics: optionPropsArray.isRequired,
  selectedSegments: optionPropsArray.isRequired,
  setSelectedMetrics: PropTypes.func.isRequired,
  setSelectedSegments: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  maxSegments: PropTypes.number,
  selectedTimePeriod: optionProps,
  setSelectedTimePeriod: PropTypes.func,
  timePeriods: optionPropsArray,
};

const defaultProps = {
  maxSegments: 3,
  selectedTimePeriod: undefined,
  setSelectedTimePeriod: undefined,
  timePeriods: undefined,
};

function generateSegmentOptions(segments, selected, max) {
  if (selected.length < max) {
    return segments;
  }

  return segments.map((dim) => {
    if (selected.findIndex((sel) => sel.value === dim.value) === -1) {
      return {
        ...dim,
        disabled: true,
      };
    }
    return dim;
  });
}

function BreakdownDrawer({
  segments,
  isOpen,
  maxSegments,
  metrics,
  onClose,
  setSelectedSegments,
  setSelectedMetrics,
  setSelectedTimePeriod,
  selectedSegments,
  selectedMetrics,
  selectedTimePeriod,
  timePeriods,
}) {
  const [segmentOptions, setSegmentOptions] = useState(
    generateSegmentOptions(segments, selectedSegments, maxSegments),
  );
  const [currentSegments, setCurrentSegments] = useState(selectedSegments);
  const [currentMetrics, setCurrentMetrics] = useState(selectedMetrics);
  const [currentTimePeriod, setCurrentTimePeriod] = useState(selectedTimePeriod);
  const isApplyDisabled = (isSameByValue(currentSegments, selectedSegments)
    && isSameByValue(currentMetrics, selectedMetrics)
    && currentTimePeriod?.value === selectedTimePeriod?.value);
  const requiredSegments = segments.filter((segment) => segment.disabled);

  useEffect(() => {
    setSegmentOptions(generateSegmentOptions(segments, selectedSegments, maxSegments));
    setCurrentSegments(selectedSegments);
  }, [selectedSegments]);

  useEffect(() => {
    setCurrentMetrics(selectedMetrics);
  }, [selectedMetrics]);

  useEffect(() => {
    setCurrentTimePeriod(selectedTimePeriod);
  }, [selectedTimePeriod]);

  const removeSegment = (option) => {
    const updated = removeObjectByValue(currentSegments, option);
    setSegmentOptions(generateSegmentOptions(segments, updated, maxSegments));
    setCurrentSegments(updated);
  };

  const removeMetric = (option) => {
    setCurrentMetrics((current) => removeObjectByValue(current, option));
  };

  const handleSegmentChange = (selected) => {
    const current = toggleObject(currentSegments, selected);
    setSegmentOptions(generateSegmentOptions(segments, current, maxSegments));
    setCurrentSegments(current);
  };

  const handleSegmentClear = () => {
    const current = [];
    setSegmentOptions(generateSegmentOptions(segments, current, maxSegments));
    setCurrentSegments(current.concat(requiredSegments));
  };

  const handleMetricChange = (selected) => {
    setCurrentMetrics(toggleObject(currentMetrics, selected));
  };

  const handleTimePeriodChange = (selected) => {
    setCurrentTimePeriod(selected);
    if (selected !== currentTimePeriod) {
      track('change_time_period_in_setup', { timePeriod: selected.value });
    }
  };

  const handleCancel = () => {
    setSegmentOptions(generateSegmentOptions(segments, selectedSegments, maxSegments));
    setCurrentSegments(selectedSegments);
    setCurrentMetrics(selectedMetrics);
    setCurrentTimePeriod(selectedTimePeriod);
    onClose();
  };

  const handleApply = () => {
    setSegmentOptions(generateSegmentOptions(segments, currentSegments, maxSegments));
    setSelectedSegments(currentSegments);
    setSelectedMetrics(currentMetrics);
    setSelectedTimePeriod?.(currentTimePeriod);
    track('apply_setup_changes', {
      segments: currentSegments,
      metrics: currentMetrics,
      timePeriod: currentTimePeriod ? currentTimePeriod.value : undefined,
    });
    onClose();
  };

  const drawerClasses = classNames(styles.drawer, { [styles.hidden]: !isOpen });

  return (
    <Drawer isOpen={isOpen} onClose={handleCancel}>
      <div className={drawerClasses}>
        <div className={styles.scrollable}>
          <div className={styles.header}>
            <h5>Select Report Segments and Metrics</h5>
            <div className="t-body-2">
              Slice your data into segments and select the metrics you want to report on.
            </div>
          </div>
          <Divider />
          <div className={styles.body}>
            <div className={styles.select}>
              <MultipleDropdown
                label={`Segment by (Select up to ${maxSegments - requiredSegments.length}${requiredSegments.length > 0 ? ' additional' : ''})`}
                menuProps={{ size: 'large' }}
                options={segmentOptions}
                selected={currentSegments}
                size="medium"
                onChange={handleSegmentChange}
              />
              { currentSegments.length > 0 && (
                <Button
                  label="Clear All"
                  variant="tertiary"
                  onClick={handleSegmentClear}
                />
              ) }
            </div>
            { currentSegments.length > 0 && (
              <div className={styles.selections}>
                { currentSegments.map((segment) => (
                  <ClickableTag
                    key={segment.value}
                    label={segment.label}
                    onRemove={segment.disabled ? undefined : (() => removeSegment(segment))}
                  />
                )) }
              </div>
            ) }
            <div className={styles.select}>
              <MultipleDropdown
                label="Add Metrics"
                menuProps={{ size: 'large' }}
                options={metrics}
                selected={currentMetrics}
                size="medium"
                onChange={handleMetricChange}
              />
              { currentMetrics.length > 0 && (
                <Button
                  label="Clear All"
                  variant="tertiary"
                  onClick={() => setCurrentMetrics([])}
                />
              ) }
            </div>
            { currentMetrics.length > 0 && (
              <div className={styles.selections}>
                { currentMetrics.map((metric) => (
                  <ClickableTag
                    key={metric.value}
                    label={metric.label}
                    onRemove={() => removeMetric(metric)}
                  />
                )) }
              </div>
            ) }
            { timePeriods && (
              <div className={styles.select}>
                <Dropdown
                  label="Time Period"
                  menuProps={{ size: 'medium' }}
                  options={timePeriods}
                  selected={currentTimePeriod}
                  size="medium"
                  onChange={handleTimePeriodChange}
                />
              </div>
            ) }
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.buttons}>
            <Button
              label="Cancel"
              variant="tertiary"
              onClick={handleCancel}
            />
            <Button
              disabled={isApplyDisabled}
              label="Apply"
              variant="primary"
              onClick={handleApply}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
}

BreakdownDrawer.propTypes = propTypes;
BreakdownDrawer.defaultProps = defaultProps;

export default BreakdownDrawer;
