import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from '@makeably/creativex-design-system';
import { tiersProps } from 'components/rules/tiers/Tiers';
import GuidelineWeights from 'components/scores/modal/GuidelineWeights';
import { guidelineProps } from 'components/scores/modal/ScoreModal';

const propTypes = {
  guidelines: PropTypes.arrayOf(
    PropTypes.shape(guidelineProps),
  ).isRequired,
  label: PropTypes.string.isRequired,
  tiers: PropTypes.arrayOf(tiersProps).isRequired,
  description: PropTypes.string,
};

const defaultProps = {
  description: undefined,
};

function formatTierStrings(tiers) {
  return tiers.map((tier, i) => {
    const {
      label,
      rank,
    } = tier;
    const maximum = rank === 'highest' ? 100 : tiers[i - 1].minimumCutoff - 1;
    const minimum = rank === 'lowest' ? 0 : tiers[i].minimumCutoff;
    const rangeString = maximum === minimum ? `${maximum}%` : `${minimum}-${maximum}%`;

    return {
      label,
      rangeString,
    };
  });
}

function ScoreConfirmation({
  description,
  guidelines,
  label,
  tiers,
}) {
  const formattedTiers = formatTierStrings(tiers);

  return (
    <>
      <h5 className="u-marginBottom-16">{ label }</h5>
      { description && <div className="t-body-1">{ description }</div> }
      <Divider margin />
      <h5 className="u-marginBottom-16">Tiers</h5>
      { formattedTiers.map((tier) => (
        <div className="t-body-1">
          { `${tier.label} Threshold: ${tier.rangeString}` }
        </div>
      )) }
      <Divider margin />
      <h5 className="u-marginBottom-16">Guidelines</h5>
      <GuidelineWeights guidelines={guidelines} />
    </>
  );
}

ScoreConfirmation.propTypes = propTypes;
ScoreConfirmation.defaultProps = defaultProps;

export default ScoreConfirmation;
