import React from 'react';
import PropTypes from 'prop-types';
import { MessageModal } from '@makeably/creativex-design-system';
import { titleize } from 'utilities/string';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

function BenchmarksDisclaimer({
  isOpen,
  onClose,
  type,
}) {
  const disclaimer = `These benchmarks are based on CreativeX's Creative Quality Score (CQS), which measures an ad’s digital suitability based on the 6 statistically validated digital fundamentals that are universal to all of the biggest online platforms. The benchmarks use anonymously aggregated data from our partners' published assets and media spend, and are statistically significant, normalized, and serve as a reliable industry standard. Each ${type} requires at least 3 companies for effective benchmarking. They're accumulated and published quarterly. Company data is updated daily to ensure relevance.`;

  return (
    <MessageModal
      actionButtonLabel="Close"
      closeButtonLabel=""
      isOpen={isOpen}
      size="medium"
      title={`${titleize(type)} Benchmarks Overview`}
      onActionButtonClick={onClose}
      onClose={onClose}
    >
      <div className="t-body-1">
        { disclaimer }
      </div>
    </MessageModal>
  );
}

BenchmarksDisclaimer.propTypes = propTypes;

export default BenchmarksDisclaimer;
