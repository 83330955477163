import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Spinner } from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import Tiers from 'components/rules/tiers/Tiers';
import { tierCollectionDataTierPath } from 'utilities/routes';
import styles from './ScoreTiers.module.css';

const propTypes = {
  scoreId: PropTypes.number.isRequired,
};

function ScoreTiers({ scoreId }) {
  const [tierData, setTierData] = useState();

  useEffect(() => {
    async function fetchProps() {
      const response = await fetch(tierCollectionDataTierPath(scoreId));

      if (response.ok) {
        const data = await response.json();

        setTierData(data);
      } else {
        addToast('Something went wrong, please reload to try again.', {
          size: 'large',
          type: 'error',
        });
      }
    }

    fetchProps();
  }, [scoreId]);

  if (!tierData) {
    return (
      <div className={styles.spinner}>
        <Spinner />
      </div>
    );
  }

  const {
    defaultTiers,
    editable,
    isStandard,
    scoreName,
    tierCollectionId,
    tiers,
  } = tierData;

  return (
    <Tiers
      defaultTiers={defaultTiers}
      editable={editable}
      isStandard={isStandard}
      scoreName={scoreName}
      tierCollectionId={tierCollectionId}
      tiers={tiers}
    />
  );
}

ScoreTiers.propTypes = propTypes;

export default ScoreTiers;
