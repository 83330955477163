import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Search } from '@makeably/creativex-design-system';
import CollapsibleWidget from 'components/molecules/CollapsibleWidget';
import styles from './BrandWords.module.css';

export const propTypes = {
  companyName: PropTypes.string.isRequired,
  isBranded: PropTypes.bool.isRequired,
  brandWords: PropTypes.arrayOf(PropTypes.string),
  isOmnibrand: PropTypes.bool,
};

const defaultProps = {
  brandWords: [],
  isOmnibrand: false,
};

const SEARCH_MIN_SIZE = 12;

function BrandWords({
  brandWords,
  companyName,
  isBranded,
  isOmnibrand,
}) {
  const [search, setSearch] = useState('');
  const showSearch = brandWords?.length >= SEARCH_MIN_SIZE;
  const words = (brandWords ?? []).map((word) => word.toLowerCase());

  const searchedWords = useMemo(() => {
    const term = search.toLowerCase();

    return words.filter((word) => word.indexOf(term) !== -1);
  }, [words, search]);

  if (!isBranded || words.length === 0) return null;

  return (
    <CollapsibleWidget headerText="Brand Words">
      <div className={styles.content}>
        { isOmnibrand && (
          <div className="u-marginBottom-16">
            <h6>{ `All ${companyName} Brands` }</h6>
          </div>
        ) }
        { showSearch && (
          <div className="u-marginBottom-16">
            <Search
              placeholder="Search by Brand"
              value={search}
              onChange={setSearch}
            />
          </div>
        ) }
        <div className="u-scrollShadowBottom u-flexColumn u-gap-8">
          { searchedWords.map((word) => (
            <div key={word}>{ word }</div>
          )) }
        </div>
      </div>
    </CollapsibleWidget>
  );
}

BrandWords.propTypes = propTypes;
BrandWords.defaultProps = defaultProps;

export default BrandWords;
