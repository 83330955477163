import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Icon,
  RadioGroup,
  TextArea,
  TextInput,
  Tooltip,
} from '@makeably/creativex-design-system';
import styles from './CheckRow.module.css';

export const checkPropType = {
  description: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  passed: PropTypes.bool.isRequired,
  type: PropTypes.oneOf(['guideline', 'category', 'regulatory', 'hypothesis']).isRequired,
  altText: PropTypes.string,
  challengeReviewed: PropTypes.bool,
  challenged: PropTypes.bool,
  detailName: PropTypes.string,
};

export const challengePropType = {
  challenged: PropTypes.bool.isRequired,
  exemptionReason: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  matchingAssetId: PropTypes.string.isRequired,
  reason: PropTypes.string.isRequired,
};

const propTypes = {
  challenge: PropTypes.shape(challengePropType).isRequired,
  challengeReasons: PropTypes.objectOf(PropTypes.string).isRequired,
  guidelineCheck: PropTypes.shape(checkPropType).isRequired,
  isAdminUser: PropTypes.bool.isRequired,
  onExemptionReasonChange: PropTypes.func.isRequired,
  onGuidelineClick: PropTypes.func.isRequired,
  onMatchingAssetIdChange: PropTypes.func.isRequired,
  onReasonChange: PropTypes.func.isRequired,
};

const label = (
  <div className={styles.label}>
    CreativeX ID
    <Tooltip ariaLabelSubject="CreativeX ID" label="Add the CreativeX ID of the identical asset here" />
  </div>
);

function getReasons(challengeReasons, isAdminUser, checkPassed) {
  const reasonOptions = [];

  if (checkPassed) {
    reasonOptions.push('shouldNotHavePassed', 'identicalCreativeFailed');
  } else {
    reasonOptions.push('shouldHavePassed', 'identicalCreativePassed');
  }

  if (isAdminUser) {
    reasonOptions.push('exemption');
  }

  return reasonOptions.map((reason) => (
    {
      label: challengeReasons[reason],
      value: reason,
    }
  ));
}

function CheckRow(props) {
  const {
    challenge: {
      challenged,
      exemptionReason,
      isError,
      matchingAssetId,
      reason,
    },
    challengeReasons,
    guidelineCheck: {
      description,
      id,
      name,
      passed,
      altText,
      challengeReviewed,
      challenged: previouslyChallenged,
      detailName,
    },
    isAdminUser,
    onExemptionReasonChange,
    onGuidelineClick,
    onMatchingAssetIdChange,
    onReasonChange,
  } = props;

  const showExtra = !previouslyChallenged && challenged;
  const isIdenticalReason = ['identicalCreativePassed', 'identicalCreativeFailed'].includes(reason);
  const isExemptionReason = reason === 'exemption';

  let icon;
  if (passed) {
    icon = <Icon color="green" name="checkCircle" />;
  } else {
    icon = <Icon color="red" name="xCircle" />;
  }

  const classes = classNames(
    styles.clickableDiv,
    { [styles.disabled]: previouslyChallenged },
  );
  const contentClasses = classNames(
    styles.content,
    { [styles.challenged]: challenged },
  );

  let disabledText = 'This rule is currently under review';
  if (challengeReviewed) disabledText = 'This rule has already been reviewed';

  return (
    <div
      className={classes}
    >
      <div className={contentClasses}>
        <div className={styles.header}>
          <Checkbox
            checked={challenged}
            disabled={previouslyChallenged}
            label={name}
            onChange={onGuidelineClick}
          />
          { icon }
        </div>
        <>
          { previouslyChallenged && <p className={styles.disabled}>{ disabledText }</p> }
          { showExtra && (
          <>
            <div className={classNames(styles.checkDetails, 't-caption-1')}>
              { altText && <span>{ altText }</span> }
              { detailName && <span>{ detailName }</span> }
              <span>{ description }</span>
            </div>
            <div className={styles.radioGroup}>
              <RadioGroup
                name={`challenge_${id}`}
                options={getReasons(challengeReasons, isAdminUser, passed)}
                value={reason}
                onChange={onReasonChange}
              />
            </div>
            { isIdenticalReason && (
              <TextInput
                isError={isError}
                label={label}
                placeholder="Empty"
                value={matchingAssetId}
                onChange={onMatchingAssetIdChange}
              />
            ) }
            { isExemptionReason && (
              <TextArea
                placeholder="Please provide information here to help our team understand why this creative should be exempted."
                size="large"
                value={exemptionReason}
                onChange={onExemptionReasonChange}
              />
            ) }
          </>
          ) }
        </>
      </div>
    </div>
  );
}

CheckRow.propTypes = propTypes;

export default CheckRow;
