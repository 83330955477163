import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Dropdown,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import {
  areValid,
  createRow,
  duplicateLastRow,
  hasContent,
  parseCsv,
} from 'components/internal/connections/shared';
import { optionArrayProps } from 'components/internal/shared';
import ActionButton from 'components/molecules/ActionButton';
import BulkSelect from 'components/molecules/BulkSelect';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import {
  findDuplicates,
  findObjectByValue,
  removeByIndex,
  replaceAtIndex,
  splitArray,
} from 'utilities/array';
import {
  loadTextFile,
  saveItemsCsvFile,
} from 'utilities/file';
import {
  addFormDataArray,
  post,
} from 'utilities/requests';
import { internalDv360CampaignsPath } from 'utilities/routes';
import { removeNonDigits } from 'utilities/string';
import {
  getParams,
  redirectWithParam,
} from 'utilities/url';

const propTypes = {
  companyOptions: optionArrayProps.isRequired,
  brandOptions: optionArrayProps,
  marketOptions: optionArrayProps,
  selectedCompanyId: PropTypes.number,
};

const defaultProps = {
  brandOptions: [],
  marketOptions: [],
  selectedCompanyId: undefined,
};

const CONNECT_BATCH_SIZE = 10;

const headers = [
  {
    key: 'campaignId',
    label: 'Campaign ID',
    type: 'text',
  },
  {
    key: 'advertiserId',
    label: 'Advertiser ID',
    type: 'text',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
];

function flagErrors(connections) {
  const campaignIds = connections.map(({ campaignId }) => campaignId);
  const duplicateSet = findDuplicates(campaignIds);
  const error = 'A campaigns ID can only be connected to a single ad account';

  return connections.map((connection) => ({
    ...connection,
    error: duplicateSet.has(connection.campaignId) ? error : null,
  }));
}

function getFormData(selectedCompany, connections) {
  const converted = connections.map((connection) => ({
    api_ad_campaign_id: removeNonDigits(connection.campaignId),
    api_customer_id: removeNonDigits(connection.advertiserId),
    brand_id: connection.brand.value,
    market: connection.market.label,
    uuid: connection.uuid,
  }));

  const formData = new FormData();
  formData.append('company_id', selectedCompany.value);
  addFormDataArray(formData, 'connections', converted);

  return formData;
}

async function postBatch(selectedCompany, batch) {
  const formData = getFormData(selectedCompany, batch);
  const { data, isError } = await post(internalDv360CampaignsPath(), formData);

  if (isError) {
    const error = 'An unknown error occured. Please try again later';

    return {
      successes: [],
      failures: batch.map(({ uuid }) => ({
        error,
        uuid,
      })),
    };
  }

  return {
    successes: data.results.filter(({ campaignId }) => campaignId),
    failures: data.results.filter(({ campaignId }) => !campaignId),
  };
}

function NewDv360Campaign({
  brandOptions,
  companyOptions,
  marketOptions,
  selectedCompanyId,
}) {
  const params = getParams(window);
  const [lastCompanyId, setLastCompanyId] = useLocalStorage('cxIntCompanyId', null);
  const [selectedCompany, setSelectedCompany] = useState(
    findObjectByValue(companyOptions, selectedCompanyId),
  );
  const [connections, setConnections] = useState([createRow(headers)]);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsByKey = {
    brand: brandOptions,
    market: marketOptions,
  };
  const hasErrors = connections.some(({ error }) => Boolean(error));
  const canConnect = !hasErrors && areValid(connections, headers);

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setLastCompanyId(option?.value);
    redirectWithParam('company_id', option.value, params, window);
  };

  useEffect(() => {
    if (!selectedCompanyId && lastCompanyId) {
      const found = companyOptions.find((option) => option.value === lastCompanyId);

      if (found) {
        handleCompanyChange(found);
      }
    }
  }, [selectedCompanyId, companyOptions, lastCompanyId]);

  const handleCsvUpload = async () => {
    const csv = await loadTextFile('.csv');
    const parsed = parseCsv(csv, headers, optionsByKey);
    const existing = connections.filter((connection) => hasContent(connection, headers));

    const toAdd = [...existing, ...parsed];
    setConnections(flagErrors(toAdd));
    addToast(`Parsed data for ${parsed.length} connection(s)`);
  };

  const handleConnectionChange = (index, key, value) => {
    setConnections((last) => {
      const updated = {
        ...last[index],
        [key]: value,
      };

      return flagErrors(replaceAtIndex(last, index, updated));
    });
  };

  const updateConnections = (successes, failures) => {
    setConnections((last) => (
      last.reduce((arr, connection) => {
        const success = successes.find(({ uuid }) => uuid === connection.uuid);
        const failure = failures.find(({ uuid }) => uuid === connection.uuid);

        if (failure?.error) {
          return [
            ...arr,
            {
              ...connection,
              error: failure.error,
            },
          ];
        }
        if (success) {
          return arr;
        }
        return [...arr, connection];
      }, [])
    ));
  };

  const handleConnect = async () => {
    setIsConnecting(true);

    const batches = splitArray(connections, CONNECT_BATCH_SIZE);

    const [succeeded, failed] = await batches.reduce((promise, batch) => (
      promise.then(async ([ok, bad]) => {
        const { successes, failures } = await postBatch(selectedCompany, batch);

        updateConnections(successes, failures);
        return [ok + successes.length, bad + failures.length];
      })), Promise.resolve([0, 0]));

    if (succeeded > 0) {
      addToast(`Added ${succeeded} DV360 campaigns`);
    }
    if (failed > 0) {
      addErrorToast(`Could not connect ${failed} DV360 campaigns`);
    }

    setIsConnecting(false);
  };

  return (
    <Card className="u-flexColumn u-gap-24">
      <Dropdown
        label="Company"
        menuProps={{ size: 'medium' }}
        options={companyOptions}
        selected={selectedCompany}
        size="medium"
        onChange={handleCompanyChange}
      />
      <Divider />
      <div className="u-flexRow u-justifyEnd u-gap-16">
        <Button
          label="Download CSV Template"
          variant="tertiary"
          onClick={() => saveItemsCsvFile('dv360', [], headers)}
        />
        <Button
          label="Upload CSV"
          variant="secondary"
          onClick={handleCsvUpload}
        />
      </div>
      <BulkSelect
        disabled={isConnecting}
        headers={headers}
        optionsByKey={optionsByKey}
        rows={connections}
        onAdd={() => setConnections((last) => [...last, createRow(headers)])}
        onChange={handleConnectionChange}
        onDuplicate={() => setConnections((last) => duplicateLastRow(last, headers))}
        onRemove={(index) => setConnections((last) => flagErrors(removeByIndex(last, index)))}
      />
      <div className="u-flexRow u-justifyEnd u-gap-8">
        <Button
          label="Reset"
          variant="secondary"
          onClick={() => setConnections([createRow(headers)])}
        />
        <ActionButton
          active={isConnecting}
          disabled={!canConnect}
          label="Connect"
          onClick={handleConnect}
        />
      </div>
    </Card>
  );
}

NewDv360Campaign.propTypes = propTypes;
NewDv360Campaign.defaultProps = defaultProps;

export default NewDv360Campaign;
