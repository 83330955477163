import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import ReportFilter, { getOptions } from 'components/molecules/ReportFilter';
import {
  getPublisherLabel,
  EMPTY_VALUE,
} from 'components/shared/channel';
import {
  titleize,
} from 'utilities/string';

const dimensionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

export const filterProps = PropTypes.shape({
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
});

export const recordProps = PropTypes.shape({
  assetIds: PropTypes.arrayOf(PropTypes.number).isRequired,
  assetType: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  contentType: PropTypes.string.isRequired,
  market: PropTypes.string.isRequired,
  creativeAgency: PropTypes.string,
  excellentSpend: PropTypes.number,
  mediaAgency: PropTypes.string,
  partner: PropTypes.string,
  rank: PropTypes.string,
  score: PropTypes.number,
  spend: PropTypes.number,
});

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  records: PropTypes.arrayOf(recordProps).isRequired,
  segments: PropTypes.arrayOf(dimensionProps).isRequired,
  selections: PropTypes.objectOf(
    PropTypes.arrayOf(filterProps),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onSelectionsChange: PropTypes.func.isRequired,
};

function getCustomOption(asset, key) {
  if (key === 'assetType') {
    const value = asset[key];
    const label = titleize(value);

    return {
      label,
      value,
    };
  } else if (key === 'publisher') {
    const value = asset[key] ?? EMPTY_VALUE;
    const label = getPublisherLabel(value);

    return {
      label,
      value,
    };
  }
  return null;
}

function PreflightsFilter({
  isOpen,
  onClose,
  onOpen,
  onSelectionsChange,
  records,
  segments,
  selections,
}) {
  const [options, setOptions] = useState({});

  useEffect(() => {
    setOptions(getOptions(records, segments, getCustomOption));
  }, [segments, records]);

  return (
    <ReportFilter
      isOpen={isOpen}
      options={options}
      segments={segments}
      selections={selections}
      onClose={onClose}
      onOpen={onOpen}
      onSelectionsChange={onSelectionsChange}
    />
  );
}

PreflightsFilter.propTypes = propTypes;

export default PreflightsFilter;
