import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  ClickableTag,
  CloseButton,
  useKeyDown,
} from '@makeably/creativex-design-system';
import { toDate } from 'utilities/string';
import styles from './DeprecatedTag.module.css';

const propTypes = {
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  replacementUrl: PropTypes.string.isRequired,
  level: PropTypes.string,
};

const defaultProps = {
  level: 'page',
};

const PHIL_URL = 'https://picassolabs.slack.com/app_redirect?channel=U0351JQJWDP';
const CHANNEL_URL = 'https://picassolabs.slack.com/archives/C077KG43YRK';

function DeprecatedTag({
  date,
  level,
  name,
  replacementUrl,
}) {
  const [overlayShown, setOverlayShown] = useState(false);

  const onClose = () => setOverlayShown(false);

  useKeyDown('Escape', onClose);

  return (
    <div className={styles.container}>
      <ClickableTag
        color="red"
        label="Deprecated"
        onClick={() => setOverlayShown((last) => !last)}
      />
      { overlayShown && (
        <>
          <button
            aria-hidden="true"
            className={styles.overlayBackground}
            type="button"
            onClick={onClose}
          />
          <div className={`u-elevation-2 ${styles.overlay}`}>
            <div className="u-flexRow u-justifyEnd">
              <CloseButton onClick={onClose} />
            </div>
            <div className={`t-body-1 ${styles.content}`}>
              <div>
                { `The ${name} ${level} has been deprecated` }
                { ` and will be removed on ${toDate(new Date(date))}.` }
              </div>
              <div>
                Please use the
                <a href={replacementUrl}>{ ` replacement ${level}` }</a>
                .
              </div>
              <div>
                If you still need this feature, or the replacement is
                missing a feature, please send a message to
                <a href={PHIL_URL}> @Phil </a>
                <br />
                or to
                <a href={CHANNEL_URL}> #project-internal-tools </a>
              </div>
            </div>
          </div>
        </>
      ) }
    </div>
  );
}

DeprecatedTag.propTypes = propTypes;
DeprecatedTag.defaultProps = defaultProps;

export default DeprecatedTag;
