import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  BasicModal,
  Button,
  CloseButton,
  MultipleDropdown,
  toggleObject,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import { useTimeout } from 'utilities/hooks';
import { getAuthenticityToken } from 'utilities/requests';
import { duplicateAdminGuidelinePath } from 'utilities/routes';
import styles from './DuplicateGuideline.module.css';

const propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  ).isRequired,
  id: PropTypes.number.isRequired,
  isTemplate: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  standard: PropTypes.bool.isRequired,
};

async function postDuplicateGuideline(id, companies) {
  const token = getAuthenticityToken();
  const formData = new FormData();
  formData.append('authenticity_token', token);
  companies.forEach(({ value }) => formData.append('company_ids[]', value));

  const response = await fetch(duplicateAdminGuidelinePath(id), {
    method: 'POST',
    body: formData,
  });
  return response.ok ? response.json() : { success: false };
}

function DuplicateGuideline({
  companies,
  id,
  isTemplate,
  name,
  standard,
}) {
  const companyOptions = companies.map((company) => ({
    label: company.name,
    value: company.id,
  }));

  const [isProcessing, setIsProcessing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  const handleClose = () => setIsModalOpen(false);

  const guidelineLabel = `${standard ? '[CQ] ' : ''}${name} (${id})`;

  const duplicateGuideline = async () => {
    setIsProcessing(true);

    const toastOpts = { size: 'large' };
    handleClose();

    const data = await postDuplicateGuideline(id, selectedCompanies);

    if (data.success) {
      if (selectedCompanies.length > 0) {
        selectedCompanies.forEach(({ label: companyName }) => (
          addToast(`Successfully duplicated ${guidelineLabel} to ${companyName}`, toastOpts)
        ));
      } else {
        addToast(`Successfully duplicated ${guidelineLabel}`, toastOpts);
      }
      const currentUrl = window.location.href;
      window.location.href = currentUrl;
    } else {
      toastOpts.type = 'error';
      addToast('Issues duplicating for some/all selections', toastOpts);

      if (selectedCompanies.length > 0) {
        (data.failures || []).forEach((companyName) => (
          addToast(`Unable to duplicate ${guidelineLabel} to ${companyName}`, toastOpts)
        ));
      } else {
        addToast(`Unable to duplicate ${guidelineLabel}`, toastOpts);
      }
    }

    // In order to prevent accidental duplicate clicks on the duplicate button
    // We want to institute a delay before we re-enable the duplicate button
    // This delay is 1s
    useTimeout(() => setIsProcessing(false), 1000);
  };

  const footer = (
    <div className="u-buttonGroup">
      <Button
        label="Cancel"
        variant="tertiary"
        onClick={handleClose}
      />
      <Button
        disabled={selectedCompanies.length === 0}
        label="Confirm"
        onClick={duplicateGuideline}
      />
    </div>
  );

  return (
    <>
      <button
        className={styles.button}
        disabled={isProcessing}
        type="button"
        onClick={() => (isTemplate ? setIsModalOpen(true) : duplicateGuideline())}
      >
        Duplicate
      </button>
      { isTemplate && (
        <BasicModal
          footer={footer}
          header={<CloseButton onClick={handleClose} />}
          isOpen={isModalOpen}
          size="medium"
          onClose={handleClose}
        >
          <div className={styles.modalContent}>
            <h5 className={styles.header}>
              { `Duplicating guideline ${guidelineLabel}.\nSelect the companies to duplicate to below:` }
            </h5>
            <div className={styles.selector}>
              <MultipleDropdown
                label="Companies"
                options={companyOptions}
                selected={selectedCompanies}
                onChange={(c) => setSelectedCompanies((last) => toggleObject(last, c))}
              />
              { selectedCompanies.length > 1 && (
                <div>
                  <div className="t-caption-1">Duplicating to:</div>
                  <div className="t-body-1">
                    { selectedCompanies.map(({ label }) => label).sort().join(', ') }
                  </div>
                </div>
              ) }
            </div>
          </div>
        </BasicModal>
      ) }
    </>
  );
}

DuplicateGuideline.propTypes = propTypes;

export default DuplicateGuideline;
