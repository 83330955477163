import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { stepProps } from 'components/internal/PipelineStep';
import Chart from 'components/molecules/Chart';
import { getUniques } from 'utilities/array';
import { getCssVarsObj } from 'utilities/css';
import { stringToDate } from 'utilities/date';
import { toDate } from 'utilities/string';

const dateStepsProps = PropTypes.arrayOf(
  PropTypes.shape({
    date: PropTypes.string.isRequired,
    steps: PropTypes.objectOf(stepProps).isRequired,
  }),
);

const propTypes = {
  dateSteps: dateStepsProps.isRequired,
  onDateStepSelect: PropTypes.func.isRequired,
  selectedDate: PropTypes.string,
  selectedStep: PropTypes.string,
};

const defaultProps = {
  selectedDate: undefined,
  selectedStep: undefined,
};

const stepVarColors = {
  bad: '--red-500',
  failed: '--red-300',
  unsupported: '--grey-200',
  '0-': '--orange-300',
  '0-a': '--orange-400',
  '0-ab': '--orange-500',
  '0-bc': '--orange-500',
  '1-abc': '--green-400',
  '2-abcd': '--blue-300',
  '2-abcde': '--blue-400',
  '2-abcdf': '--blue-500',
  '3-abcdef': '--purple-300',
  '4-abcdefg': '--purple-400',
};
const stepColors = getCssVarsObj(stepVarColors);

function getLabels(dateSteps) {
  return dateSteps.map(({ date }) => toDate(stringToDate(date), true));
}

function getAllSteps(dateSteps) {
  const allSteps = dateSteps.flatMap(({ steps }) => Object.keys(steps));

  return getUniques(allSteps).sort().reverse();
}

function checkSelected(date, step, selectedDate, selectedStep) {
  if (!selectedDate) return true;

  if (date === selectedDate) {
    if (!selectedStep) return true;

    return step === selectedStep;
  }

  return false;
}

function getStepColorHex(step) {
  if (step.endsWith('unsupported')) {
    return stepColors.unsupported;
  } else if (step.endsWith('failed')) {
    return stepColors.failed;
  } else if (step.endsWith('bad')) {
    return stepColors.bad;
  }

  return stepColors[step] ?? 'black';
}

function getStepColor(date, step, selectedDate, selectedStep) {
  const isSelected = checkSelected(date, step, selectedDate, selectedStep);
  const alpha = isSelected ? 1 : 0.3;
  const hex = getStepColorHex(step);

  return `rgb(from ${hex} r g b / ${alpha})`;
}

function getChartData(dateSteps, selectedDate, selectedStep) {
  const labels = getLabels(dateSteps);
  const allSteps = getAllSteps(dateSteps);
  const datasets = allSteps.map((step) => ({
    label: step,
    data: dateSteps.map(({ steps }) => steps[step]?.count),
    backgroundColor: dateSteps.map(({ date }) => (
      getStepColor(date, step, selectedDate, selectedStep)
    )),
  }));

  return {
    datasets,
    labels,
  };
}

function getChartOptions(handleElementClick) {
  return {
    animation: {
      duration: 0,
    },
    barPercentage: 0.9,
    categoryPercentage: 1,
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const { datasetIndex, index } = elements[0];

        handleElementClick(index, datasetIndex);
      }
    },
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
        events: [],
      },
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
  };
}

function PipelineChart({
  dateSteps,
  onDateStepSelect,
  selectedDate,
  selectedStep,
}) {
  const chartData = useMemo(() => (
    getChartData(dateSteps, selectedDate, selectedStep)
  ), [dateSteps, selectedDate, selectedStep]);

  const handleElementClick = (index, datasetIndex) => {
    const { date } = dateSteps[index];
    const step = chartData.datasets[datasetIndex].label;

    // @note: the setTimeout avoids a chart.js console error in updating tooltips while data changes
    setTimeout(() => onDateStepSelect(date, step), 0);
  };

  const chartOptions = useMemo(() => getChartOptions(handleElementClick), [handleElementClick]);

  return (
    <Chart
      data={chartData}
      options={chartOptions}
      type="bar"
    />
  );
}

PipelineChart.propTypes = propTypes;
PipelineChart.defaultProps = defaultProps;

export default PipelineChart;
