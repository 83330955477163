import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Dropdown,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import {
  addClientWarning,
  areValid,
  createRow,
  duplicateLastRow,
  hasContent,
  parseCsv,
} from 'components/internal/connections/shared';
import { optionArrayProps } from 'components/internal/shared';
import ActionButton from 'components/molecules/ActionButton';
import BulkSelect from 'components/molecules/BulkSelect';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import {
  findObjectByValue,
  removeByIndex,
  replaceAtIndex,
} from 'utilities/array';
import {
  loadTextFile,
  saveItemsCsvFile,
} from 'utilities/file';
import { post } from 'utilities/requests';
import { internalGoogleAdsAccountsPath } from 'utilities/routes';
import { removeNonDigits } from 'utilities/string';
import {
  getParams,
  redirectWithParam,
} from 'utilities/url';
import styles from './NewGoogleAdsAccount.module.css';

export const clientProps = PropTypes.objectOf(
  PropTypes.shape({
    brandId: PropTypes.number.isRequired,
    id: PropTypes.number.isRequired,
    marketId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
);

const propTypes = {
  companyOptions: optionArrayProps.isRequired,
  brandOptions: optionArrayProps,
  clients: clientProps,
  integrationOptions: optionArrayProps,
  marketOptions: optionArrayProps,
  partnerOptions: optionArrayProps,
  selectedCompanyId: PropTypes.number,
  userOptions: optionArrayProps,
};

const defaultProps = {
  brandOptions: [],
  clients: [],
  integrationOptions: [],
  marketOptions: [],
  partnerOptions: [],
  selectedCompanyId: undefined,
  userOptions: [],
};

const headers = [
  {
    alternateLabel: 'Advertiser ID',
    key: 'apiClientId',
    label: 'API Client ID',
    type: 'text',
  },
  {
    alternateLabel: 'Linked Email (integrations)',
    key: 'integration',
    label: 'Integration Email',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    alternateLabel: 'Managing Agency',
    key: 'partner',
    label: 'Partner',
  },
  {
    alternateLabel: 'Connecting User (probably you)',
    key: 'user',
    label: 'User Email',
  },
];

function getConnectData(selectedCompany, connections) {
  const converted = connections.map((connection) => ({
    affiliate_id: connection.partner.value,
    api_client_id: removeNonDigits(connection.apiClientId),
    brand_id: connection.brand.value,
    integration_id: connection.integration.value,
    market_id: connection.market.value,
    user_id: connection.user.value,
    uuid: connection.uuid,
  }));

  const formData = new FormData();
  formData.append('company_id', selectedCompany.value);
  formData.append('connections', JSON.stringify(converted));

  return formData;
}

function NewGoogleAdsAccount({
  brandOptions,
  clients,
  companyOptions,
  integrationOptions,
  marketOptions,
  partnerOptions,
  selectedCompanyId,
  userOptions,
}) {
  const params = getParams(window);
  const [lastCompanyId, setLastCompanyId] = useLocalStorage('cxIntCompanyId', null);
  const [selectedCompany, setSelectedCompany] = useState(
    findObjectByValue(companyOptions, selectedCompanyId),
  );
  const [connections, setConnections] = useState([createRow(headers)]);
  const [isConnecting, setIsConnecting] = useState(false);
  const optionsByKey = {
    integration: integrationOptions,
    brand: brandOptions,
    market: marketOptions,
    partner: partnerOptions,
    user: userOptions,
  };
  const canConnect = areValid(connections, headers);

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setLastCompanyId(option?.value);
    redirectWithParam('company_id', option.value, params, window);
  };

  useEffect(() => {
    if (!selectedCompanyId && lastCompanyId) {
      const found = companyOptions.find((option) => option.value === lastCompanyId);

      if (found) {
        handleCompanyChange(found);
      }
    }
  }, [selectedCompanyId, companyOptions, lastCompanyId]);

  const handleCsvUpload = async () => {
    const csv = await loadTextFile('.csv');
    const parsed = parseCsv(csv, headers, optionsByKey);
    const checked = parsed.map((connection) => addClientWarning(connection, clients));
    const existing = connections.filter((connection) => hasContent(connection, headers));

    setConnections([...existing, ...checked]);
    addToast(`Parsed data for ${checked.length} connection(s)`);
  };

  const handleConnectionChange = (index, key, value) => {
    setConnections((last) => {
      const updated = {
        ...last[index],
        [key]: value,
      };
      const checked = addClientWarning(updated, clients);
      return replaceAtIndex(last, index, checked);
    });
  };

  const handleConnect = async () => {
    setIsConnecting(true);
    const formData = getConnectData(selectedCompany, connections);
    const { data, isError } = await post(internalGoogleAdsAccountsPath(), formData);

    if (isError) {
      addErrorToast('Could not connect Google Ads accounts. Please try again later');
    } else {
      const successes = data.results.filter(({ adAccountId }) => adAccountId);
      const failures = data.results.filter(({ adAccountId }) => !adAccountId);

      if (successes.length > 0) {
        addToast(`Connected ${successes.length} Google Ads accounts`);
      }
      if (failures.length > 0) {
        addErrorToast(`Could not connect ${failures.length} Google Ads accounts`);
      }

      setConnections((last) => {
        const error = 'This account could not be connected. '
          + 'Please verify the API Client ID and Integration Email';

        return last.reduce((arr, connection) => {
          const hasFailed = Boolean(failures.find(({ uuid }) => uuid === connection.uuid));

          if (hasFailed) {
            return [
              ...arr,
              {
                ...connection,
                error,
              },
            ];
          }

          return arr;
        }, []);
      });
    }
    setIsConnecting(false);
  };

  return (
    <Card>
      <div className={styles.controls}>
        <Dropdown
          label="Company"
          menuProps={{ size: 'medium' }}
          options={companyOptions}
          selected={selectedCompany}
          size="medium"
          onChange={handleCompanyChange}
        />
        <div className="u-flexRow u-gap-16">
          <Button
            label="Download CSV Template"
            variant="tertiary"
            onClick={() => saveItemsCsvFile('googleAds', [], headers)}
          />
          <Button
            label="Upload CSV"
            variant="secondary"
            onClick={handleCsvUpload}
          />
        </div>
      </div>
      <BulkSelect
        headers={headers}
        optionsByKey={optionsByKey}
        rows={connections}
        onAdd={() => setConnections((vals) => [...vals, createRow(headers)])}
        onChange={handleConnectionChange}
        onDuplicate={() => setConnections((vals) => duplicateLastRow(vals, headers))}
        onRemove={(index) => setConnections((vals) => removeByIndex(vals, index))}
      />
      <div className={styles.connect}>
        <div className="u-flexRow u-gap-8">
          <Button
            label="Reset"
            variant="secondary"
            onClick={() => setConnections([createRow(headers)])}
          />
          <ActionButton
            active={isConnecting}
            disabled={!canConnect}
            label="Connect"
            onClick={handleConnect}
          />
        </div>
      </div>
    </Card>
  );
}

NewGoogleAdsAccount.propTypes = propTypes;
NewGoogleAdsAccount.defaultProps = defaultProps;

export default NewGoogleAdsAccount;
