import React from 'react';
import PropTypes from 'prop-types';
import ItemsBarChart from 'components/molecules/ItemsBarChart';
import { getCssVars } from 'utilities/css';
import { itemProps } from 'utilities/item';

const propTypes = {
  items: PropTypes.arrayOf(itemProps).isRequired,
  isSingleColor: PropTypes.bool,
};

const defaultProps = {
  isSingleColor: false,
};

const padding = 16;
const perItemHeight = 36;
const twoColors = getCssVars(['--purple-500', '--grey-300']);

const chartOptions = {
  borderRadius: 3,
  indexAxis: 'y',
  layout: {
    padding: {
      right: 50,
    },
  },
  maintainAspectRatio: false,
  plugins: {
    datalabels: {
      align: 'end',
      anchor: 'end',
      offset: 5,
      formatter: (value, context) => {
        const index = context.dataIndex;
        const contents = context.dataset.contents ?? [];
        const dataLabel = contents[index] ?? `${value.toFixed(0)}`;
        return dataLabel;
      },
    },
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: ({ dataset, dataIndex }) => {
          const { tooltips } = dataset;
          const tooltip = tooltips[dataIndex].split(',');
          return tooltip;
        },
        title: () => null,
      },
      displayColors: false,
    },
  },
  scales: {
    x: {
      border: {
        display: false,
      },
      grid: {
        display: false,
        drawTicks: false,
      },
      max: 1.0,
      ticks: {
        beginAtZero: true,
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
    },
  },
};

function BenchmarkBarChart({
  items,
  isSingleColor,
}) {
  const height = padding + (items.length * perItemHeight);
  const colors = isSingleColor ? twoColors.slice(0, 1) : twoColors;

  return (
    <div style={{ height: `${height}px` }}>
      <ItemsBarChart
        customChartOptions={chartOptions}
        customColors={colors}
        displayKey="metric"
        items={items}
        labelKey="label"
        tooltipKeys={['tooltip']}
      />
    </div>
  );
}

BenchmarkBarChart.propTypes = propTypes;
BenchmarkBarChart.defaultProps = defaultProps;

export default BenchmarkBarChart;
