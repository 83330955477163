import { v4 as uuidv4 } from 'uuid';
import {
  removeAccents,
  splitLines,
} from 'utilities/string';

export const MULTI_BRAND = 'Multi-Brand';
export const MULTI_MARKET = 'Multi-Market';

export function createRow(headers) {
  return headers.reduce((obj, { key, type }) => ({
    ...obj,
    [key]: type === 'text' ? '' : null,
  }), {
    uuid: uuidv4(),
  });
}

export function duplicateLastRow(rows, headers) {
  const duplicate = rows.at(-1) ?? createRow(headers);

  return [
    ...rows,
    {
      ...duplicate,
      uuid: uuidv4(),
    },
  ];
}

function parseCsvIndices(firstLine, headers) {
  const labels = firstLine.split(',');

  return headers.map((column) => {
    const index = labels.indexOf(column.label);

    if (index === -1) {
      return labels.indexOf(column.alternateLabel);
    }
    return index;
  });
}

export function parseCsv(csv, headers, optionsByKey) {
  const unaccented = removeAccents(csv);
  const lines = splitLines(unaccented);
  const indices = parseCsvIndices(lines[0], headers);

  return lines.slice(1).map((line) => {
    const values = line.split(',');

    return indices.reduce((obj, valueIndex, index) => {
      if (valueIndex === -1) {
        return obj;
      }

      const { key, type } = headers[index];
      const value = values[valueIndex];

      if (type === 'text') {
        return {
          ...obj,
          [key]: value,
        };
      }

      const options = optionsByKey[key] ?? [];
      const found = options.find((option) => option.label.toLowerCase() === value.toLowerCase())
        ?? null;

      return {
        ...obj,
        [key]: found,
      };
    }, createRow(headers));
  });
}

function getClientWarning(brandId, marketId, clients) {
  if (brandId && marketId) {
    if (brandId === MULTI_BRAND || marketId === MULTI_MARKET) return null;

    if (!clients[`${brandId}::${marketId}`]) {
      return 'No existing client with the selected brand and market. Adding this link will create a new client.';
    }
  }

  return null;
}

export function addClientWarning(connection, clients) {
  const { brand, market } = connection;

  return {
    ...connection,
    warning: getClientWarning(brand?.value, market?.value, clients),
  };
}

function getDataValues(connection, headers) {
  return headers.map(({ key }) => connection[key]);
}

export function hasContent(connection, headers) {
  return getDataValues(connection, headers).some((value) => Boolean(value));
}

export function areValid(connections, headers) {
  if (connections.length === 0) return false;

  return connections.every((connection) => (
    getDataValues(connection, headers).every((value) => Boolean(value))
  ));
}
