import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Icon,
  Search,
  useLocalStorage,
} from '@makeably/creativex-design-system';
import BulkUserSettings, {
  OFF_VALUE,
  ON_VALUE,
} from 'components/internal/BulkUserSettings';
import {
  emptyState,
  optionProps,
} from 'components/internal/shared';
import ActionButton from 'components/molecules/ActionButton';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { findObjectByValue } from 'utilities/array';
import {
  loadTextFile,
  saveItemsCsvFile,
} from 'utilities/file';
import { toggleProperty } from 'utilities/object';
import {
  addFormDataArray,
  patch,
} from 'utilities/requests';
import {
  bulkUpdateInternalUsersPath,
  editAdminUserPath,
} from 'utilities/routes';
import {
  splitLines,
  titleize,
  toSnakeCase,
} from 'utilities/string';
import {
  getParams,
  redirectWithParam,
} from 'utilities/url';
import styles from './BulkEditUsers.module.css';

const userProps = PropTypes.shape({
  email: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  partner: PropTypes.string.isRequired,
});

const propTypes = {
  brandOptions: PropTypes.arrayOf(optionProps).isRequired,
  channelOptions: PropTypes.arrayOf(optionProps).isRequired,
  companyAccessFeatures: PropTypes.objectOf(PropTypes.bool).isRequired,
  companyOptions: PropTypes.arrayOf(optionProps).isRequired,
  marketOptions: PropTypes.arrayOf(optionProps).isRequired,
  partnerOptions: PropTypes.arrayOf(optionProps).isRequired,
  users: PropTypes.arrayOf(userProps).isRequired,
  selectedCompanyId: PropTypes.number,
};

const defaultProps = {
  selectedCompanyId: undefined,
};

function getCommonProfile(selected, users) {
  if (users.length === 0) {
    return null;
  }

  const profiles = users.reduce((arr, { id, profile }) => {
    if (selected[id]) {
      return [...arr, profile];
    }
    return arr;
  }, []);

  const set = new Set(profiles);

  if (set.size !== 1) {
    return '';
  }

  return [...set.values()][0];
}

function parseCsv(csv, users) {
  const lines = splitLines(csv).slice(1);

  return lines.reduce(({ selected, missing }, text, line) => {
    const csvEmail = text.trim();
    const user = users.find(({ email }) => email === csvEmail);

    if (user) {
      return {
        missing,
        selected: {
          ...selected,
          [user.id]: true,
        },
      };
    }

    const missingUser = {
      email: csvEmail,
      line,
    };

    return {
      missing: [...missing, missingUser],
      selected,
    };
  }, {
    selected: {},
    missing: [],
  });
}

function searchUsers(search, users) {
  const term = search.toLowerCase();

  return users.filter(({ email, fullName }) => (
    email?.toLowerCase().includes(term) || fullName?.toLowerCase().includes(term)
  ));
}

function updateLists(key) {
  switch (key) {
    case 'profile':
      return {
        whitelistedAuditChannels: [],
        whitelistedBrands: [],
        whitelistedMarkets: [],
        whitelistedOrganizationAffiliates: [],
      };
    case 'restrictedAuditChannelData':
      return { whitelistedAuditChannels: [] };
    case 'restrictedBrandData':
      return { whitelistedBrands: [] };
    case 'restrictedOrgAffiliateData':
      return { whitelistedOrganizationAffiliates: [] };
    case 'restrictedMarketData':
      return { whitelistedMarkets: [] };
    case 'viewAdditionalCreatives':
      return {
        whitelistedBrands: [],
        whitelistedMarkets: [],
        whitelistedOrganizationAffiliates: [],
      };
    default:
      return {};
  }
}

function getErrorString(value, list) {
  if (value === OFF_VALUE) {
    return null;
  }

  const size = list?.length ?? 0;

  if (size > 0) {
    return null;
  }

  return 'You must select at least one option';
}

function detectError(key, settings) {
  switch (key) {
    case 'restrictedAuditChannelData':
      return getErrorString(settings[key], settings.whitelistedAuditChannels);
    case 'restrictedBrandData':
      return getErrorString(settings[key], settings.whitelistedBrands);
    case 'restrictedMarketData':
      return getErrorString(settings[key], settings.whitelistedMarkets);
    case 'restrictedOrgAffiliateData':
      return getErrorString(settings[key], settings.whitelistedOrganizationAffiliates);
    default:
      return null;
  }
}

function detectErrors(settings) {
  const errors = Object.keys(settings).reduce((obj, key) => {
    const error = detectError(key, settings);

    if (error) {
      return {
        ...obj,
        [key]: error,
      };
    }

    return obj;
  }, {});

  if (Object.keys(errors).length > 0) {
    return errors;
  }

  return null;
}

function getRoleChanges(settings) {
  return Object.entries(settings).reduce((obj, [key, value]) => {
    if (value === ON_VALUE) {
      return {
        ...obj,
        addRoles: [...obj.addRoles, toSnakeCase(key)],
      };
    } else if (value === OFF_VALUE) {
      return {
        ...obj,
        removeRoles: [...obj.removeRoles, toSnakeCase(key)],
      };
    }

    return obj;
  }, {
    addRoles: [],
    removeRoles: [],
  });
}

function BulkEditUsers({
  brandOptions,
  channelOptions,
  companyAccessFeatures,
  companyOptions,
  marketOptions,
  partnerOptions,
  selectedCompanyId,
  users,
}) {
  const params = getParams(window);
  const [lastCompanyId, setLastCompanyId] = useLocalStorage('cxIntCompanyId', null);
  const [selectedCompany, setSelectedCompany] = useState(
    findObjectByValue(companyOptions, selectedCompanyId),
  );
  const [search, setSearch] = useState('');
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [selectedUserIds, setSelectedUserIds] = useState({});
  const [commonProfile, setCommonProfile] = useState(null);
  const [settings, setSettings] = useState({});
  const [errors, setErrors] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const selectedCount = Object.keys(selectedUserIds).length;
  const canSave = selectedCount > 0 && Object.keys(settings).length > 0;

  const handleCompanyChange = (option) => {
    setSelectedCompany(option);
    setLastCompanyId(option?.value);
    redirectWithParam('company_id', option.value, params, window);
  };

  useEffect(() => {
    if (!selectedCompanyId && lastCompanyId) {
      const found = findObjectByValue(companyOptions, lastCompanyId);

      if (found) {
        handleCompanyChange(found);
      }
    }
  }, [selectedCompanyId, companyOptions, lastCompanyId]);

  useEffect(() => {
    setSearchedUsers(searchUsers(search, users));
  }, [search, users]);

  const updateSelectedUserIds = async (updated) => {
    setCommonProfile(getCommonProfile(updated, users));
    setSelectedUserIds(updated);
  };

  const handleCsvUpload = async () => {
    const csv = await loadTextFile('.csv');
    const {
      missing,
      selected,
    } = parseCsv(csv, users);

    const found = Object.keys(selected).length;

    if (found > 0) {
      addToast(`Matched ${found} email(s)`);
    }

    missing.map(({ email, line }) => (
      addErrorToast(`Could not match line ${line}: ${email}`)
    ));

    updateSelectedUserIds(selected);
  };

  const handleUserSelect = async (id) => {
    const updated = toggleProperty(selectedUserIds, id, true);

    updateSelectedUserIds(updated);
  };

  const handleSelectAll = () => {
    const selected = searchedUsers.reduce((obj, { id }) => ({
      ...obj,
      [id]: true,
    }), {});

    updateSelectedUserIds((last) => ({
      ...last,
      ...selected,
    }));
  };

  const handleSettingsChange = (key, value) => {
    const lists = updateLists(key);

    setSettings((last) => ({
      ...last,
      ...lists,
      [key]: value,
    }));
    setErrors(null);
  };

  const handleSave = async () => {
    const errs = detectErrors(settings);

    if (errs) {
      setErrors(errs);
    } else {
      setIsSaving(true);

      const url = bulkUpdateInternalUsersPath();
      const userIds = Object.keys(selectedUserIds);
      const { addRoles, removeRoles } = getRoleChanges(settings);

      const formData = new FormData();
      addFormDataArray(formData, 'ids', userIds);
      addFormDataArray(formData, 'add_roles', addRoles);
      addFormDataArray(formData, 'remove_roles', removeRoles);
      if (settings.profile) {
        formData.append('profile', settings.profile);
      }
      if (settings.organizationAffiliateId) {
        formData.append('organization_affiliate_id', settings.organizationAffiliateId);
      }
      if (settings.whitelistedAuditChannels) {
        if (settings.whitelistedAuditChannels.length > 0) {
          addFormDataArray(formData, 'whitelisted_audit_channels', settings.whitelistedAuditChannels);
        } else {
          formData.append('clear_channels', true);
        }
      }
      if (settings.whitelistedBrands) {
        if (settings.whitelistedBrands.length > 0) {
          addFormDataArray(formData, 'whitelisted_brands', settings.whitelistedBrands);
        } else {
          formData.append('clear_brands', true);
        }
      }
      if (settings.whitelistedMarkets) {
        if (settings.whitelistedMarkets.length > 0) {
          addFormDataArray(formData, 'whitelisted_markets', settings.whitelistedMarkets);
        } else {
          formData.append('clear_markets', true);
        }
      }
      if (settings.whitelistedOrganizationAffiliates) {
        if (settings.whitelistedOrganizationAffiliates.length > 0) {
          addFormDataArray(formData, 'whitelisted_organization_affiliates', settings.whitelistedOrganizationAffiliates);
        } else {
          formData.append('clear_organization_affiliates', true);
        }
      }

      const { isError } = await patch(url, formData);

      if (isError) {
        addErrorToast('Could not update users. Please try again later');
      } else {
        addToast(`${selectedCount} user(s) updated`);
        window.location.reload();
      }
      setIsSaving(false);
    }
  };

  const renderUser = ({
    id,
    email,
    fullName,
    partner,
    profile,
  }) => (
    <div key={id} className={styles.userItem}>
      <Checkbox
        checked={selectedUserIds[id]}
        onChange={() => handleUserSelect(id)}
      />
      <button
        className={`t-body-1 ${styles.user}`}
        type="button"
        onClick={() => handleUserSelect(id)}
      >
        <div className={styles.name}>
          <div className={styles.nameText}>
            { fullName }
          </div>
          <a
            className={styles.nameLink}
            href={editAdminUserPath(id)}
            rel="noreferrer"
            target="_blank"
          >
            <Icon name="externalLink" />
          </a>
        </div>
        <div className={styles.userText}>
          { email }
        </div>
        <div className={styles.userText}>
          { `${partner}, ${titleize(profile)}` }
        </div>
      </button>
    </div>
  );

  return (
    <Card className={styles.card}>
      <Dropdown
        label="Company"
        menuProps={{ size: 'medium' }}
        options={companyOptions}
        selected={selectedCompany}
        size="medium"
        onChange={handleCompanyChange}
      />
      <Divider />
      <div className={styles.content}>
        <div className={styles.controls}>
          <div className="u-flexRow u-gap-16">
            <Search
              placeholder="Name or Email"
              size="large"
              value={search}
              onChange={setSearch}
            />
            <Button
              label="Upload CSV"
              variant="secondary"
              onClick={handleCsvUpload}
            />
            <Button
              label="Download CSV Template"
              variant="tertiary"
              onClick={() => saveItemsCsvFile('userEmails', [], [{ label: 'Email' }])}
            />
          </div>
          <div className="u-flexRow u-alignCenter u-gap-16">
            { `${selectedCount} selected` }
            <Button
              label="Select All"
              variant="tertiary"
              onClick={handleSelectAll}
            />
            <Button
              disabled={selectedCount === 0}
              label="Select None"
              variant="tertiary"
              onClick={() => setSelectedUserIds({})}
            />
          </div>
        </div>
        <div className={styles.center}>
          <div className={`u-scrollShadowBottom ${styles.users}`}>
            { searchedUsers.length === 0 && emptyState }
            { searchedUsers.map((user) => renderUser(user)) }
          </div>
          <div className={`u-scrollShadowBottom ${styles.settings}`}>
            <BulkUserSettings
              brandOptions={brandOptions}
              channelOptions={channelOptions}
              commonProfile={commonProfile}
              errors={errors}
              isLifecycleEnabled={companyAccessFeatures.isLifecycleEnabled}
              isRepresentationEnabled={companyAccessFeatures.isRepresentationEnabled}
              marketOptions={marketOptions}
              partnerOptions={partnerOptions}
              settings={settings}
              userSettings={{}}
              onSettingsChange={handleSettingsChange}
            />
          </div>
        </div>
        <div className={styles.buttons}>
          <div className="u-flexRow u-gap-8">
            <Button
              label="Reset"
              variant="secondary"
              onClick={() => setSettings({})}
            />
            <ActionButton
              active={isSaving}
              disabled={!canSave}
              label="Save"
              onClick={handleSave}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

BulkEditUsers.propTypes = propTypes;
BulkEditUsers.defaultProps = defaultProps;

export default BulkEditUsers;
