import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { RadioGroup } from '@makeably/creativex-design-system';
import TierForm from 'components/rules/tiers/TierForm';
import {
  formatTiers,
  tierRadioOptions,
  tiersProps,
} from 'components/rules/tiers/Tiers';

const propTypes = {
  tierCount: PropTypes.number.isRequired,
  tiers: PropTypes.arrayOf(tiersProps).isRequired,
  onTierChange: PropTypes.func.isRequired,
  onTierCountChange: PropTypes.func.isRequired,
};

function CustomTiers({
  onTierChange,
  onTierCountChange,
  tierCount,
  tiers,
}) {
  const formattedTiers = useMemo(
    () => formatTiers(tiers, tiers.map(({ label }) => label)),
    [tiers],
  );

  return (
    <>
      <div className="u-flexRow u-marginBottom-16">
        <RadioGroup
          label="Number of Tiers"
          options={tierRadioOptions}
          value={tierCount.toString()}
          horizontal
          onChange={(e) => onTierCountChange(parseInt(e.target.value))}
        />
      </div>
      <TierForm
        tiers={formattedTiers}
        editing
        onTierChange={onTierChange}
      />
    </>
  );
}

CustomTiers.propTypes = propTypes;

export default CustomTiers;
