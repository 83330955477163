import React from 'react';
import PropTypes from 'prop-types';
import {
  Toggle,
  Tooltip,
} from '@makeably/creativex-design-system';
import { gridOptionProps } from 'components/admin/EvaluationGridSelector';
import Video from 'components/molecules/Video';
import VideoPlaybackRateControls, { defaultPlaybackRate } from 'components/molecules/VideoPlaybackRateControls';
import CustomVideo from 'components/organisms/VideoPlayer';
import Gridlines from 'components/reusable/GridLines';

const propTypes = {
  gridOptions: gridOptionProps.isRequired,
  isVideo: PropTypes.bool.isRequired,
  sourceUrl: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
};

class EvaluationPageAsset extends React.Component {
  constructor(props) {
    super(props);

    const { gridOptions } = props;

    this.state = {
      gridHeight: 1,
      gridOffsetLeft: 0,
      gridOffsetTop: 0,
      gridOptions,
      gridWidth: 1,
      playbackRate: defaultPlaybackRate,
      selectedCoordinates: [],
      useOldPlayer: false,
    };
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    const { width } = this.props;
    if (prevProps.width !== width) {
      this.setState({
        gridHeight: 1,
        gridOffsetLeft: 0,
        gridOffsetTop: 0,
        gridWidth: 1,
        selectedCoordinates: [],
      });
    }

    const {
      gridOptions: {
        gridFillColor,
        gridlineColor,
        showGridlines,
      },
    } = this.props;

    const {
      gridOptions: {
        gridFillColor: prevGridFillColor,
        gridlineColor: prevGridlineColor,
        showGridlines: prevShowGridlines,
      },
    } = prevProps;

    if (prevShowGridlines !== showGridlines) {
      this.setState((prevState) => {
        const { gridOptions: newOptions } = prevState;
        newOptions.showGridlines = showGridlines;
        return { gridOptions: newOptions };
      });
    }
    if (prevGridlineColor !== gridlineColor) {
      this.setState((prevState) => {
        const { gridOptions: newOptions } = prevState;
        newOptions.gridlineColor = gridlineColor;
        return { gridOptions: newOptions };
      });
    }
    if (prevGridFillColor !== gridFillColor) {
      this.setState((prevState) => {
        const { gridOptions: newOptions } = prevState;
        newOptions.gridFillColor = gridFillColor;
        return { gridOptions: newOptions };
      });
    }
  }
  /* eslint-enable react/no-did-update-set-state */

  handleSelectedCoordinates(x, y) {
    const {
      selectedCoordinates,
    } = this.state;

    const filteredCoordinates = selectedCoordinates.filter((p) => !(p.x === x && p.y === y));

    if (filteredCoordinates.length === selectedCoordinates.length) {
      this.setState({
        selectedCoordinates: [...selectedCoordinates, {
          x,
          y,
        }],
      });
    } else {
      this.setState({ selectedCoordinates: filteredCoordinates });
    }
  }

  setImageGrid(event) {
    const image = event.target;
    const {
      width,
      height,
    } = image;

    const imageRectangle = image.getBoundingClientRect();
    const imageParentRectangle = image.parentElement.getBoundingClientRect();

    // Given the image and image parent div, find the distance from the top left
    // corner of the image parent div to the top left corner of the image
    const gridOffsetLeft = imageRectangle.x - imageParentRectangle.x;
    const gridOffsetTop = imageRectangle.y - imageParentRectangle.y;

    this.setState({
      gridWidth: width,
      gridHeight: height,
      gridOffsetLeft,
      gridOffsetTop,
    });
  }

  setVideoGrid(htmlVideo) {
    const videoContainerRect = htmlVideo.getBoundingClientRect();

    // By default, the video width and height will equal the container size
    let videoWidth = videoContainerRect.width;
    let videoHeight = videoContainerRect.height;
    let gridOffsetTop = 0;
    let gridOffsetLeft = 0;

    const videoAspectRatio = (htmlVideo.videoWidth / htmlVideo.videoHeight);

    // If container width:height ratio is less than actual width:height ratio, we
    // know that the video is bound by it's width
    const boundByWidth = (videoWidth / videoHeight) <= videoAspectRatio;

    if (boundByWidth) {
      // When bound by width, find the real video height
      videoHeight = (htmlVideo.videoHeight / htmlVideo.videoWidth) * videoWidth;
      // The video will be centered in the container, so the offset will be half
      // of the difference from the video container height and
      gridOffsetTop = Math.ceil((videoContainerRect.height - videoHeight) / 2);
    } else {
      videoWidth = videoAspectRatio * videoHeight;
      gridOffsetLeft = Math.ceil((videoContainerRect.width - videoWidth) / 2);
    }

    const gridHeight = Math.ceil(videoHeight);
    const gridWidth = Math.ceil(videoWidth);
    this.setState({
      gridWidth,
      gridHeight,
      gridOffsetTop,
      gridOffsetLeft,
    });
  }

  renderAsset() {
    const {
      isVideo,
      sourceUrl,
      width,
    } = this.props;

    const {
      playbackRate,
      useOldPlayer,
    } = this.state;

    if (isVideo && useOldPlayer) {
      return (
        <Video
          playbackRate={playbackRate}
          url={sourceUrl}
          width={width}
          onReady={(reactPlayer) => this.setVideoGrid(reactPlayer.getInternalPlayer())}
        />
      );
    } else if (isVideo) {
      return (
        <CustomVideo
          height="420px"
          source={sourceUrl}
          width={`${width}px`}
          hasOptions
          onReady={(video) => this.setVideoGrid(video)}
        />
      );
    }

    return (
      <img
        alt="audit-post-asset"
        src={sourceUrl}
        width={width}
        onLoad={(event) => this.setImageGrid(event)}
      />
    );
  }

  renderGrid() {
    const {
      gridHeight,
      gridOffsetLeft,
      gridOffsetTop,
      gridOptions: {
        gridFillColor,
        gridlineColor,
        showGridlines,
      },
      gridWidth,
      selectedCoordinates,
    } = this.state;

    return (
      <Gridlines
        height={gridHeight}
        hideGrid={!showGridlines}
        lineColor={gridlineColor}
        offsetLeft={gridOffsetLeft}
        offsetTop={gridOffsetTop}
        rectangleColor={gridFillColor}
        selectedCoordinates={selectedCoordinates}
        width={gridWidth}
        onSelection={(x, y) => this.handleSelectedCoordinates(x, y)}
      >
        { this.renderAsset() }
      </Gridlines>
    );
  }

  render() {
    const { isVideo } = this.props;

    const {
      gridOptions: { showGridlines },
      playbackRate,
      selectedCoordinates,
      useOldPlayer,
    } = this.state;

    let selectedCoordinateCount;
    if (showGridlines && selectedCoordinates.length > 0) {
      selectedCoordinateCount = `${selectedCoordinates.length * 2.5}%`;
    }

    const toggleLabel = (
      <div className="u-flexRow u-gap-8 u-alignCenter">
        Use old video player
        <Tooltip label="The old video player will be removed as soon as we confirm that the new player is working as intended." />
      </div>
    );

    return (
      <div className="u-flexColumn u-alignCenter">
        <div className="auditPostAsset-assetWrapper">
          { this.renderGrid() }
          <span className="auditPostAsset-gridLabel u-label">
            { selectedCoordinateCount }
          </span>
        </div>
        { isVideo && useOldPlayer && (
          <VideoPlaybackRateControls
            playbackRate={playbackRate}
            onRateChange={(rate) => this.setState({ playbackRate: parseFloat(rate) })}
          />
        ) }
        <div className="u-marginTop-16">
          <Toggle
            checked={useOldPlayer}
            label={toggleLabel}
            right
            onChange={(e) => this.setState({ useOldPlayer: e.target.checked })}
          />
        </div>
      </div>
    );
  }
}

EvaluationPageAsset.propTypes = propTypes;

export default EvaluationPageAsset;
