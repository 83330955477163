import React, { useState } from 'react';
import {
  Button,
  MessageModal,
  Tooltip,
} from '@makeably/creativex-design-system';
import {
  recordProps,
  rolesProps,
} from 'components/internal/explorer/shared';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { destroy } from 'utilities/requests';
import {
  internalExplorerAuditPath,
  internalExplorerHomePath,
} from 'utilities/routes';

const propTypes = {
  record: recordProps.isRequired,
  roles: rolesProps,
};

const defaultProps = {
  roles: {},
};

function AuditActions({ record, roles }) {
  const [modalShown, setModalShown] = useState(false);
  const isDisabled = !roles.canDestroyPreflights;

  if (!record.pretest) return null;

  const handleDelete = async () => {
    setModalShown(false);

    const { data, isError } = await destroy(internalExplorerAuditPath(record.id));

    if (isError || !data.success) {
      addErrorToast('Could not delete preflight. Please try again later');
    } else {
      addToast('Preflight deleted');
      window.location.href = internalExplorerHomePath();
    }
  };

  return (
    <>
      <div className="u-flexRow u-alignCenter u-gap-8">
        <Button
          disabled={isDisabled}
          label="Delete Preflight"
          variant="destructive"
          onClick={() => setModalShown(true)}
        />
        { isDisabled && (
          <Tooltip
            label="You must have the 'destroy_preflights' role to delete Preflights"
          />
        ) }
      </div>
      <MessageModal
        actionButtonLabel="Delete"
        actionButtonVariant="destructive"
        isOpen={modalShown}
        title="Delete Preflight"
        onActionButtonClick={handleDelete}
        onClose={() => setModalShown(false)}
      >
        { `Are you sure you want to delete preflight #${record.id}?` }
      </MessageModal>
    </>
  );
}

AuditActions.propTypes = propTypes;
AuditActions.defaultProps = defaultProps;

export default AuditActions;
