// Displays the post and creative related info on the evaluation page
import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import AltText from 'components/admin/review_queue/post_info/AltText';
import Asset from 'components/admin/review_queue/post_info/Asset';
import AudioTranscription, {
  propTypes as audioTranscriptionProps,
  defaultProps as defaultAudioTranscriptionProps,
} from 'components/admin/review_queue/post_info/AudioTranscription';
import BrandWords, { propTypes as brandWordsProps } from 'components/admin/review_queue/post_info/BrandWords';
import CreativeDetails, { propTypes as creativeDetailsProps } from 'components/admin/review_queue/post_info/CreativeDetails';
import GridSelector, { defaultGridOptions } from 'components/admin/review_queue/post_info/GridSelector';
import OcrTags, { propTypes as ocrTagsProps } from 'components/admin/review_queue/post_info/OcrTags';
import PostCopy, {
  propTypes as postCopyProps,
  defaultProps as defaultPostCopyProps,
} from 'components/admin/review_queue/post_info/PostCopy';
import TextScreenshots from 'components/admin/review_queue/post_info/TextScreenshots';
import ThumbnailInfo, {
  propTypes as thumbnailInfoProps,
  defaultProps as defaultThumbnailProps,
} from 'components/admin/review_queue/post_info/ThumbnailInfo';
import VideoShots, { propTypes as videoShotsProps } from 'components/admin/review_queue/post_info/VideoShots';
import styles from './PostInfo.module.css';

const textScreenShotType = PropTypes.shape({
  instructions: PropTypes.string,
  screenshots: PropTypes.arrayOf(
    PropTypes.shape({
      screenshot: PropTypes.string,
      text: PropTypes.string,
    }),
  ),
});

export const propTypes = {
  ...audioTranscriptionProps,
  ...brandWordsProps,
  ...creativeDetailsProps,
  ...ocrTagsProps,
  ...postCopyProps,
  ...thumbnailInfoProps,
  ...videoShotsProps,
  checkId: PropTypes.number.isRequired,
  altText: PropTypes.string,
  creativeUrl: PropTypes.string,
  postUrl: PropTypes.string,
  textScreenshotsSection: PropTypes.shape({
    contrast: textScreenShotType,
    height: textScreenShotType,
  }),
  onAssetReady: PropTypes.func,
};

const defaultProps = {
  ...defaultAudioTranscriptionProps,
  ...defaultPostCopyProps,
  ...defaultThumbnailProps,
  altText: undefined,
  creativeUrl: undefined,
  onAssetReady: undefined,
  postUrl: undefined,
  textScreenshotsSection: ({
    contrast: ({
      instructions: undefined,
      screenshots: undefined,
    }),
    height: ({
      instructions: undefined,
      screenshots: undefined,
    }),
  }),
};

function PostInfo({
  altText,
  audioTranscription,
  brandWords,
  checkId,
  companyName,
  creativeDetails,
  creativeUrl,
  isBranded,
  isOmnibrand,
  isVideo,
  ocrTags,
  onAssetReady,
  postCopy,
  postUrl,
  textScreenshotsSection: {
    contrast,
    height,
  },
  thumbnail,
  videoShots,
}) {
  const [gridOptions, setGridOptions] = useState(defaultGridOptions);

  useEffect(() => {
    setGridOptions(defaultGridOptions);
  }, [checkId]);

  let postLink;
  if (postUrl) {
    postLink = (
      <a className="u-marginRight" href={postUrl} rel="noreferrer" target="_blank">
        <Icon color="grey" name="externalLink" />
      </a>
    );
  }

  let downloadLink;
  if (creativeUrl) {
    downloadLink = (
      <a href={creativeUrl} rel="noreferrer" target="_blank">
        <Icon color="grey" name="download" />
      </a>
    );
  }

  return (
    <div className={styles.content}>
      <PostCopy postCopy={postCopy} />
      <AltText text={altText} />
      <ThumbnailInfo thumbnail={thumbnail} />
      <Asset
        checkId={checkId}
        gridOptions={gridOptions}
        isVideo={isVideo}
        sourceUrl={creativeUrl}
        onAssetReady={onAssetReady}
      />
      <GridSelector
        callback={(newGridOptions) => setGridOptions(newGridOptions)}
        gridOptions={gridOptions}
      />
      <div className="u-marginBelow">
        { postLink }
        { downloadLink }
      </div>
      <BrandWords
        brandWords={brandWords}
        companyName={companyName}
        isBranded={isBranded}
        isOmnibrand={isOmnibrand}
      />
      <OcrTags ocrTags={ocrTags} />
      <TextScreenshots content={contrast} header="Text Contrast" />
      <TextScreenshots content={height} header="Text Height" />
      <AudioTranscription audioTranscription={audioTranscription} />
      <VideoShots shots={videoShots} />
      <CreativeDetails creativeDetails={creativeDetails} />
    </div>
  );
}

PostInfo.propTypes = propTypes;
PostInfo.defaultProps = defaultProps;

export default PostInfo;
