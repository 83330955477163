// This displays a row of controls (dropdowns or text inputs) for bulk selecting parameters
// Each row object represents a connection and it's set of parameters
// - Row values should be an option for a dropdown or a string for a text input (or null)
// Takes headers to define the columns and their associated key and dropdown options by key
// Takes callbacks for value changess, adding a row and removing a row
import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  CloseButton,
  Dropdown,
  Icon,
  TextInput,
} from '@makeably/creativex-design-system';
import styles from './BulkSelect.module.css';

const headerProps = PropTypes.shape({
  key: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
});

const rowProps = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]).isRequired,
      label: PropTypes.string,
      type: PropTypes.string,
    }),
    PropTypes.string,
  ]),
);

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
});

const propTypes = {
  headers: PropTypes.arrayOf(headerProps).isRequired,
  rows: PropTypes.arrayOf(rowProps).isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  optionsByKey: PropTypes.objectOf(PropTypes.arrayOf(optionProps)),
  onAdd: PropTypes.func,
  onDuplicate: PropTypes.func,
  onRemove: PropTypes.func,
};

const defaultProps = {
  disabled: false,
  onAdd: undefined,
  onDuplicate: undefined,
  onRemove: undefined,
  optionsByKey: {},
};

function BulkSelect({
  disabled,
  headers,
  onAdd,
  onChange,
  onDuplicate,
  onRemove,
  optionsByKey,
  rows,
}) {
  const renderLabel = (index, label) => {
    if (index !== 0) return null;

    return (
      <div className={`t-overline ${styles.label}`}>
        { label }
      </div>
    );
  };

  const renderControl = (index, header, row) => {
    const {
      key,
      label,
      type,
    } = header;
    const value = row[header.key];
    const options = optionsByKey[key] ?? [];
    const classes = classNames(
      styles.control,
      { [styles.missing]: !value },
    );

    if (type === 'text') {
      return (
        <div key={key}>
          { renderLabel(index, label) }
          <div className={classes}>
            <TextInput
              value={value}
              onChange={(val) => onChange(index, key, val)}
            />
          </div>
        </div>
      );
    }

    return (
      <div key={key}>
        { renderLabel(index, label) }
        <div className={classes}>
          <Dropdown
            key={key}
            menuProps={{ size: 'large' }}
            options={options}
            selected={value}
            onChange={(option) => onChange(index, key, option)}
          />
        </div>
      </div>
    );
  };

  const renderMessage = (type, message) => {
    if (!message) return null;

    const isWarning = type === 'warning';
    const name = isWarning ? 'exclamationTriangle' : 'exclamationCircle';
    const color = isWarning ? 'orange' : 'red';

    return (
      <div className={styles.message}>
        <Icon color={color} name={name} />
        { message }
      </div>
    );
  };

  const renderRow = (row, index) => (
    <Fragment key={row.uuid}>
      <div className={styles.row}>
        { headers.map((header) => renderControl(index, header, row)) }
        { onRemove && (
          <div className={styles.close}>
            <CloseButton onClick={() => onRemove(index)} />
          </div>
        ) }
      </div>
      <div>
        { renderMessage('warning', row.warning) }
        { renderMessage('error', row.error) }
      </div>
    </Fragment>
  );

  return (
    <div className={`u-scrollShadowRight ${styles.main}`}>
      <div className={styles.rows}>
        { rows.map(renderRow) }
      </div>
      <div className="u-flexRow u-gap-8">
        { onDuplicate && (
          <div className={styles.add}>
            <Button
              label="Duplicate Row"
              variant="tertiary"
              onClick={onDuplicate}
            />
          </div>
        ) }
        { onAdd && (
          <div className={styles.add}>
            <Button
              label="Add Row"
              variant="tertiary"
              onClick={onAdd}
            />
          </div>
        ) }
      </div>
      { disabled && <div className={styles.overlay} /> }
    </div>
  );
}

BulkSelect.propTypes = propTypes;
BulkSelect.defaultProps = defaultProps;

export default BulkSelect;
