import React from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  Pagination,
  Spinner,
} from '@makeably/creativex-design-system';
import styles from './SubmittedPreflightsTable.module.css';

const propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      classNames: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  loading: PropTypes.bool.isRequired,
  maxPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      cells: PropTypes.arrayOf(
        PropTypes.shape({
          classNames: PropTypes.string,
          value: PropTypes.node,
        }),
      ),
      key: PropTypes.string,
    }),
  ).isRequired,
  onPageChange: PropTypes.func.isRequired,
  total: PropTypes.number,
};

const defaultProps = {
  total: null,
};

function SubmittedPreflightsTable({
  headers,
  rows,
  page,
  loading,
  maxPerPage,
  total,
  onPageChange,
}) {
  return (
    <>
      { loading && <Spinner /> }
      { !loading && (
        <Table
          className={styles.table}
          headers={headers}
          rows={rows}
        />
      ) }
      { (total > maxPerPage && !loading) && (
        <div className={styles.pagination}>
          <Pagination
            currentPage={page}
            perPage={maxPerPage}
            total={total}
            onPageChange={onPageChange}
          />
        </div>
      ) }
    </>
  );
}

SubmittedPreflightsTable.propTypes = propTypes;
SubmittedPreflightsTable.defaultProps = defaultProps;

export default SubmittedPreflightsTable;
