import React from 'react';
import PropTypes from 'prop-types';
import Pill from 'components/reusable/Pill';

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      title: PropTypes.string,
    }),
  ).isRequired,
  selected: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  groupLabelId: PropTypes.string,
};
const defaultProps = {
  children: null,
  disabled: false,
  groupLabelId: null,
};

function Pills({
  children,
  disabled,
  groupLabelId,
  onChange,
  options,
  selected,
}) {
  const renderPills = () => {
    if (children) return children;
    return options.map((option) => (
      <Pill
        {...option}
        key={`pills-${option.value}`}
        disabled={disabled}
        isActive={option.value === selected}
        onChange={onChange}
      />
    ));
  };

  return (
    <div aria-labelledby={groupLabelId} className="pill-actions" role="group">
      { renderPills() }
    </div>
  );
}

Pills.propTypes = propTypes;
Pills.defaultProps = defaultProps;

export default Pills;
