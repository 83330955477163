import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  MessageModal,
  RadioGroup,
} from '@makeably/creativex-design-system';
import styles from './ActionSelectModal.module.css';

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  selectedUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
  onActionButtonClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

function ActionSelectModal({
  isOpen,
  onActionButtonClick,
  onClose,
  selectedUuids,
}) {
  const [action, setAction] = useState();

  const options = [
    {
      checked: action === 'deactivateAsset',
      label: 'Delete Core Assets',
      value: 'deactivateAsset',
    },
    {
      checked: action === 'updateAssetPartner',
      label: 'Change Production Partner',
      value: 'updateAssetPartner',
    },
    {
      checked: action === 'updateAssetMeasurementPartner',
      label: 'Change Measurement Partner',
      value: 'updateAssetMeasurementPartner',
    },
  ];

  return (
    <MessageModal
      actionButtonDisabled={!action}
      actionButtonLabel="Continue"
      isOpen={isOpen}
      subtitle="Choose from the following options to update the selected core assets."
      title={`Update Selected Core Assets (${selectedUuids.length})`}
      onActionButtonClick={() => onActionButtonClick(action)}
      onClose={onClose}
    >
      <div className={styles.radioGroup}>
        <RadioGroup
          name="editCampaignCoreAssetRadioGroup"
          options={options}
          value={action}
          onChange={(e) => setAction(e.target.value)}
        />
      </div>
    </MessageModal>
  );
}

ActionSelectModal.propTypes = propTypes;

export default ActionSelectModal;
