import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
  Icon,
  Spinner,
  Tooltip,
} from '@makeably/creativex-design-system';
import BenchmarksDisclaimer from 'components/benchmarks/BenchmarksDisclaimer';
import {
  getMetricTotals,
  reportProps,
} from 'components/benchmarks/utilities';
import {
  titleize,
  toConciseCount,
  toConciseSpend,
  toPercent,
} from 'utilities/string';
import styles from './BenchmarkMetrics.module.css';

const propTypes = {
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  reports: PropTypes.arrayOf(reportProps),
};

const defaultProps = {
  reports: [],
};

const qualityRateTooltip = 'The percentage of posts that meet digital suitability standards (posts that achieve a Creative Quality Score of 100%) across all CreativeX companies for the selected data range.';
const spendRateTooltip = 'The percentage of spend that meets digital suitability standards (the spend behind posts that achieve a Creative Quality Score of 100%) across all CreativeX companies for the selected date range.';

function renderMetric(label, text, tooltip) {
  return (
    <div className="u-flexColumn u-gap-8">
      <div className="u-flexRow u-gap-8 u-alignCenter">
        <div className="t-subtitle">
          { label }
        </div>
        { tooltip && <Tooltip label={tooltip} /> }
      </div>
      <h1>{ text }</h1>
    </div>
  );
}

function getTypeCount(reports, type) {
  const values = reports.reduce((vals, report) => (
    [...vals, report[type]]
  ), []);

  return new Set(values).size;
}

function BenchmarkMetrics({
  loading,
  reports,
  type,
}) {
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);

  const metrics = getMetricTotals(reports);
  const typeLabel = type === 'industry' ? 'Industries' : 'Categories';
  const typeCount = getTypeCount(reports, type);

  return (
    <>
      <Card>
        { loading && (
          <div className="u-flexRow u-justifyCenter">
            <Spinner />
          </div>
        ) }
        { !loading && (
          <div className="u-flexRow u-gap-24">
            <div className="u-flexColumn u-gap-8">
              <div className="u-flexRow u-gap-16 u-alignCenter">
                <div className="u-flexRow u-gap-8 u-alignCenter">
                  <div className={styles.cxIcon}>
                    <Icon color="current" name="cxIcon" />
                  </div>
                  <h5>{ `${titleize(type)} Benchmarks Sample Size` }</h5>
                </div>
                <Button
                  label="Learn More"
                  variant="tertiary"
                  onClick={() => setShowDisclaimerModal(true)}
                />
              </div>
              <div className="u-flexRow u-gap-24">
                { renderMetric('Total Spend', toConciseSpend(metrics.totalSpend)) }
                { renderMetric('Total In-Flight Posts', toConciseCount(metrics.totalCount)) }
                { renderMetric('Companies', metrics.clientCount) }
                { renderMetric(typeLabel, typeCount) }
              </div>
            </div>
            <div><Divider vertical /></div>
            <div className="u-flexColumn u-gap-16 u-justifyEnd">
              <h5>{ `Overall ${titleize(type)} Benchmarks` }</h5>
              <div className="u-flexRow u-gap-24">
                { renderMetric('Creative Quality Rate', toPercent(metrics.scoreRate) ?? 'N/A', qualityRateTooltip) }
                { renderMetric('Quality Spend Rate', toPercent(metrics.spendRate) ?? 'N/A', spendRateTooltip) }
              </div>
            </div>
          </div>
        ) }
      </Card>
      <BenchmarksDisclaimer
        isOpen={showDisclaimerModal}
        type={type}
        onClose={() => setShowDisclaimerModal(false)}
      />
    </>
  );
}

BenchmarkMetrics.propTypes = propTypes;
BenchmarkMetrics.defaultProps = defaultProps;

export default BenchmarkMetrics;
