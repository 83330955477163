// Utilities for usage displays for contracts
import React from 'react';
import {
  DataVizSkeleton,
  Tooltip,
} from '@makeably/creativex-design-system';
import ItemsBarChart from 'components/molecules/ItemsBarChart';
import styles from './utilities.module.css';

const padding = 16;
const perItemHeight = 28;

function getMonthLabel(date) {
  return new Intl.DateTimeFormat('en', {
    month: 'long',
    year: 'numeric',
  }).format(date);
}

export function getMonthsArray(startDate, contractEndDate) {
  let endDate;

  if (contractEndDate && new Date(contractEndDate) < new Date()) {
    endDate = new Date(contractEndDate);
  } else {
    endDate = new Date();
  }

  const currMonth = new Date(endDate.getFullYear(), endDate.getMonth());
  const startMonth = new Date(startDate.getFullYear(), startDate.getMonth());
  const months = [];

  while (currMonth >= startMonth) {
    months.push(getMonthLabel(currMonth));
    currMonth.setMonth(currMonth.getMonth() - 1);
  }

  return months;
}

export function getMonthBins(items, key) {
  return items.reduce((bins, item) => {
    const date = new Date(item.year, item.month - 1);
    const month = getMonthLabel(date);
    const total = bins[month] ?? 0;

    return {
      ...bins,
      [month]: total + item[key],
    };
  }, {});
}

export function formatDate(date) {
  if (!date) return 'N/A';

  const options = {
    timeZone: 'UTC',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  };

  const dateObject = new Date(date);
  const formattedDate = new Intl.DateTimeFormat('en', options).format(dateObject);

  return formattedDate;
}

export function renderStat(label, text, tooltip) {
  return (
    <div className="u-flexColumn u-gap-8">
      <div className="u-flexRow u-gap-8">
        <div className="t-caption-1">
          { label }
        </div>
        { tooltip && <Tooltip label={tooltip} /> }
      </div>
      <h4>{ text }</h4>
    </div>
  );
}

export function renderMonthlyBreakdown(items, key) {
  const height = padding + (items.length * perItemHeight);

  if (items.length === 0) {
    return (
      <div className={styles.chart}>
        <div className={styles.empty}>
          <DataVizSkeleton />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.chart}>
      <div className={styles.viz}>
        <div style={{ height: `${height}px` }}>
          <ItemsBarChart
            displayKey={key}
            items={items}
            labelKey="month"
            tooltipKeys={['month']}
          />
        </div>
      </div>
    </div>
  );
}
