import { titleize } from 'utilities/string';

export const EMPTY_VALUE = '~';
export const EMPTY_LABEL = 'N/A';

// get the label for a publisher tag
export function getPublisherLabel(value) {
  switch (value) {
    case 'youtube':
      return 'YouTube';
    case 'display':
      return titleize(value);
    default:
      return EMPTY_LABEL;
  }
}
