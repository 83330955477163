import PropTypes from 'prop-types';

export const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
});

export const optionArrayProps = PropTypes.arrayOf(optionProps);
