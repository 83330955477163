import React from 'react';
import {
  Button,
  CloseButton,
  Tag,
} from '@makeably/creativex-design-system';
import { addToast } from 'components/organisms/Toasts';
import GuidelineWeights from 'components/scores/modal/GuidelineWeights';
import ScoreInfoNotice from 'components/scores/modal/ScoreInfoNotice';
import GuidelineTable from 'components/scores/table/GuidelineTable';
import { track } from 'utilities/mixpanel';
import { getAuthenticityToken } from 'utilities/requests';
import {
  scorePath,
  updateWeightsScorePath,
} from 'utilities/routes';
import styles from '../Steps.module.css';

async function onSubmit({
  label,
  onNext,
  scoreId,
  setIsNextDisabled,
  selectedGuidelines,
}) {
  setIsNextDisabled(true);

  const formData = new FormData();
  formData.append('authenticity_token', getAuthenticityToken());

  const guidelineWeights = selectedGuidelines.map(({
    id,
    channelId,
    weight,
  }) => ({
    guideline_id: id,
    channel_id: channelId,
    weight,
  }));
  formData.append('guideline_weights', JSON.stringify(guidelineWeights));

  const response = await fetch(updateWeightsScorePath(scoreId), {
    method: 'POST',
    body: formData,
  });

  if (response.ok) {
    track('edit_score', {
      score_id: scoreId,
      score_label: label,
    });
    onNext();
  } else {
    addToast('Something went wrong, please try again.', {
      size: 'large',
      type: 'error',
    });
  }

  setIsNextDisabled(false);
}

function onConfirm({ scoreId }) {
  window.location.href = scorePath(scoreId);
}

function getContent(step, props) {
  const {
    guidelines,
    onGuidelineSelect,
    onGuidelineWeightChange,
    selectedGuidelines,
  } = props;

  switch (step) {
    case 1:
      return (
        <GuidelineTable
          guidelines={guidelines}
          isSelectable
          onGuidelineSelect={onGuidelineSelect}
        />
      );
    case 2:
      return (
        <GuidelineWeights
          guidelines={selectedGuidelines}
          isEditable
          onWeightChange={onGuidelineWeightChange}
        />
      );
    case 3:
      return (
        <GuidelineWeights
          guidelines={guidelines}
          isWeightUpdate
        />
      );
    case 4:
      return <ScoreInfoNotice />;
    default:
      return null;
  }
}

function getFooter(step, props) {
  const {
    initialSelectedGuidelines,
    initialStep,
    isNextDisabled,
    numSelectedGuidelines,
    onNext,
    onPrevious,
    selectedGuidelines,
  } = props;

  const initialGuidelineIds = initialSelectedGuidelines.map(({ id }) => id);
  const currentGuidelineIds = selectedGuidelines.map(({ id }) => id);

  const hasGuidelineRemoved = initialGuidelineIds.some((id) => !currentGuidelineIds.includes(id));
  const hasGuidelineAdded = currentGuidelineIds.some((id) => !initialGuidelineIds.includes(id));

  const hasGuidelineChanged = hasGuidelineRemoved || hasGuidelineAdded;
  const hasWeightChange = selectedGuidelines.some(({ initialWeight, weight }) => (
    initialWeight !== weight
  ));

  switch (step) {
    case 1:
      return (
        <Button
          disabled={numSelectedGuidelines === 0 || !hasGuidelineChanged}
          label="Continue"
          onClick={onNext}
        />
      );
    case 2:
      return (
        <div className="u-buttonGroup">
          { initialStep === 1 && (
            <Button
              label="Back"
              variant="tertiary"
              onClick={onPrevious}
            />
          ) }
          <Button
            disabled={!(hasWeightChange || hasGuidelineChanged)}
            label="Continue"
            onClick={onNext}
          />
        </div>
      );
    case 3:
      return (
        <div className="u-buttonGroup">
          <Button
            label="Back"
            variant="tertiary"
            onClick={onPrevious}
          />
          <Button
            disabled={isNextDisabled}
            label="Confirm"
            onClick={() => onSubmit(props)}
          />
        </div>
      );
    case 4:
      return (
        <Button
          label="Got it!"
          onClick={() => onConfirm(props)}
        />
      );
    default:
      return null;
  }
}

function getHeader(step, props) {
  const {
    numSelectedGuidelines,
    onClose,
  } = props;

  switch (step) {
    case 1:
      return (
        <div className={styles.header}>
          <div className="u-flexRow u-alignCenter u-gap-16">
            <h4>Add/Remove Guidelines</h4>
            <Tag label={`${numSelectedGuidelines} Guidelines Selected`} />
          </div>
          <CloseButton onClick={onClose} />
        </div>
      );
    case 2:
      return (
        <div className={styles.headerAndSubtitle}>
          <div className={styles.header}>
            <h4>Configure Guideline Weights</h4>
            <CloseButton onClick={onClose} />
          </div>
          <div className="t-subtitle">All guidelines are equally weighted as a default. Select reset to weigh all guidelines equally. Guideline weights can be edited in score settings after your new score has been published.</div>
        </div>
      );
    case 3:
      return (
        <div className={styles.headerAndSubtitle}>
          <div className={styles.header}>
            <h4>Summary of Guideline Weight Changes</h4>
            <CloseButton onClick={onClose} />
          </div>
          <div className="t-subtitle">Please confirm the following guideline weight changes</div>
        </div>
      );
    case 4:
      return (
        <div className={styles.header}>
          <h4>Score Update Successful!</h4>
          <CloseButton onClick={onClose} />
        </div>
      );
    default:
      return null;
  }
}

function getModalSize(step) {
  switch (step) {
    case 1:
    case 2:
    case 3:
      return 'full';
    case 4:
      return 'medium';
    default:
      return null;
  }
}

export function getStepContent(step, props) {
  return {
    content: getContent(step, props),
    footer: getFooter(step, props),
    header: getHeader(step, props),
    size: getModalSize(step),
  };
}
