import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  MessageModal,
} from '@makeably/creativex-design-system';
import { optionArrayProps } from 'components/shared';
import { post } from 'utilities/requests';
import {
  bulkUpdatePartnerCreativeLifecycleCoreAssetsPath,
  editCreativeLifecycleCampaignPath,
} from 'utilities/routes';
import styles from './AssetUpdatePartnerModal.module.css';

const propTypes = {
  campaignId: PropTypes.number.isRequired,
  contentKey: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  measurementPartners: optionArrayProps.isRequired,
  partners: optionArrayProps.isRequired,
  selectedUuids: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

function AssetUpdatePartnerModal({
  campaignId,
  contentKey,
  isOpen,
  onClose,
  measurementPartners,
  partners: productionPartners,
  selectedUuids,
}) {
  const [errorText, setErrorText] = useState();
  const [newPartner, setNewPartner] = useState();

  const isProductionPartnerUpdate = contentKey === 'updateAssetPartner';
  const subtitle = `Choose a new ${isProductionPartnerUpdate ? 'production' : 'measurement'} partner from the dropdown below.`;

  const partners = isProductionPartnerUpdate ? productionPartners : measurementPartners;

  const partnerObject = {
    [isProductionPartnerUpdate ? 'partner_id' : 'measurement_partner_id']: newPartner?.value,
  };

  const onActionButtonClick = async () => {
    const response = await post(bulkUpdatePartnerCreativeLifecycleCoreAssetsPath(), {
      ...partnerObject,
      core_asset_uuids: selectedUuids,
    });

    if (!response.isError) {
      window.location.href = editCreativeLifecycleCampaignPath(campaignId);
    } else {
      setErrorText('Something went wrong, please try again');
    }
  };

  return (
    <MessageModal
      actionButtonDisabled={!newPartner}
      actionButtonLabel="Update"
      isOpen={isOpen}
      subtitle={subtitle}
      title={`Update Selected Core Assets (${selectedUuids.length})`}
      onActionButtonClick={onActionButtonClick}
      onClose={onClose}
    >
      <>
        <div className={styles.dropdown}>
          <Dropdown
            disabled={partners.length === 0}
            label={`${isProductionPartnerUpdate ? 'Production' : 'Measurement'} Partner`}
            menuProps={{ size: 'medium' }}
            options={partners}
            placeholder={partners.length > 0 ? 'Select' : 'None'}
            selected={newPartner}
            size="medium"
            onChange={(p) => setNewPartner(p)}
          />
        </div>
        <div className={styles.errorText}>{ errorText }</div>
      </>
    </MessageModal>
  );
}

AssetUpdatePartnerModal.propTypes = propTypes;

export default AssetUpdatePartnerModal;
