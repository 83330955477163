import React, {
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Divider,
  MetricCard,
} from '@makeably/creativex-design-system';
import { metricTooltips } from 'components/creative_lifecycle/shared';
import VerticalBarChart from 'components/molecules/VerticalBarChart';
import { useViewPage } from 'utilities/mixpanel';
import { getNiceNumber } from 'utilities/number';
import {
  toConciseCount,
  toConciseSpend,
} from 'utilities/string';
import styles from './ActivatedMedia.module.css';

export const spendAndImpressionsProps = PropTypes.shape({
  assetType: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  campaign: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  impressions: PropTypes.number.isRequired,
  market: PropTypes.string.isRequired,
  partner: PropTypes.string.isRequired,
  postId: PropTypes.number.isRequired,
  spend: PropTypes.number.isRequired,
  uuid: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
});

export const totalsProps = PropTypes.shape({
  assetIds: PropTypes.number.isRequired,
  assetType: PropTypes.string.isRequired,
  brand: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired,
  impressions: PropTypes.number.isRequired,
  market: PropTypes.string.isRequired,
  spend: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
});

const propTypes = {
  filteredData: PropTypes.arrayOf(spendAndImpressionsProps).isRequired,
  filteredTotals: PropTypes.arrayOf(totalsProps).isRequired,
};

const chartSegments = [
  {
    value: 'totalMediaSpend',
    label: 'Distribution of Total Media Spend per Activated Core Asset',
  },
  {
    value: 'totalImpressions',
    label: 'Distribution of Total Impressions per Activated Core Asset',
  },
];

const metricObjects = [
  {
    label: 'Activated Media Spend',
    tooltip: metricTooltips.spend,
    value: 'totalSpend',
  },
  {
    label: 'Activated Impressions',
    tooltip: metricTooltips.impressions,
    value: 'totalImpressions',
  },
];

function getMetricValues(data) {
  const { totalSpend, totalImpressions } = data.reduce(
    (totals, {
      year, spend, impressions, postId,
    }) => {
      const postsByYear = totals.postsByYear.get(year) || new Set();
      const oldPost = postsByYear.has(postId);
      const updatedPostsByYear = postsByYear.add(postId);

      return {
        postsByYear: totals.postsByYear.set(year, updatedPostsByYear),
        totalSpend:
          oldPost ? totals.totalSpend : totals.totalSpend + spend,
        totalImpressions:
          oldPost ? totals.totalImpressions : totals.totalImpressions + impressions,
      };
    },
    {
      postsByYear: new Map(),
      totalSpend: 0,
      totalImpressions: 0,
    },
  );

  return {
    totalSpend: toConciseSpend(totalSpend),
    totalImpressions: toConciseCount(totalImpressions),
  };
}

function getMetricTotals(data) {
  const {
    totalSpend, totalImpressions, assets,
  } = data.reduce(
    (totals, {
      spend,
      impressions,
      assetIds,
    }) => ({
      totalSpend: totals.totalSpend + spend,
      totalImpressions: totals.totalImpressions + impressions,
      assets: new Set([...totals.assets, ...assetIds]),
    }),
    {
      totalSpend: 0,
      totalImpressions: 0,
      assets: new Set(),
    },
  );

  return {
    totalSpend: toConciseSpend(totalSpend),
    totalImpressions: toConciseCount(totalImpressions),
    totalAssets: toConciseCount(assets.size),
  };
}

function getMetricsPerCoreAsset(data) {
  const { totals } = data.reduce(
    (metrics, {
      spend, impressions, uuid, year, postId,
    }) => {
      const key = `${uuid}-${year}`;
      const postsByYear = metrics.postsByYear.get(key) || new Set();
      const oldPost = postsByYear.has(postId);
      const updatedPostsByYear = postsByYear.add(postId);

      const current = metrics.totals.get(uuid) || {
        totalMediaSpend: 0,
        totalImpressions: 0,
      };
      const updatedTotals = metrics.totals;
      updatedTotals.set(uuid, {
        totalMediaSpend:
          oldPost ? current.totalMediaSpend : current.totalMediaSpend + spend,
        totalImpressions:
          oldPost ? current.totalImpressions : current.totalImpressions + impressions,
      });
      return {
        totals: updatedTotals,
        postsByYear: metrics.postsByYear.set(key, updatedPostsByYear),
      };
    },
    {
      totals: new Map(),
      postsByYear: new Map(),
    },
  );

  return Array.from(totals, ([uuid, { totalMediaSpend, totalImpressions }]) => ({
    uuid,
    totalMediaSpend,
    totalImpressions,
  }));
}

function bucketize(start, end) {
  if (end === start) {
    return [{
      start,
      end,
    }];
  }

  const range = end - start + 1;
  const adjustedRange = range + 1;
  const rawBucketSize = adjustedRange / 10;
  const bucketSize = getNiceNumber(rawBucketSize);

  const buckets = Array.from({ length: 10 }, (_, i) => ({
    start: i * bucketSize,
    end: i === 9 ? (end + 1) : ((i + 1) * bucketSize - 1),
  }));

  return buckets;
}

function getAssetsPerBucket(buckets, data, metric) {
  return buckets.reduce((items, { start, end }, index) => {
    const filteredData = data.filter(
      (item) => item[metric] >= start && item[metric] < end,
    );

    let dataLabel;
    if (index === 0) {
      if (start === end) {
        dataLabel = `${toConciseCount(start)}`;
      } else {
        dataLabel = `<${toConciseCount(end + 1)}`;
      }
    } else if (index === buckets.length - 1) {
      dataLabel = `>${toConciseCount(start)}`;
    } else {
      dataLabel = `${toConciseCount(start)} - ${toConciseCount(end + 1)}`;
    }

    const item = {
      id: { value: dataLabel },
      index: { value: index },
      bucket: { value: dataLabel },
      assetCount: { value: start === end ? data.length : filteredData.length },
    };

    return [...items, item];
  }, []);
}

function getChartItems(data) {
  const metricsPerCoreAsset = getMetricsPerCoreAsset(data);
  const {
    maxTotalMediaSpend,
    maxTotalImpressions,
    minTotalMediaSpend,
    minTotalImpressions,
  } = metricsPerCoreAsset.reduce(
    (ranges, { totalMediaSpend, totalImpressions }) => ({
      maxTotalMediaSpend: Math.max(ranges.maxTotalMediaSpend, totalMediaSpend),
      maxTotalImpressions: Math.max(ranges.maxTotalImpressions, totalImpressions),
      minTotalMediaSpend: Math.min(ranges.minTotalMediaSpend, totalMediaSpend),
      minTotalImpressions: Math.min(ranges.minTotalImpressions, totalImpressions),
    }),
    {
      maxTotalMediaSpend: 0,
      maxTotalImpressions: 0,
      minTotalMediaSpend: Infinity,
      minTotalImpressions: Infinity,
    },
  );

  const spendBuckets = bucketize(minTotalMediaSpend, maxTotalMediaSpend);
  const impressionBuckets = bucketize(minTotalImpressions, maxTotalImpressions);

  const coreAssetsPerSpendBucket = getAssetsPerBucket(
    spendBuckets,
    metricsPerCoreAsset,
    'totalMediaSpend',
  );

  const coreAssetsPerImpressionBucket = getAssetsPerBucket(
    impressionBuckets,
    metricsPerCoreAsset,
    'totalImpressions',
  );

  return {
    totalMediaSpend: coreAssetsPerSpendBucket,
    totalImpressions: coreAssetsPerImpressionBucket,
  };
}

function ActivatedMedia({
  filteredData,
  filteredTotals,
}) {
  const [metricValues, setMetricValues] = useState({});
  const [metricTotals, setMetricTotals] = useState({});
  const [chartItems, setChartItems] = useState({});

  useViewPage('activated_media');

  useEffect(() => {
    setMetricValues(getMetricValues(filteredData));
    setChartItems(filteredData.length > 0 ? getChartItems(filteredData) : {});
  }, [filteredData]);

  useEffect(() => {
    setMetricTotals(getMetricTotals(filteredTotals));
  }, [filteredTotals]);

  const renderMetricCard = (metric) => {
    const {
      totalSpend,
      totalImpressions,
      totalAssets,
    } = metricTotals;
    const metricWithValue = {
      ...metric,
      value: filteredData.length > 0 ? metricValues[metric.value] : 'No data',
    };

    if (filteredData.length > 0) {
      if (metric.value === 'totalSpend') {
        metricWithValue.caption = `Of ${totalSpend} in Total Media Spend across ${totalAssets} In-Flight Assets`;
      } else {
        metricWithValue.caption = `Of ${totalImpressions} in Total Impressions across ${totalAssets} In-Flight Assets`;
      }
    }

    return (
      <div key={metric.value} className="u-col-6">
        <MetricCard alert={false} metric={metricWithValue} />
      </div>
    );
  };

  return (
    <div className="u-flexColumn u-gap-24">
      <div className="u-flexColumn u-gap-16">
        <div className="u-flexRow u-gap-16 u-justifyBetween">
          { metricObjects.map((metric) => renderMetricCard(metric)) }
        </div>
      </div>
      { chartSegments.map((segment) => (
        <Card key={segment} padding>
          <h5 className={styles.chartLabel}>{ segment.label }</h5>
          <Divider />
          <div>
            <VerticalBarChart
              displayKey="assetCount"
              items={chartItems[segment.value] || []}
              labelKey="bucket"
              xLabel={segment.value === 'totalImpressions' ? 'Impressions Breakdown' : 'Spend Breakdown ($)'}
              yLabel="Number of Core Assets Activated"
            />
          </div>
        </Card>
      )) }
    </div>
  );
}

ActivatedMedia.propTypes = propTypes;

export default ActivatedMedia;
