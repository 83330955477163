import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
} from '@makeably/creativex-design-system';
import PipelineChart from 'components/internal/PipelineChart';
import PipelineStep, { stepProps } from 'components/internal/PipelineStep';
import PipelineSteps from 'components/internal/PipelineSteps';
import PipelineTimes from 'components/internal/PipelineTimes';
import { emptyState } from 'components/internal/shared';
import { stringToDate } from 'utilities/date';
import { groupBy } from 'utilities/object';
import { internalPipelineTrackersPath } from 'utilities/routes';
import { getByObjectKey } from 'utilities/sort';
import {
  dateToString,
  toDate,
} from 'utilities/string';
import {
  getParams,
  redirectWithParam,
  setParam,
} from 'utilities/url';
import styles from './PipelineTrackers.module.css';

const propTypes = {
  endDate: PropTypes.string.isRequired,
  today: PropTypes.string.isRequired,
  totals: PropTypes.arrayOf(stepProps).isRequired,
  initialDate: PropTypes.string,
  initialStep: PropTypes.string,
};

const defaultProps = {
  initialDate: undefined,
  initialStep: undefined,
};

const validStepKeys = new Set([
  '0-',
  '0-a',
  '0-ab',
  '0-b',
  '0-bc',
  '1-abc',
  '2-abcd',
  '2-abcde',
  '2-abcdf',
  '3-abcdef',
  '4-abcdefg',
]);

function getStepKey({
  stage,
  status,
  substage,
}) {
  const key = `${stage}-${substage}`;

  if (status === 'failed') return `${key}-failed`;
  if (status === 'unsupported') return `${key}-unsupported`;

  return validStepKeys.has(key) ? key : `${key}-bad`;
}

function getSteps(records) {
  return records.reduce((obj, record) => {
    const key = getStepKey(record);

    return {
      ...obj,
      [key]: {
        ...record,
        key,
      },
    };
  }, {});
}

function getStepGroup(dateSteps, selectedDate, selectedStep) {
  const dateStep = dateSteps.find(({ date }) => date === selectedDate) ?? {};
  const steps = Object.values(dateStep.steps ?? {});
  const step = steps.find(({ key }) => key === selectedStep);
  const date = toDate(stringToDate(dateStep.date ?? ''));

  if (!step) return null;

  return {
    label: `${date} ${step.stage}-${step.substage}`,
    allSteps: [step],
    steps: { [step.key]: step },
  };
}

function getDateGroup(dateSteps, selectedDate) {
  const dateStep = dateSteps.find(({ date }) => date === selectedDate);

  if (!dateStep) return null;

  return {
    ...dateStep,
    allSteps: Object.values(dateStep.steps),
    label: toDate(stringToDate(dateStep.date), true),
  };
}

function getRangeLabel(dateSteps) {
  const start = stringToDate(dateSteps.at(0)?.date);
  const end = stringToDate(dateSteps.at(-1)?.date);

  return `${toDate(start, true)} - ${toDate(end, true)}`;
}

function groupSteps(last, steps) {
  return Object.values(steps).reduce((obj, {
    count,
    key,
    stage,
    status,
    substage,
  }) => {
    const lastCount = last[key]?.count ?? 0;

    return {
      ...obj,
      [key]: {
        count: lastCount + count,
        key,
        stage,
        status,
        substage,
      },
    };
  }, last);
}

function getDatesGroup(dateSteps) {
  if (dateSteps.length === 0) return null;

  const allSteps = dateSteps.flatMap(({ steps }) => Object.values(steps));

  return {
    allSteps,
    isMultiday: true,
    label: getRangeLabel(dateSteps),
    steps: dateSteps.reduce((obj, { steps }) => groupSteps(obj, steps), {}),
  };
}

function PipelineTrackers({
  endDate,
  initialDate,
  initialStep,
  today,
  totals,
}) {
  const params = getParams(window);
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const [selectedStep, setSelectedStep] = useState(initialStep);

  const dateSteps = useMemo(() => {
    const byDate = getByObjectKey('date');
    const grouped = groupBy(totals, 'date');
    const dates = Object.entries(grouped).map(([date, records]) => ({
      date,
      steps: getSteps(records),
    }));
    return dates.sort(byDate);
  }, [totals]);

  const stepsGroup = useMemo(() => {
    if (!selectedDate) return getDatesGroup(dateSteps);
    if (selectedStep) return getStepGroup(dateSteps, selectedDate, selectedStep);

    return getDateGroup(dateSteps, selectedDate);
  }, [dateSteps, selectedDate, selectedStep]);

  const handleStepSelect = (step) => {
    setSelectedStep(step);
    setParam('step', step ?? '', params, window);
  };

  const handleDateSelect = (date) => {
    setSelectedDate(date);

    handleStepSelect(null);
    setParam('date', date ?? '', params, window);
  };

  const handleDateStepSelect = (date, step) => {
    if (selectedDate && selectedDate === date) {
      handleStepSelect(step);
    } else {
      handleDateSelect(date);
    }
  };

  const handleDateChange = (inc) => {
    const data = stringToDate(endDate);
    data.setDate(data.getDate() + inc);
    const newEndDate = dateToString(data);

    handleStepSelect(null);
    handleDateSelect(null);
    redirectWithParam('end_date', newEndDate, params, window);
  };

  const renderBackButton = () => {
    if (!selectedStep && !selectedDate) return null;

    const label = selectedStep ? 'Back to full day' : 'Back to all days';
    const handler = selectedStep ? handleStepSelect : handleDateSelect;

    return (
      <Button
        iconLeft="arrowLeft"
        label={label}
        variant="tertiary"
        onClick={() => handler(null)}
      />
    );
  };

  const renderSteps = () => {
    if (stepsGroup?.allSteps?.length === 1) {
      return (
        <>
          <h5>Step Details</h5>
          <PipelineStep step={stepsGroup.allSteps[0]} />
        </>
      );
    }

    return (
      <>
        <h5>Steps</h5>
        <PipelineSteps
          stepsGroup={stepsGroup}
          onStepSelect={handleStepSelect}
        />
      </>
    );
  };

  if (!stepsGroup) {
    return (
      <Card>
        <a href={internalPipelineTrackersPath()}>Reset to Today</a>
        { emptyState }
      </Card>
    );
  }

  return (
    <Card className="u-flexColumn u-gap-24">
      <div className="u-flexRow u-scrollShadowRight">
        <div className="u-flexRow u-alignCenter u-gap-16">
          <Button
            iconLeft="chevronLeft"
            variant="round"
            onClick={() => handleDateChange(-1)}
          />
          <div className={styles.chart}>
            <PipelineChart
              dateSteps={dateSteps}
              selectedDate={selectedDate}
              selectedStep={selectedStep}
              onDateStepSelect={handleDateStepSelect}
            />
          </div>
          { endDate !== today && (
            <Button
              iconLeft="chevronRight"
              variant="round"
              onClick={() => handleDateChange(1)}
            />
          ) }
        </div>
      </div>
      <div>
        <div className={styles.title}>
          <h4 className="">{ stepsGroup?.label }</h4>
          { renderBackButton() }
        </div>
        <div className="u-flexRow u-gap-24 u-scrollShadowRight">
          <div>
            { renderSteps() }
          </div>
          <div>
            <h5>Times</h5>
            <PipelineTimes stepsGroup={stepsGroup} />
          </div>
        </div>
      </div>
    </Card>
  );
}

PipelineTrackers.propTypes = propTypes;
PipelineTrackers.defaultProps = defaultProps;

export default PipelineTrackers;
