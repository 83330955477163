import React, {
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Card,
  RadioGroup,
} from '@makeably/creativex-design-system';
import BoundingBox, {
  positionProps as boundingBoxProps,
} from 'components/admin/annotation/BoundingBox';
import Crosshair from 'components/admin/annotation/Crosshair';
import styles from 'components/admin/annotation/AnnotationCanvas.module.css';
import { horizontalLine as crosshairClass } from 'components/admin/annotation/Crosshair.module.css';

export const propTypes = {
  assetUrl: PropTypes.string.isRequired,
  boundingBoxes: PropTypes.arrayOf(PropTypes.shape(boundingBoxProps)).isRequired,
  createBox: PropTypes.func.isRequired,
  currentBoxIdx: PropTypes.number.isRequired,
  removeDrawingBox: PropTypes.func.isRequired,
  updateDrawingBox: PropTypes.func.isRequired,
};

const DIV_BORDER = 1;
const MAX_BOX_COUNT = 5;
const MIN_BOX_DIMENSION = 10;

const crosshairRadioOptions = [
  {
    label: 'White',
    value: 'white',
  },
  {
    label: 'Black',
    value: 'black',
  },
];

const boxRadioOptions = [
  {
    label: 'Blue',
    value: 'blue',
  },
  {
    label: 'Red',
    value: 'red',
  },
];

function isValidBoxSize(position) {
  return position.width >= MIN_BOX_DIMENSION && position.height >= MIN_BOX_DIMENSION;
}

const elemRoot = (elem) => {
  if (elem.parentElement) return elemRoot(elem.parentElement);
  return elem;
};

const offset = (elem) => {
  const rootElem = elemRoot(elem);
  const {
    left: docLeft,
    top: docTop,
  } = rootElem.getBoundingClientRect();
  const {
    left: elemLeft,
    top: elemTop,
    width: w,
    height: h,
  } = elem.getBoundingClientRect();

  return {
    h,
    w,
    x: Math.abs(docLeft) + elemLeft,
    y: Math.abs(docTop) + elemTop,
  };
};

function AnnotationCanvas({
  assetUrl,
  boundingBoxes,
  createBox,
  currentBoxIdx,
  removeDrawingBox,
  updateDrawingBox,
}) {
  const [isDrawing, setIsDrawing] = useState(false);
  const [boxColor, setBoxColor] = useState('blue');
  const [fillColor, setFillColor] = useState('white');
  const [currXY, setCurrXY] = useState({});
  const [startXY, setStartXY] = useState({});
  const [imageProps, setImageProps] = useState({});
  const [scrollValues, setScrollValues] = useState({
    top: 0,
    left: 0,
  });

  const rectPosition = () => {
    let left = Math.min(startXY.x, currXY.x);
    let top = Math.min(startXY.y, currXY.y);
    let right = Math.max(startXY.x, currXY.x);
    let bottom = Math.max(startXY.y, currXY.y);

    const width = imageProps.width - DIV_BORDER;
    const height = imageProps.height - DIV_BORDER;

    // limit rectangles to the size of the image
    left = Math.max(imageProps.offsetX, left);
    top = Math.max(imageProps.offsetY, top);
    right = Math.min(width + imageProps.offsetX, right);
    bottom = Math.min(height + imageProps.offsetY, bottom);

    return {
      left: left - imageProps.offsetX,
      top: top - imageProps.offsetY,
      width: right - left,
      height: bottom - top,
    };
  };

  const handleMouseDown = (event) => {
    if (currentBoxIdx === MAX_BOX_COUNT) return;

    // start drawing if the mouse was pressed down inside the image
    // top z-axis component in the image is the crosshair (styled with crosshair class)
    const targetClasses = event.target.className.split(' ');
    if (targetClasses.includes(crosshairClass)) {
      event.persist();
      setCurrXY({
        x: event.pageX + scrollValues.left,
        y: event.pageY + scrollValues.top,
      });
      setStartXY({
        x: event.pageX + scrollValues.left,
        y: event.pageY + scrollValues.top,
      });
      setIsDrawing(true);
    }
  };

  const handleMouseMove = (event) => {
    event.persist();
    setCurrXY({
      x: Math.max(event.pageX + scrollValues.left, imageProps.offsetX),
      y: Math.max(event.pageY + scrollValues.top, imageProps.offsetY),
    });

    if (isDrawing) updateDrawingBox(rectPosition());
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollLeft } = e.target;
    setScrollValues({
      top: scrollTop,
      left: scrollLeft,
    });
  };

  const handleMouseUp = () => {
    if (!isDrawing) return;

    const boxPosition = rectPosition();
    if (isValidBoxSize(boxPosition)) {
      createBox(boxPosition);
    } else {
      removeDrawingBox();
    }

    setIsDrawing(false);
    setStartXY({
      x: null,
      y: null,
    });
  };

  const setDimensions = (img) => {
    const {
      x: offsetX,
      y: offsetY,
    } = offset(img);

    const newImageProps = {
      height: img.offsetHeight,
      width: img.offsetWidth,
      offsetX,
      offsetY,
    };

    setImageProps(newImageProps);
  };

  const crosshairReady = currXY.x && currXY.y && imageProps.height && imageProps.width;
  const crosshairProps = {
    imageProps,
    fillColor,
    x: currXY.x,
    y: currXY.y,
  };

  useEffect(() => {
    const content = document.getElementById('page-content');
    content.addEventListener('scroll', handleScroll);
    return () => content.removeEventListener('scroll', handleScroll);
  });

  return (
    <div className="u-col-8 u-marginRight">
      <button
        className="u-marginBottom-16"
        type="button"
        onMouseDown={(event) => handleMouseDown(event)}
        onMouseMove={(event) => handleMouseMove(event)}
        onMouseUp={(event) => handleMouseUp(event)}
      >
        { crosshairReady && <Crosshair {...crosshairProps} /> }
        <div className={classNames(styles.boundingBoxes)}>
          { boundingBoxes.map((box, index) => (
            <BoundingBox
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              color={boxColor}
              completed={index < currentBoxIdx}
              fillColor={fillColor}
              id={index + 1}
              isDrawing={isDrawing}
              position={box}
            />
          )) }
        </div>
        <img
          alt="annotation asset"
          className={classNames(styles.image)}
          src={assetUrl}
          onLoad={(event) => setDimensions(event.target)}
        />
      </button>
      <Card className={styles.userInteractions}>
        <div>
          <div className="t-label-1 u-marginBottom-16">Bounding Box Color:</div>
          <RadioGroup
            name="boxColor"
            options={boxRadioOptions}
            value={boxColor}
            onChange={(e) => setBoxColor(e.target.value)}
          />
        </div>
        <div className="t-label-1 u-marginRight">
          <div className="u-marginBottom-16">Crosshair Color:</div>
          <RadioGroup
            name="crosshairColor"
            options={crosshairRadioOptions}
            value={fillColor}
            onChange={(e) => setFillColor(e.target.value)}
          />
        </div>
      </Card>
    </div>
  );
}

AnnotationCanvas.propTypes = propTypes;

export default AnnotationCanvas;
