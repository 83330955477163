import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@makeably/creativex-design-system';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import { get } from 'utilities/requests';
import { trackerIdsInternalPipelineTrackersPath } from 'utilities/routes';
import styles from './PipelineStep.module.css';

export const stepProps = PropTypes.shape({
  count: PropTypes.number.isRequired,
  stage: PropTypes.number.isRequired,
  substage: PropTypes.string.isRequired,
  avgTime0a: PropTypes.number,
  avgTime0b: PropTypes.number,
  avgTime0c: PropTypes.number,
  avgTime1: PropTypes.number,
  avgTime2: PropTypes.number,
  avgTime2e: PropTypes.number,
  avgTime2f: PropTypes.number,
  avgTime3: PropTypes.number,
  avgTime4: PropTypes.number,
  avgTotal: PropTypes.number,
  key: PropTypes.string,
  maxTime0a: PropTypes.number,
  maxTime0b: PropTypes.number,
  maxTime0c: PropTypes.number,
  maxTime1: PropTypes.number,
  maxTime2: PropTypes.number,
  maxTime2e: PropTypes.number,
  maxTime2f: PropTypes.number,
  maxTime3: PropTypes.number,
  maxTime4: PropTypes.number,
  maxTotal: PropTypes.number,
  minTime0a: PropTypes.number,
  minTime0b: PropTypes.number,
  minTime0c: PropTypes.number,
  minTime1: PropTypes.number,
  minTime2: PropTypes.number,
  minTime2e: PropTypes.number,
  minTime2f: PropTypes.number,
  minTime3: PropTypes.number,
  minTime4: PropTypes.number,
  minTotal: PropTypes.number,
  status: PropTypes.string,
});

const propTypes = {
  step: stepProps.isRequired,
};

export const substageNames = {
  a: 'Ad Data Added',
  b: 'Asset Added',
  c: 'CV Processed',
  d: 'Audit Objects Added',
  e: 'Rules Engine Run',
  f: 'Dated Audit Posts Created',
  g: 'Reviewed',
};

export function getStatus(key, status) {
  if (key.endsWith('unsupported')) {
    return 'Unsupported';
  } else if (key.endsWith('failed')) {
    return 'Failed';
  } else if (key.endsWith('bad')) {
    return 'Bad Stage';
  } else if (status === 'completed') {
    return 'Completed';
  }
  return 'Processing';
}

async function copyTrackerIds({
  date,
  status,
  substage,
}) {
  const params = {
    date,
    status,
    substage,
  };
  const { data, isError } = await get(trackerIdsInternalPipelineTrackersPath(params));

  if (isError) {
    addErrorToast('Could not copy tracker IDs. Try again later');
  } else {
    addToast(`${data.length} tracker IDs copied to clipboard`);
    navigator.clipboard.writeText(data.join(','));
  }
}

function getSubstageStatus(letter, substage) {
  if (substage.includes(letter)) {
    return 'Completed';
  }

  const lastSubstage = substage.at(-1);

  if (letter < lastSubstage) {
    return <div className={styles.error}>Missing</div>;
  }

  return 'N/A';
}

function PipelineStep({ step }) {
  return (
    <div className="u-marginTop-16">
      <div className="u-marginBottom-16">
        <div>{ `${step.count} Ads` }</div>
        <div>{ `Status: ${getStatus(step.key, step.status)}` }</div>
        <Button
          iconRight="copy"
          label="Copy Tracker IDs"
          variant="tertiary"
          onClick={() => copyTrackerIds(step)}
        />
      </div>
      <h5 className="u-marginBottom-8">Operations</h5>
      <div className={styles.operations}>
        { Object.entries(substageNames).map(([letter, label]) => (
          <Fragment key={letter}>
            <div>{ `${label}:` }</div>
            <div>{ getSubstageStatus(letter, step.substage) }</div>
          </Fragment>
        )) }
      </div>
    </div>
  );
}

PipelineStep.propTypes = propTypes;

export default PipelineStep;
