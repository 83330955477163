import React, {
  useState,
  useMemo,
  Fragment,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Dropdown,
  Icon,
  Tag,
} from '@makeably/creativex-design-system';
import { useViewPage } from 'utilities/mixpanel';

const accountProps = PropTypes.shape({
  country: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

export const dropdownProps = {
  brands: PropTypes.arrayOf(optionProps).isRequired,
  markets: PropTypes.arrayOf(optionProps).isRequired,
  partners: PropTypes.arrayOf(optionProps).isRequired,
};

const propTypes = {
  ...dropdownProps,
  selectedAccounts: PropTypes.arrayOf(accountProps).isRequired,
  onContinue: PropTypes.func.isRequired,
};

const dropdownKeys = ['brand', 'market', 'partner'];

function getAssociations(accounts) {
  return new Map(accounts.map((account) => [account.id, {
    brand: null,
    market: null,
    partner: null,
  }]));
}

const getMarketByCode = (markets, code) => markets.get(code);

function isAssociationComplete(association) {
  return Object.values(association).every(Boolean);
}

function renderInfo(title, value) {
  return (
    <div className="u-flexColumn u-gap-8">
      <div className="t-caption-1">{ title }</div>
      <div className="t-body-1">{ value }</div>
    </div>
  );
}

function AssociateAccounts({
  selectedAccounts,
  brands,
  markets,
  partners,
  onContinue,
  onGoBack,
}) {
  const [showBulkCard, setShowBulkCard] = useState(false);
  const [checkedAccountIds, setCheckedAccountIds] = useState(new Set([]));
  const [associationCount, setAssociationCount] = useState(0);
  const [bulkDropdowns, setBulkDropdowns] = useState({
    brand: undefined,
    market: undefined,
    partner: undefined,
  });
  const [associations, setAssociations] = useState(getAssociations(selectedAccounts));
  const [isBulkApplyDisabled, setIsBulkApplyDisabled] = useState(true);
  const dropdowns = useMemo(() => ({
    brand: {
      label: 'Brand',
      options: brands,
    },
    market: {
      label: 'Market',
      options: markets,
    },
    partner: {
      label: 'Partner',
      options: partners,
    },
  }), [brands, markets, partners]);
  const marketsMap = useMemo(() => new Map(
    markets.map((market) => [market.value, market.label]),
  ), [markets]);

  useViewPage('Associate Accounts');

  useEffect(() => {
    const allDropdownsSelected = Object.values(bulkDropdowns).every(Boolean);
    setIsBulkApplyDisabled(!allDropdownsSelected || checkedAccountIds.size < 1);
  }, [bulkDropdowns, checkedAccountIds]);

  const handleAccountCheck = (checked, accountId) => {
    if (checked) {
      setCheckedAccountIds((prev) => new Set([...prev, accountId]));
    } else {
      setCheckedAccountIds((prev) => {
        const newSet = new Set([...prev]);
        newSet.delete(accountId);
        return newSet;
      });
    }
  };

  const handleCheckAll = (checked) => {
    if (checked) {
      setCheckedAccountIds(new Set(selectedAccounts.map((account) => account.id)));
    } else {
      setCheckedAccountIds(new Set([]));
    }
  };

  const updateAssociationCount = (prevAssociation, updatedAssociation) => {
    const wasComplete = isAssociationComplete(prevAssociation);
    const isNowComplete = isAssociationComplete(updatedAssociation);

    if (!wasComplete && isNowComplete) {
      setAssociationCount((count) => count + 1);
    }
  };

  const handleDropdownChange = (key, accountId, option) => {
    if (accountId) {
      setAssociations((prev) => {
        const newAssociations = new Map(prev);
        const prevAssociation = newAssociations.get(accountId);
        const updatedAssociation = {
          ...prevAssociation,
          [key]: option,
        };

        newAssociations.set(accountId, updatedAssociation);
        updateAssociationCount(prevAssociation, updatedAssociation);

        return newAssociations;
      });
    } else {
      setBulkDropdowns((prev) => ({
        ...prev,
        [key]: option,
      }));
    }
  };

  const handleBulkApply = () => {
    const newAssociations = new Map(associations);
    checkedAccountIds.forEach((accountId) => {
      const prevAssociation = newAssociations.get(accountId);
      const updatedAssociation = {
        brand: bulkDropdowns.brand,
        market: bulkDropdowns.market,
        partner: bulkDropdowns.partner,
      };
      newAssociations.set(accountId, updatedAssociation);
      updateAssociationCount(prevAssociation, updatedAssociation);
    });
    setAssociations(newAssociations);
    setCheckedAccountIds(new Set([]));
    setBulkDropdowns({
      brand: undefined,
      market: undefined,
      partner: undefined,
    });
  };

  const renderDropdown = (key, options, id = null) => {
    const dropdown = options[key];

    if (!dropdown) return null;

    return (
      <Fragment key={key}>
        <Dropdown
          {...dropdown}
          menuProps={{ size: 'medium' }}
          selected={id ? associations.get(id)[key] : bulkDropdowns[key]}
          size="medium"
          onChange={(option) => { handleDropdownChange(key, id, option); }}
        />
      </Fragment>
    );
  };

  const renderAccountCard = (account) => (
    <Card key={account.id} className="u-flexColumn u-gap-16 u-marginBottom-16">
      <div>
        <Checkbox
          checked={checkedAccountIds.has(account.id)}
          label={account.name}
          onChange={(e) => handleAccountCheck(e.target.checked, account.id)}
        />
      </div>
      <Divider />
      <div className="u-flexRow u-gap-24">
        { renderInfo('ID', account.id) }
        { renderInfo('Country', getMarketByCode(marketsMap, account.country)) }
      </div>
      <Divider />
      <div className="u-flexRow u-gap-8">
        { dropdownKeys.map((key) => renderDropdown(key, dropdowns, account.id)) }
      </div>
    </Card>
  );

  return (
    <div className="u-flexColumn u-gap-24">
      <div>
        <h5>Associate each ad account with its correct brand, market and partner.</h5>
      </div>
      <Card>
        <div className="u-flexColumn u-gap-16">
          <div className="u-flexRow u-justifyBetween">
            <div className="t-subtitle">Bulk associate brand, market and partner</div>
            <button
              type="button"
              onClick={() => setShowBulkCard((value) => !value)}
            >
              <Icon name={showBulkCard ? 'chevronUp' : 'chevronDown'} />
            </button>
          </div>
          { showBulkCard && (
            <>
              <Divider />
              <div className="u-flexRow u-justifyBetween">
                <Checkbox
                  checked={checkedAccountIds.size === selectedAccounts.length}
                  label="Select all ad accounts"
                  onChange={(e) => handleCheckAll(e.target.checked)}
                />
                <Tag label={`${checkedAccountIds.size}/${selectedAccounts.length} Selected`} />
              </div>
              <div className="u-flexRow u-gap-8">
                { dropdownKeys.map((key) => renderDropdown(key, dropdowns)) }
              </div>
              <div className="u-flexRow u-justifyEnd">
                <Button
                  disabled={isBulkApplyDisabled}
                  label="Apply"
                  variant="secondary"
                  onClick={handleBulkApply}
                />
              </div>
            </>
          ) }
        </div>
      </Card>
      <Divider />
      <div>
        <div className="u-flexRow u-justifyEnd u-marginBottom-8">
          <div className="t-body-2">
            { `Ad Accounts assigned: ${associationCount}/${associations.size}` }
          </div>
        </div>
        { selectedAccounts.map((account) => (
          renderAccountCard(account)
        )) }
      </div>
      <div className="u-flexRow u-justifyEnd">
        <div className="u-buttonGroup">
          <Button
            label="Back"
            variant="secondary"
            onClick={onGoBack}
          />
          <Button
            disabled={associationCount < associations.size}
            label="Continue"
            onClick={() => onContinue(associations)}
          />
        </div>
      </div>
    </div>
  );
}

AssociateAccounts.propTypes = propTypes;

export default AssociateAccounts;
