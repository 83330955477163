// Pretest thumbnail displayed in each row, with the modal logic
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {
  Icon,
  MaxWidthText,
} from '@makeably/creativex-design-system';
import Modal from 'components/reusable/modal.coffee';
import {
  auditScorecardPath,
} from 'utilities/routes';
import styles from './CreativeCell.module.css';

const preflightShape = PropTypes.shape({
  assetId: PropTypes.number,
  brandConsistent: PropTypes.bool,
  channel: PropTypes.string,
  creativeName: PropTypes.string,
  creativeThumbnail: PropTypes.string,
  excellentCreatives: PropTypes.oneOf([PropTypes.string, PropTypes.bool]),
  isPreflightReviewed: PropTypes.bool,
  isVideo: PropTypes.bool,
  partner: PropTypes.string,
  pretestProcessingStatus: PropTypes.string,
  pretestStatus: PropTypes.string,
  regulatoryResult: PropTypes.string,
  // have disabled the rule below as it is a variable object with different keys
  // eslint-disable-next-line react/forbid-prop-types
  ruleResults: PropTypes.object,
  rulesMet: PropTypes.string,
  // have disabled the rule below as it is a variable object with different keys
  // eslint-disable-next-line react/forbid-prop-types
  scores: PropTypes.object,
  submittedTime: PropTypes.string,
});

const propTypes = {
  item: PropTypes.shape({
    assetId: PropTypes.number,
    children: PropTypes.arrayOf(preflightShape),
    creativeName: PropTypes.string,
    creativeThumbnail: PropTypes.string,
    isChild: PropTypes.bool,
    isVideo: PropTypes.bool,
    loadOnOpen: PropTypes.bool,
    pretestProcessingStatus: PropTypes.string,
    pretestStatus: PropTypes.string,
  }).isRequired,
};

const MODAL_ID_PREFIX = 'pretest_creative_viewer';

function CreativeCell({
  item: {
    assetId,
    children,
    creativeName,
    creativeThumbnail,
    isChild,
    isVideo,
    pretestProcessingStatus,
    pretestStatus,
  },

}) {
  const hasChildren = children?.length > 0;
  const dataTarget = `${MODAL_ID_PREFIX}_${assetId}`;

  const thumbnailClasses = classNames(
    styles.creativeContainer,
    { [styles.creativeContainerHover]: isChild || !hasChildren },
  );

  const containerClasses = classNames(
    styles.details,
    { [styles.margin]: isChild },
  );

  return (
    <div className={containerClasses}>
      { assetId && (
        <Modal
          className="scorecard-container"
          contentLocation={auditScorecardPath(assetId, {
            include_matched_posts: false,
            pretest_status: pretestStatus ? 'completed' : 'in-review',
          })}
          id={dataTarget}
          loadOnOpen
        />
      ) }
      <div className={styles.badgeContainer}>
        <div className={thumbnailClasses} data-target={!hasChildren && dataTarget}>
          { isVideo && (
            <div className={styles.videoContainer}>
              <Icon color="white" name="play" />
            </div>
          ) }
          <img alt="creative thumbnail" src={creativeThumbnail} />
        </div>
        { hasChildren && <div className={`t-body-2 ${styles.badge}`}>{ children.length }</div> }
      </div>
      <div className={styles.status}>
        <span className="t-body-1"><MaxWidthText text={creativeName} /></span>
        <span className="t-body-2">{ pretestProcessingStatus }</span>
      </div>
    </div>
  );
}

CreativeCell.propTypes = propTypes;

export default CreativeCell;
