import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Drawer,
  Icon,
} from '@makeably/creativex-design-system';
import Accordion from 'components/atoms/Accordion';
import LinkButton from 'components/atoms/LinkButton';
import { addToast } from 'components/organisms/Toasts';
import ChannelsWithDrawer from 'components/rules/guidelines/ChannelsWithDrawer';
import GuidelineDetail, { guidelineDetailType } from 'components/rules/guidelines/GuidelineDetail';
import GuidelinesContext from 'components/rules/guidelines/GuidelinesContext';
import {
  benchmarksIndustryReportsPath,
  guidelinesUrl,
  scorePath,
} from 'utilities/routes';
import styles from './Guidelines.module.css';

const MAX_LINKS_CHARACTERS = 50;

const BENCHMARK_LINK = {
  key: 'benchmarks',
  url: benchmarksIndustryReportsPath(),
  label: 'Benchmarks',
};

const guidelinePropTypes = {
  guideline: PropTypes.shape({
    channels: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
      value: PropTypes.string,
    })).isRequired,
    description: PropTypes.string.isRequired,
    guidelineDetails: PropTypes.arrayOf(guidelineDetailType).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    standard: PropTypes.bool.isRequired,
    activeScores: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      label: PropTypes.string,
    })),
  }).isRequired,
};

function Guideline({ guideline }) {
  const {
    activeScores,
    channels,
    description,
    guidelineDetails,
    id,
    name,
    standard,
  } = guideline;

  const {
    featureAccess: {
      isBenchmarksEnabled,
      isCqEnabled,
    },
  } = useContext(GuidelinesContext);

  const displayBenchmarks = isBenchmarksEnabled && standard;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState({
    header: null,
    content: null,
  });

  useEffect(() => {
    setDrawerOpen(false);
  }, [guideline]);

  const openDrawer = (header, content) => {
    setDrawerOpen(true);
    setDrawerContent({
      header,
      content,
    });
  };
  const sortDefinitionsAlpha = (definitionA, definitionB) => {
    if (!definitionA.name) {
      return 1;
    }

    return definitionA.name.localeCompare(definitionB.name);
  };
  const activeGuidelineDetails = guidelineDetails
    .filter(({ state }) => state === 'active')
    .sort(sortDefinitionsAlpha);
  const inactiveGuidelineDetails = guidelineDetails
    .filter(({ state }) => state === 'inactive')
    .sort(sortDefinitionsAlpha);

  const renderDefinitions = (sectionName, details) => (
    <Accordion
      header={(
        <h5>
          { `${sectionName} (${details.length})` }
        </h5>
      )}
    >
      { details.map((guidelineDetail) => (
        <GuidelineDetail
          key={guidelineDetail.id}
          guidelineDetail={guidelineDetail}
          openDrawer={openDrawer}
        />
      )) }
    </Accordion>
  );

  const renderLink = ({
    key,
    url,
    label,
  }) => (
    <a
      key={key}
      className="u-flexRow"
      href={url}
      rel="noreferrer"
      target="_blank"
    >
      <span className={styles.scoreLink}>{ label }</span>
      <Icon color="grey" name="externalLink" />
    </a>
  );

  const renderApplicability = () => {
    if (activeScores.length === 0 && !displayBenchmarks) {
      return 'N/A';
    }

    const initialLinks = displayBenchmarks ? [BENCHMARK_LINK] : [];

    const applicableLinks = activeScores.reduce((links, score) => ([
      ...links,
      {
        key: score.id,
        url: scorePath(score.id),
        label: score.label,
      },
    ]), initialLinks);

    const linkCharLength = applicableLinks.reduce((sum, link) => sum + link.label.length, 0);

    if (linkCharLength > MAX_LINKS_CHARACTERS) {
      const drawerLinks = applicableLinks.map((link) => renderLink(link));
      return (
        <LinkButton onClick={() => openDrawer(`Scores (${applicableLinks.length})`, drawerLinks)}>
          { applicableLinks.length }
        </LinkButton>
      );
    }

    return applicableLinks.map((link, idx) => (
      <div key={link.key} className="u-flexRow">
        { (idx > 0) && (
          <div className="u-marginLeft-8 u-marginRight-8">
            <Divider vertical />
          </div>
        ) }
        { renderLink(link) }
      </div>
    ));
  };

  const copyGuidelineUrl = () => {
    navigator.clipboard.writeText(guidelinesUrl({ guideline: id }));
    addToast('Link Copied to Clipboard');
  };

  return (
    <div className={styles.guidelineContainer}>
      <div className="u-flexRow u-flexAlignCenter">
        { (isBenchmarksEnabled || isCqEnabled) && standard && (
          <div className={classnames('u-marginRight-8', styles.cxIcon)}>
            <Icon color="current" name="cxIcon" />
          </div>
        ) }
        <h5>{ name }</h5>
        <div className={styles.anchor}>
          <Button
            iconLeft="link"
            variant="round"
            onClick={copyGuidelineUrl}
          />
        </div>
      </div>
      <div className="t-subtitle u-marginBottom-16">{ description }</div>
      <div className={classnames(styles.scrollable, 'u-flexGrow')}>
        <ChannelsWithDrawer channels={channels} openDrawer={openDrawer} />
        <div className="u-marginBottom-8">
          <div className="t-caption-1 u-marginBottom-8">Applicability:</div>
          <div className="u-flexRow u-alignCenter">
            { renderApplicability() }
          </div>
        </div>
        <Divider margin />
        { renderDefinitions('Definitions', activeGuidelineDetails) }
        { inactiveGuidelineDetails.length > 0
            && renderDefinitions('Inactive Definitions', inactiveGuidelineDetails) }
      </div>
      <Drawer isOpen={drawerOpen} closeOnExternalClick onClose={() => setDrawerOpen(false)}>
        <div className={classnames(styles.fullHeight, 'u-flexColumn')}>
          <h5 className={styles.cardMargin}>{ drawerContent.header }</h5>
          <Divider />
          <div className={classnames(styles.cardMargin, styles.scrollable, 'u-flexGrow')}>
            { drawerContent.content }
          </div>
          <Divider />
          <div className={classnames('u-flexRow', 'u-justifyStart', 'u-marginTop-16', styles.cardMargin)}>
            <Button label="Close" variant="primary" onClick={() => setDrawerOpen(false)} />
          </div>
        </div>
      </Drawer>
    </div>
  );
}

Guideline.propTypes = guidelinePropTypes;

export default Guideline;
