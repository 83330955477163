import React, {
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Divider,
} from '@makeably/creativex-design-system';
import ActionButton from 'components/molecules/ActionButton';
import ItemsTable from 'components/molecules/ItemsTable';
import { addToast } from 'components/organisms/Toasts';
import { getItemSortBy } from 'utilities/item';
import {
  track,
  useViewPage,
} from 'utilities/mixpanel';
import {
  addFormDataArray,
  getAuthenticityToken,
  post,
} from 'utilities/requests';
import { settingsLinkedPlatformAccountsPath } from 'utilities/routes';

const itemProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
});

const accountProps = PropTypes.shape({
  brand: itemProps.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  market: itemProps.isRequired,
  name: PropTypes.string.isRequired,
  partner: itemProps.isRequired,
});

const propTypes = {
  accountsWithAssociations: PropTypes.arrayOf(accountProps).isRequired,
  formUrl: PropTypes.string.isRequired,
  onGoBack: PropTypes.func.isRequired,
};

const headers = [
  {
    key: 'name',
    label: 'Ad Account Name',
  },
  {
    key: 'id',
    label: 'Ad Account Id',
  },
  {
    key: 'brand',
    label: 'Brand',
  },
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'partner',
    label: 'Partner',
  },
];

function getItems(accounts) {
  return accounts.map((account) => ({
    name: { value: account.name },
    id: { value: account.id },
    brand: account.brand,
    market: account.market,
    partner: account.partner,
  }));
}

async function submitAccounts(accountsWithAssociations, formUrl) {
  const accounts = accountsWithAssociations.map((account) => ({
    id: account.id,
    brand_id: account.brand.value,
    name: account.name,
    partner_id: account.partner.value,
    market: account.market.value,
  }));

  const formData = new FormData();
  addFormDataArray(formData, 'accounts', accounts);
  formData.append('authenticity_token', getAuthenticityToken());

  const response = await post(formUrl, formData);
  return response.data;
}

function Confirmation({
  accountsWithAssociations,
  formUrl,
  onGoBack,
}) {
  const [sort, setSort] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);

  useViewPage('Confirmation');

  const items = useMemo(
    () => getItems(accountsWithAssociations),
    [accountsWithAssociations],
  );
  const sortedItems = useMemo(() => {
    if (sort) {
      const byKeyDir = getItemSortBy(sort.key, sort.asc);
      return items.slice().sort(byKeyDir);
    }
    return items;
  }, [items, sort]);

  const handleConfirm = async () => {
    setIsSubmitting(true);
    const result = await submitAccounts(accountsWithAssociations, formUrl);
    addToast(result.message, { type: result.success ? 'success' : 'error' });
    if (result.success) {
      track('submit_success');
      window.location.href = settingsLinkedPlatformAccountsPath({ after_connection: true });
    }
  };

  return (
    <div className="u-flexColumn u-gap-24">
      <div className="t-body-2">
        Confirm each Ad Account is associated with its correct brand, market
        and partner.
      </div>
      <Divider />
      <h5>Account Connections</h5>
      <Card>
        <ItemsTable
          headers={headers}
          items={sortedItems}
          sort={sort}
          onSortChange={(value) => setSort(value)}
        />
      </Card>
      <div className="u-flexRow u-justifyEnd">
        <div className="u-buttonGroup u-gap-8">
          <Button
            label="Back"
            variant="secondary"
            onClick={onGoBack}
          />
          <ActionButton
            active={isSubmitting}
            label="Confirm"
            onClick={handleConfirm}
          />
        </div>
      </div>
    </div>
  );
}

Confirmation.propTypes = propTypes;

export default Confirmation;
