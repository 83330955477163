import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  DataVizSkeleton,
  Divider,
  Icon,
  Tooltip,
} from '@makeably/creativex-design-system';
import BenchmarksBarChart from 'components/benchmarks/BenchmarksBarChart';
import {
  benchmarkGuidelines,
  getItemsForMetric,
  metricProps,
} from 'components/benchmarks/utilities';
import { guidelinesPath } from 'utilities/routes';

const propTypes = {
  company: PropTypes.string.isRequired,
  companyMetrics: metricProps.isRequired,
  loading: PropTypes.bool.isRequired,
  reportMetrics: metricProps.isRequired,
};

const guidelinesTooltip = 'Compare with your industry how well your company follows digital suitability guidelines.';

function GuidelinesVisualization({
  company,
  companyMetrics,
  loading,
  reportMetrics,
}) {
  return (
    <Card>
      <div className="u-flexColumn u-gap-16">
        <div className="u-flexRow u-justifyBetween">
          <div className="u-flexRow u-gap-8 u-alignCenter">
            <div className="t-subtitle">
              Industry Guideline Adoption Rate
            </div>
            <Tooltip label={guidelinesTooltip} />
          </div>
          <a
            className="u-flexRow"
            href={guidelinesPath({ benchmarks: true })}
            rel="noreferrer"
            target="_blank"
          >
            <span className="u-marginRight-8">Definitions</span>
            <Icon color="grey" name="externalLink" />
          </a>
        </div>
        <Divider />
        { loading && (
          <div className="u-flexRow u-justifyCenter">
            <DataVizSkeleton animate />
          </div>
        ) }
        { !loading && benchmarkGuidelines.map((guideline) => (
          <div className="u-flexColumn">
            <div className="t-subtitle">
              { guideline }
            </div>
            <BenchmarksBarChart
              items={getItemsForMetric(guideline, company, companyMetrics, reportMetrics)}
            />
          </div>
        )) }
      </div>
    </Card>
  );
}

GuidelinesVisualization.propTypes = propTypes;

export default GuidelinesVisualization;
