export function getAssetCount(assets) {
  const uniqueCoreAssets = new Set(
    assets.map(({ uuid }) => uuid).filter((uuid) => !!uuid),
  );

  return uniqueCoreAssets.size;
}

export function getDateOptions(elements) {
  const uniqueYears = new Set(elements.map(({ year }) => year));
  const dateOptions = [...uniqueYears].sort().reverse().map((year) => ({
    label: year.toString(),
    value: year,
  }));
  return dateOptions;
}
