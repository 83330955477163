// Helper functions for sorting data

// Returns a compare function to sort objects by label
export function byObjectLabel(a, b) {
  if (a.label > b.label) {
    return 1;
  }
  if (a.label < b.label) {
    return -1;
  }
  return 0;
}

// Returns a compare function to sort object by value
export function byObjectValue(a, b) {
  if (a.value > b.value) {
    return 1;
  }
  if (a.value < b.value) {
    return -1;
  }
  return 0;
}

// Returns a compare function to sort objects by the supplied key
export function getByObjectKey(key, asc = true) {
  return (a, b) => {
    if (a[key] > b[key]) {
      return asc ? 1 : -1;
    }
    if (a[key] < b[key]) {
      return asc ? -1 : 1;
    }
    return 0;
  };
}

const rankValues = {
  lowest: 0,
  low: 1,
  medium: 2,
  high: 3,
  highest: 4,
};

// Returns a compare function to sort by tier rank
export function byRank(a, b) {
  const aValue = rankValues[a];
  const bValue = rankValues[b];

  if (aValue > bValue) {
    return 1;
  }
  if (aValue < bValue) {
    return -1;
  }
  return 0;
}
