import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dropdown,
  MoreButton,
  MultipleDropdown,
  Tooltip,
} from '@makeably/creativex-design-system';
import StickyTop from 'components/atoms/StickyTop';
import ActivationCard from 'components/creative_lifecycle/ActivationCard';
import CoreAssetDetails, { coreAssetProps } from 'components/creative_lifecycle/CoreAssetDetails';
import RemoveActivations from 'components/creative_lifecycle/RemoveActivations';
import RestoreActivations from 'components/creative_lifecycle/RestoreActivations';
import { metricTooltips } from 'components/creative_lifecycle/shared';
import { arrayIf } from 'utilities/array';
import { getItemSortBy } from 'utilities/item';
import {
  filterItems,
  getOptions,
  updateSelections,
} from 'utilities/itemFilter';
import { useViewPage } from 'utilities/mixpanel';
import { auditScorecardPath } from 'utilities/routes';
import { byRank } from 'utilities/sort';
import {
  toConciseSpend,
  toConciseCount,
  toMultiplier,
  toPercent,
} from 'utilities/string';
import styles from './Activations.module.css';

const activationProps = {
  assetMatchId: PropTypes.number.isRequired,
  auditAssetId: PropTypes.number.isRequired,
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  id: PropTypes.number.isRequired,
  markets: PropTypes.arrayOf(PropTypes.string).isRequired,
  ranks: PropTypes.arrayOf(PropTypes.string).isRequired,
  scores: PropTypes.arrayOf(PropTypes.string).isRequired,
  url: PropTypes.string.isRequired,
};

const propTypes = {
  canViewCreatives: PropTypes.bool.isRequired,
  canViewSpend: PropTypes.bool.isRequired,
  coreAsset: PropTypes.shape(coreAssetProps).isRequired,
  matched: PropTypes.arrayOf(
    PropTypes.shape(activationProps),
  ).isRequired,
  summaryMetrics: PropTypes.shape({
    impressions: PropTypes.number.isRequired,
    spend: PropTypes.number.isRequired,
  }).isRequired,
  unmatched: PropTypes.arrayOf(
    PropTypes.shape(activationProps),
  ).isRequired,
};

const MODE_NORMAL = 'normal';
const MODE_REMOVE = 'remove';
const MODE_RESTORE = 'restore';

const filterDimensions = [
  {
    key: 'market',
    label: 'Market',
  },
  {
    key: 'channel',
    label: 'Channel',
  },
];

const sortOptions = [
  {
    label: 'Score (High - Low)',
    sort: {
      key: 'score',
      asc: false,
    },
    value: 0,
  },
  {
    label: 'Score (Low - High)',
    sort: {
      key: 'score',
      asc: true,
    },
    value: 1,
  },
];

function renderLabelWithTooltip(label, tooltip) {
  return (
    <div className={styles.labelTooltip}>
      { label }
      <Tooltip ariaLabelSubject={label} label={tooltip} />
    </div>
  );
}

function getChannel(activation) {
  const entries = activation.channels.map((str) => str.split('::'));
  const options = entries.map(([value, label]) => ({
    value,
    label,
  }));
  const label = options.length === 1 ? options[0].label : 'Multiple';
  const value = options.length === 1 ? options[0].value : 'multiple';

  return {
    label,
    value,
    options,
  };
}

function getMarket(activation) {
  const options = activation.markets.map((value) => ({
    label: value,
    value,
  }));
  const value = options.length === 1 ? options[0].value : 'Multiple';

  return {
    options,
    value,
  };
}

function getRank(activation) {
  const sorted = activation.ranks.slice().sort(byRank);
  const value = sorted[0];

  return { value };
}

function getScore(activation) {
  const values = activation.scores.map((str) => parseFloat(str));
  const filtered = values.filter((value) => !Number.isNaN(value));
  const sorted = filtered.sort();
  const options = sorted.map((value) => ({
    label: toPercent(value),
    value,
  }));
  const value = sorted.at(-1) ?? null;
  const label = toPercent(value);

  return {
    label,
    options,
    value,
  };
}

function getActivationItems(activations) {
  return activations.map((activation) => ({
    assetMatchId: { value: activation.assetMatchId },
    auditAssetId: { value: activation.auditAssetId },
    channel: getChannel(activation),
    id: { value: activation.id },
    market: getMarket(activation),
    postIds: { value: activation.postIds },
    rank: getRank(activation),
    score: getScore(activation),
    url: { value: activation.url },
    videoLength: { value: activation.videoLength },
  }));
}

function getAverageScore(items) {
  const total = items.reduce(({ count, sum }, item) => {
    const options = item.score?.options ?? [];
    const itemSum = options.reduce((s, { value }) => s + value, 0);

    return {
      count: count + options.length,
      sum: sum + itemSum,
    };
  }, {
    count: 0,
    sum: 0,
  });

  if (total.count === 0) {
    return 'N/A';
  }
  return toPercent(total.sum / total.count);
}

function getMarkets(options) {
  if (options.market.length === 0) {
    return 0;
  }

  const marketNames = options.market.map((market) => market.label);

  return (
    <Tooltip direction="bottom" label={marketNames.join(', ')}>
      { marketNames.length }
    </Tooltip>
  );
}

function getChannels(options) {
  if (options.channel.length === 0) {
    return 'N/A';
  }

  const channelNames = options.channel.map((channel) => channel.label);

  return (
    <Tooltip direction="bottom" label={channelNames.join(', ')}>
      { channelNames.length }
    </Tooltip>
  );
}

function getActivationTotals(items, options, summaryMetrics, canViewSpend) {
  const postCount = new Set(
    items.flatMap((item) => item.postIds?.value ?? []),
  ).size;

  return [
    {
      label: renderLabelWithTooltip('Average Score', metricTooltips.averageScore),
      value: getAverageScore(items),
    },
    {
      label: renderLabelWithTooltip('Repurposed Rate', metricTooltips.repurposedRate),
      value: toMultiplier(items.length),
    },
    {
      label: renderLabelWithTooltip('Reusage Rate', metricTooltips.reusageRate),
      value: toMultiplier(postCount),
    },
    ...arrayIf(canViewSpend, {
      label: renderLabelWithTooltip('Activated Media\nSpend', metricTooltips.spend),
      value: toConciseSpend(summaryMetrics.spend) || 'N/A',
    }, {
      label: renderLabelWithTooltip('Activated\nImpressions', metricTooltips.impressions),
      value: toConciseCount(summaryMetrics.impressions) || 'N/A',
    }),
    {
      label: 'Total Markets',
      value: getMarkets(options),
    },
    {
      label: 'Channels',
      value: getChannels(options),
    },
  ];
}

function Activations({
  canViewCreatives,
  canViewSpend,
  coreAsset,
  matched,
  summaryMetrics,
  unmatched,
}) {
  const [unmatchedItems, setUnmatchedItems] = useState([]);
  const [items, setItems] = useState([]);
  const [filterOptions, setFilterOptions] = useState({});
  const [filterSelections, setFilterSelections] = useState({});
  const [filteredItems, setFilteredItems] = useState([]);
  const [sortSelection, setSortSelection] = useState(sortOptions[0]);
  const [sortedItems, setSortedItems] = useState([]);
  const [activationTotals, setActivationTotals] = useState([]);
  const [mode, setMode] = useState(MODE_NORMAL);

  useViewPage();

  useEffect(() => {
    setUnmatchedItems(getActivationItems(unmatched));
  }, [unmatched]);

  useEffect(() => {
    const allItems = getActivationItems(matched);
    const options = getOptions(filterDimensions, allItems);
    const totals = getActivationTotals(allItems, options, summaryMetrics, canViewSpend);

    setItems(allItems);
    setFilterOptions(options);
    setActivationTotals(totals);
  }, [matched, summaryMetrics]);

  useEffect(() => {
    setFilteredItems(filterItems(items, filterSelections));
  }, [items, filterSelections]);

  useEffect(() => {
    const byKeyDir = getItemSortBy(sortSelection.sort.key, sortSelection.sort.asc);
    setSortedItems(filteredItems.slice().sort(byKeyDir));
  }, [filteredItems, sortSelection]);

  const handleFilterChange = (key, selected) => {
    setFilterSelections(updateSelections(filterSelections, key, selected));
  };

  const renderMoreButton = () => {
    const options = [
      {
        disabled: sortedItems.length === 0,
        label: 'Remove Activations',
        onClick: () => setMode(MODE_REMOVE),
      },
      {
        disabled: unmatchedItems.length === 0,
        label: 'Restore Removed Activations',
        onClick: () => setMode(MODE_RESTORE),
      },
    ];

    return <MoreButton menuSize="large" options={options} />;
  };

  const renderActivations = () => {
    if (items.length === 0) {
      return (
        <div className={`t-empty ${styles.empty}`}>
          This core asset has not yet been activated.
        </div>
      );
    } else if (filteredItems.length === 0) {
      return (
        <div aria-live="assertive" className={`t-empty ${styles.empty} ${styles.filtered}`}>
          There are no activations to display
        </div>
      );
    }

    return (
      <div className={styles.cards}>
        { sortedItems.map((item) => {
          const params = {
            include_matched_posts: true,
          };
          const url = canViewCreatives ? auditScorecardPath(item.id?.value, params) : null;

          return (
            <ActivationCard
              key={item.auditAssetId?.value}
              item={item}
              url={url}
            />
          );
        }) }
      </div>
    );
  };

  switch (mode) {
    case MODE_REMOVE:
      return (
        <RemoveActivations
          activationDetails={activationTotals}
          coreAsset={coreAsset}
          items={sortedItems}
          onClose={() => setMode(MODE_NORMAL)}
        />
      );
    case MODE_RESTORE:
      return (
        <RestoreActivations
          activationDetails={activationTotals}
          coreAsset={coreAsset}
          items={unmatchedItems}
          onClose={() => setMode(MODE_NORMAL)}
        />
      );
    default:
      return (
        <>
          <StickyTop>
            <CoreAssetDetails
              activationDetails={activationTotals}
              coreAsset={coreAsset}
              isEditing={false}
              moreButton={renderMoreButton()}
            />
            { items.length > 0 && (
              <div className={styles.controls}>
                { filterDimensions.map(({ key, label }) => (
                  <MultipleDropdown
                    key={key}
                    label={label}
                    options={filterOptions[key] ?? []}
                    placeholder="All"
                    selected={filterSelections[key]}
                    size="medium"
                    onChange={(sel) => handleFilterChange(key, sel)}
                  />
                )) }
                <div className={styles.clear}>
                  <Button
                    disabled={Object.keys(filterSelections).length === 0}
                    label="Clear"
                    variant="tertiary"
                    onClick={() => setFilterSelections({})}
                  />
                </div>
                <Dropdown
                  label="Sort"
                  menuProps={{ size: 'medium' }}
                  options={sortOptions}
                  selected={sortSelection}
                  size="medium"
                  onChange={setSortSelection}
                />
              </div>
            ) }
          </StickyTop>
          { renderActivations() }
        </>
      );
  }
}

Activations.propTypes = propTypes;

export default Activations;
