import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
} from '@makeably/creativex-design-system';
import EvaluationGridSelector, { defaultGridOptions } from 'components/admin/EvaluationGridSelector';
import ChannelLogo from 'components/atoms/ChannelLogo';
import CheckRow, { checkPropType } from 'components/audit/challenge/CheckRow';
import Review from 'components/audit/challenge/Review';
import { getLabelForRuleType } from 'components/audit/challenge/ruleLabels';
import EvaluationPageAsset from 'components/audit/EvaluationPageAsset';
import DsTabs from 'components/molecules/DsTabs';
import { addToast } from 'components/organisms/Toasts';
import { getAuthenticityToken } from 'utilities/requests';
import { validCxIdsAuditChallengesPath } from 'utilities/routes';
import { isNumeric } from 'utilities/string';
import styles from './Form.module.css';

const auditPostPropTypes = {
  channel: PropTypes.string.isRequired,
  channelDisplay: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  guidelineChecks: PropTypes.arrayOf(PropTypes.shape(checkPropType)).isRequired,
  id: PropTypes.number.isRequired,
  copy: PropTypes.string,
  thumbnailUrl: PropTypes.string,
};

const propTypes = {
  assetId: PropTypes.number.isRequired,
  auditPosts: PropTypes.arrayOf(PropTypes.shape(auditPostPropTypes)).isRequired,
  challengeReasons: PropTypes.objectOf(PropTypes.string).isRequired,
  creativeDetails: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
    }),
  ).isRequired,
  guidelineChecks: PropTypes.arrayOf(PropTypes.shape(checkPropType)).isRequired,
  isAdminChallenger: PropTypes.bool.isRequired,
  sourceUrl: PropTypes.string.isRequired,
  isVideo: PropTypes.bool,
};

const defaultProps = {
  isVideo: false,
};

const RULE_TYPES = ['Guidelines', 'Brand Cues', 'Regulations', 'Hypotheses'];

const defaultChallengeTemplate = Object.freeze({
  challenged: false,
  exemptionReason: '',
  isError: false,
  matchingAssetId: '',
  reason: '',
});

function readyForReview(guidelineChallenges) {
  const challengedGuidelines = Object
    .values(guidelineChallenges)
    .filter(({ challenged }) => challenged);

  if (challengedGuidelines.length === 0) return false;

  return challengedGuidelines.every(({
    reason, matchingAssetId, exemptionReason,
  }) => {
    switch (reason) {
      case 'identicalCreativePassed':
      case 'identicalCreativeFailed':
        return matchingAssetId !== '';
      case 'exemption':
        return exemptionReason !== '';
      default:
        return reason !== '';
    }
  });
}

async function checkCxIdValidity(challenges, assetId) {
  const idsToValidate = Object
    .values(challenges)
    .filter((challenge) => ['identicalCreativePassed', 'identicalCreativeFailed'].includes(challenge.reason))
    .map((challenge) => challenge.matchingAssetId);

  if (idsToValidate.length === 0) return [];

  const response = await fetch(validCxIdsAuditChallengesPath(), {
    method: 'POST',
    body: JSON.stringify({
      asset_id: assetId,
      creativex_ids: idsToValidate,
    }),
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getAuthenticityToken(),
    },
  });

  const data = await response.json();
  const invalidIds = data?.invalidIds;

  return Object
    .entries(challenges)
    .filter(([_checkId, challenge]) => invalidIds.includes(challenge.matchingAssetId))
    .map(([checkId, _challenge]) => checkId);
}

function renderTabs(activeTypes, activeTab, setActiveTab) {
  const tabOptions = activeTypes.map((ruleType) => ({
    label: ruleType,
    onClick: () => setActiveTab(ruleType),
  }));

  return (
    <DsTabs
      currentTab={activeTab}
      tabs={tabOptions}
      variant="button"
      showSingleTab
    />
  );
}

function renderCheckChallenge(
  check,
  challenges,
  updateChallenge,
  isAdminChallenger,
  challengeReasons,
) {
  const challenge = challenges[check.id] ?? defaultChallengeTemplate;

  const onMatchingAssetIdChange = (value) => {
    if (isNumeric(value)) updateChallenge(check.id, { matchingAssetId: value });
  };

  const onReasonChange = (event) => {
    const updatedValue = {
      reason: event.target.value,
      matchingAssetId: '',
      exemptionReason: '',
    };
    updateChallenge(check.id, updatedValue);
  };

  return (
    <CheckRow
      key={check.id}
      challenge={challenge}
      challengeReasons={challengeReasons}
      guidelineCheck={check}
      isAdminUser={isAdminChallenger}
      onExemptionReasonChange={(value) => {
        updateChallenge(check.id, { exemptionReason: value });
      }}
      onGuidelineClick={() => {
        updateChallenge(check.id, { challenged: !challenge.challenged });
      }}
      onMatchingAssetIdChange={onMatchingAssetIdChange}
      onReasonChange={onReasonChange}
    />
  );
}

function renderAuditPost(
  activeTab,
  {
    channel,
    channelDisplay,
    copy,
    guidelineChecks,
    id,
    thumbnailUrl,
  },
  challenges,
  updateChallenge,
  isAdminChallenger,
  challengeReasons,
) {
  const auditPostCheckChallenges = guidelineChecks
    .filter((check) => getLabelForRuleType(check.type) === activeTab)
    .map((check) => renderCheckChallenge(
      check,
      challenges,
      updateChallenge,
      isAdminChallenger,
      challengeReasons,
    ));

  return (
    <React.Fragment key={id}>
      { (auditPostCheckChallenges.length > 0) && (
        <div className={styles.subHeader}>
          <div className={styles.auditPost}>
            <div className={styles.header}>
              <div className={styles.logo}>
                <ChannelLogo
                  channel={channel}
                  showPaid={false}
                  size="size-24"
                />
                <span className="t-subtitle">{ `${channelDisplay} Post Rules` }</span>
              </div>
              <div className={styles.subtitle}>
                { copy && <p className="t-caption-1">{ copy }</p> }
                { thumbnailUrl && (
                  <img alt="post_thumbnail" className={styles.thumbnail} src={thumbnailUrl} />
                ) }
              </div>
            </div>
          </div>
        </div>
      ) }
      { auditPostCheckChallenges }
    </React.Fragment>
  );
}

function Form({
  isAdminChallenger,
  assetId,
  auditPosts,
  challengeReasons,
  creativeDetails,
  guidelineChecks,
  sourceUrl,
  isVideo,
}) {
  const activeTypes = RULE_TYPES
    .filter((rt) => guidelineChecks.find((gc) => getLabelForRuleType(gc.type) === rt));

  const [activeTab, setActiveTab] = useState(activeTypes[0]);
  const [challenges, setChallenges] = useState({});
  const [gridOptions, setGridOptions] = useState(defaultGridOptions);
  const [isCheckingCxIds, setIsCheckingCxIds] = useState(false);
  const [showReview, setShowReview] = useState(false);

  const targetWidth = window.innerWidth / 4;
  const assetWidth = Math.min(Math.max(300, targetWidth), 480);

  const activeChecks = guidelineChecks.filter((gc) => getLabelForRuleType(gc.type) === activeTab);

  const isGuidelineOrHypothesisTab = ['Guidelines', 'Hypotheses'].includes(activeTab);

  const updateChallenge = (checkId, updates) => {
    setChallenges((prevChallenges) => {
      const challengeCopy = prevChallenges[checkId] ?? defaultChallengeTemplate;

      return {
        ...prevChallenges,
        [checkId]: {
          ...challengeCopy,
          ...updates,
        },
      };
    });
  };

  const onClick = async () => {
    setIsCheckingCxIds(true);
    Object.keys(challenges).forEach((id) => updateChallenge(id, { isError: false }));

    const invalidChallengeIds = await checkCxIdValidity(challenges, assetId);

    if (invalidChallengeIds.length > 0) {
      invalidChallengeIds.map((invalidId) => updateChallenge(invalidId, { isError: true }));
      addToast('The CreativeX ID entered is invalid', { type: 'error' });
    } else {
      setShowReview(true);
    }

    setIsCheckingCxIds(false);
  };

  const renderReview = () => {
    const allGuidelines = [
      ...guidelineChecks,
      ...auditPosts.map((ap) => ap.guidelineChecks).flat(),
    ];

    const reviewableGuidelines = allGuidelines.reduce((results, guidelineCheck) => {
      const guidelineChallenge = challenges[guidelineCheck.id];
      if (guidelineChallenge && guidelineChallenge.challenged) {
        results.push({
          guidelineCheck,
          guidelineChallenge,
        });
      }
      return results;
    }, []);

    return (
      <Review
        assetId={assetId}
        challengeReasons={challengeReasons}
        handleClose={() => setShowReview(false)}
        reviewableGuidelines={reviewableGuidelines}
      />
    );
  };

  const renderChecks = (checks) => checks.map((check) => renderCheckChallenge(
    check,
    challenges,
    updateChallenge,
    isAdminChallenger,
    challengeReasons,
  ));

  const renderPosts = (posts) => posts.map((post) => renderAuditPost(
    activeTab,
    post,
    challenges,
    updateChallenge,
    isAdminChallenger,
    challengeReasons,
  ));

  return (
    <div className={styles.challenge}>
      { showReview && renderReview() }
      <div className={styles.body}>
        <Card className={styles.creativeWrapper}>
          <div className="u-flexColumn">
            { creativeDetails.map((detail) => (
              <div key={detail.label} className={styles.detailWrapper}>
                <span>{ `${detail.label}:` }</span>
                <span>{ detail.value }</span>
              </div>
            )) }
          </div>
          <div className="u-flexColumn u-flexAlignCenter">
            <EvaluationPageAsset
              gridOptions={gridOptions}
              isVideo={isVideo}
              sourceUrl={sourceUrl}
              width={assetWidth}
              hidePercentage
            />
            <div className="u-marginAbove">
              <EvaluationGridSelector
                callback={(newGridOptions) => setGridOptions(newGridOptions)}
                gridOptions={gridOptions}
              />
            </div>
          </div>
        </Card>
        <Card className={styles.rulesCard} padding={false}>
          <div className={styles.tabRow}>
            <div className={styles.tabs}>
              { renderTabs(activeTypes, activeTab, setActiveTab) }
            </div>
            <div className={styles.review}>
              <Button
                disabled={!readyForReview(challenges) || isCheckingCxIds}
                label="Report Error"
                onClick={onClick}
              />
            </div>
          </div>
          <div className={styles.guidelines}>
            {
              isGuidelineOrHypothesisTab && (
                <div className={styles.subHeader}>
                  <div className={styles.auditPost}>
                    <div className={styles.header}>
                      <div className={styles.title}>
                        <h4 className="t-subtitle">Asset Rules</h4>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
            { renderChecks(activeChecks) }
            { isGuidelineOrHypothesisTab && renderPosts(auditPosts) }
          </div>
        </Card>
      </div>
    </div>
  );
}

Form.propTypes = propTypes;
Form.defaultProps = defaultProps;

export default Form;
