// Helper functions for logging errors
import Rollbar from 'rollbar';

const ROLLBAR_CLIENT_KEY = 'ee46335cc19746de867d9eb9e8244937';

// Log errors to Rollbar
export function logError(message, details) {
  const env = process.env.NODE_ENV;
  const isCi = process.env.CI;

  if (isCi || env === 'development') return;

  const rollbar = new Rollbar({
    accessToken: ROLLBAR_CLIENT_KEY,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: env,
  });

  rollbar.error(message, details);
}
