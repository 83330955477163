import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  DataVizSkeleton,
  Divider,
  RadioGroup,
  Tooltip,
} from '@makeably/creativex-design-system';
import BenchmarksBarChart from 'components/benchmarks/BenchmarksBarChart';
import {
  getBrandItemsForMetric,
  getItemsForMetric,
  metricProps,
} from 'components/benchmarks/utilities';

const propTypes = {
  company: PropTypes.string.isRequired,
  companyMetrics: metricProps.isRequired,
  loading: PropTypes.bool.isRequired,
  reportMetrics: metricProps.isRequired,
};

const CQR_OPTION = 'creativeQualityRate';
const QSR_OPTION = 'qualitySpendRate';

const radioOptions = [
  {
    label: 'Creative Quality Rate',
    value: CQR_OPTION,
  },
  {
    label: 'Quality Spend Rate',
    value: QSR_OPTION,
  },
];

const categoryTooltip = "Categories represent a way of classifying brands that belong to an industry. Compare your brand's Creative Quality Rate & Quality Spend Rate with its category benchmark within your company and industry.";

function CategoryVisualization({
  company,
  companyMetrics,
  loading,
  reportMetrics,
}) {
  const [metric, setMetric] = useState(CQR_OPTION);
  const [items, setItems] = useState([]);
  const [brandItems, setBrandItems] = useState([]);

  const displayCqr = metric === CQR_OPTION;
  const brandMetrics = companyMetrics.brandMetrics || [];

  useEffect(() => {
    const metricForItems = displayCqr ? 'scoreRate' : 'spendRate';
    setItems(getItemsForMetric(metricForItems, company, companyMetrics, reportMetrics));
    setBrandItems(getBrandItemsForMetric(metricForItems, brandMetrics));
  }, [metric, companyMetrics, reportMetrics]);

  return (
    <Card>
      <div className="u-flexColumn u-gap-16">
        <div className="u-flexRow u-gap-16">
          <div className="u-flexRow u-gap-8 u-alignCenter">
            <div className="t-subtitle">
              Category Benchmarks
            </div>
            <Tooltip label={categoryTooltip} />
          </div>
          <RadioGroup
            name="metricSelectionRadioGroup"
            options={radioOptions}
            value={metric}
            horizontal
            onChange={() => setMetric(displayCqr ? QSR_OPTION : CQR_OPTION)}
          />
        </div>
        <Divider />
        { loading && (
          <div className="u-flexRow u-justifyCenter">
            <DataVizSkeleton animate />
          </div>
        ) }
        { !loading && (
          <>
            <BenchmarksBarChart
              items={items}
            />
            { brandMetrics.length > 0 && (
              <>
                <Divider />
                <div className="t-subtitle">
                  { `Breakdown by Brand (${brandMetrics.length})` }
                </div>
                <BenchmarksBarChart
                  items={brandItems}
                  isSingleColor
                />
              </>
            ) }
          </>
        ) }
      </div>
    </Card>
  );
}

CategoryVisualization.propTypes = propTypes;

export default CategoryVisualization;
