// Buttons to be shown when the 'Select Core Asset'
// button has been clicked: cancels the action or
// adds the selected assets to CL
import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from '@makeably/creativex-design-system';

const propTypes = {
  disabled: PropTypes.bool.isRequired,
  onAddToCoreAssets: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

function CoreAssetButton({
  onCancel,
  onAddToCoreAssets,
  disabled,
}) {
  return (
    <>
      <Button
        label="Cancel"
        variant="secondary"
        onClick={onCancel}
      />
      <Button
        disabled={disabled}
        label="Add to Core Assets"
        onClick={onAddToCoreAssets}
      />
    </>
  );
}

CoreAssetButton.propTypes = propTypes;

export default CoreAssetButton;
