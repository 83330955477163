import React from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown,
  RadioGroup,
} from '@makeably/creativex-design-system';
import { optionProps } from 'components/internal/shared';
import UserPermissionOptions from 'components/internal/UserPermissionOptions';
import {
  getProfileRoles,
  roleLabels,
  roleOptionTypes,
} from 'components/shared/user';
import { findObjectByValue } from 'utilities/array';
import styles from './BulkUserSettings.module.css';

const settingsProps = PropTypes.objectOf(
  PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(PropTypes.string),
  ]),
);

const propTypes = {
  brandOptions: PropTypes.arrayOf(optionProps).isRequired,
  channelOptions: PropTypes.arrayOf(optionProps).isRequired,
  isLifecycleEnabled: PropTypes.bool.isRequired,
  isRepresentationEnabled: PropTypes.bool.isRequired,
  marketOptions: PropTypes.arrayOf(optionProps).isRequired,
  partnerOptions: PropTypes.arrayOf(optionProps).isRequired,
  settings: settingsProps.isRequired,
  onSettingsChange: PropTypes.func.isRequired,
  commonProfile: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.string),
};

const defaultProps = {
  commonProfile: undefined,
  errors: {},
};

export const OFF_VALUE = 'off';
export const ON_VALUE = 'on';

const permissionOptions = [
  {
    label: 'On',
    value: ON_VALUE,
  },
  {
    label: 'Off',
    value: OFF_VALUE,
  },
];

const profileOptions = [
  {
    label: 'Limited',
    value: 'limited',
  },
  {
    label: 'Connecting',
    value: 'connecting',
  },
  {
    label: 'Standard',
    value: 'standard',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
];

function BulkUserSettings({
  brandOptions,
  channelOptions,
  commonProfile,
  errors,
  isLifecycleEnabled,
  isRepresentationEnabled,
  marketOptions,
  partnerOptions,
  settings,
  onSettingsChange,
}) {
  const profile = settings.profile ?? commonProfile;
  const roles = getProfileRoles(profile, isRepresentationEnabled, isLifecycleEnabled);

  const renderOptions = (name, key, options, isOn, useLabels, error) => (
    <UserPermissionOptions
      key={key}
      disabled={!isOn}
      error={error}
      label={useLabels ? `Restrict By ${name}` : ''}
      options={options}
      values={settings[key] ?? []}
      onChange={(values) => onSettingsChange(key, values)}
    />
  );

  const renderOptionType = (optionType, isOn, useLabels, error) => {
    switch (optionType) {
      case 'brand':
        return renderOptions('Brand', 'whitelistedBrands', brandOptions, isOn, useLabels, error);
      case 'channel':
        return renderOptions('Channel', 'whitelistedAuditChannels', channelOptions, isOn, useLabels, error);
      case 'market':
        return renderOptions('Market', 'whitelistedMarkets', marketOptions, isOn, useLabels, error);
      case 'partner':
        return renderOptions('Partner', 'whitelistedOrganizationAffiliates', partnerOptions, isOn, useLabels, error);
      default:
        return null;
    }
  };

  const renderPermission = (role) => {
    const isOn = settings[role] === 'on';
    const optionTypes = roleOptionTypes[role] ?? [];
    const useLabels = optionTypes.length > 1;
    const error = errors?.[role];

    return (
      <div key={role}>
        <div className="u-marginBottom-8">
          <RadioGroup
            label={roleLabels[role]}
            name={roleLabels[role]}
            options={permissionOptions}
            value={settings[role]}
            onChange={(e) => onSettingsChange(role, e.target.value)}
          />
        </div>
        <div className="u-flexColumn u-gap-16">
          { optionTypes.map((type) => renderOptionType(type, isOn, useLabels, error)) }
        </div>
      </div>
    );
  };

  const renderPermissions = () => {
    if (commonProfile === null) {
      return (
        <div className="t-empty">
          Select users to edit permissions
        </div>
      );
    }

    if (roles.length === 0) {
      return (
        <div className="t-empty">
          Users must have the same account type to edit permissions
        </div>
      );
    }

    return roles.map((role) => renderPermission(role));
  };

  return (
    <div className={styles.settings}>
      <div className="u-flexColumn u-gap-24">
        <div>
          <h5>Details</h5>
          <div className="u-flexColumn u-gap-16">
            <Dropdown
              label="Partner"
              menuProps={{ size: 'large' }}
              options={partnerOptions}
              selected={findObjectByValue(partnerOptions, settings.organizationAffiliateId)}
              size="large"
              onChange={(opt) => onSettingsChange('organizationAffiliateId', opt.value)}
            />
            <RadioGroup
              label="Account Type"
              name="profile"
              options={profileOptions}
              value={settings.profile}
              onChange={(e) => onSettingsChange('profile', e.target.value)}
            />
          </div>
        </div>
        <div>
          <h5>Permissions</h5>
          <div className="u-flexColumn u-gap-16">
            { renderPermissions() }
          </div>
        </div>
      </div>
    </div>
  );
}

BulkUserSettings.propTypes = propTypes;
BulkUserSettings.defaultProps = defaultProps;

export default BulkUserSettings;
