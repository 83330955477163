import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { Card } from '@makeably/creativex-design-system';
import AuditActions from 'components/internal/explorer/AuditActions';
import Results from 'components/internal/explorer/Results';
import {
  getRecordItem,
  recordProps,
  rolesProps,
} from 'components/internal/explorer/shared';
import ItemGrid from 'components/molecules/ItemGrid';

export const linksProps = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
  }),
);

export const relatedProps = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number.isRequired,
    path: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
);

const propTypes = {
  record: recordProps.isRequired,
  type: PropTypes.string.isRequired,
  links: linksProps,
  related: relatedProps,
  roles: rolesProps,
  subtitle: PropTypes.string,
};

const defaultProps = {
  links: [],
  related: [],
  roles: {},
  subtitle: undefined,
};

function getActions(key, record, roles) {
  switch (key) {
    case 'Audit':
      return <AuditActions record={record} roles={roles} />;
    default:
      return null;
  }
}

function renderActions(actions, links) {
  if (!actions && links.length === 0) return null;

  return (
    <div>
      <h5 className="u-marginBottom-8">Actions</h5>
      <div className="u-flexRow u-gap-16 u-alignCenter">
        { actions }
        { links.map(({ label, url }) => <a key={url} href={url}>{ label }</a>) }
      </div>
    </div>
  );
}

function RecordDisplay({
  links,
  record,
  related,
  roles,
  subtitle,
  type,
}) {
  const [item, setItem] = useState({});
  const actions = getActions(type, record, roles);

  useEffect(() => {
    setItem(getRecordItem(record));
  }, [record]);

  return (
    <Card className="u-flexColumn u-gap-24">
      <div>
        <h4>{ `${type} ${record.id}` }</h4>
        { subtitle && (
          <div className="u-marginTop-8 u-marginLeft-8">
            { subtitle }
          </div>
        ) }
      </div>
      { renderActions(actions, links) }
      <div>
        <h5 className="u-marginBottom-8">Columns</h5>
        <ItemGrid item={item} />
      </div>
      { related.length > 0 && (
        <div>
          <h5 className="u-marginBottom-8">Related</h5>
          <Results records={related} />
        </div>
      ) }
    </Card>
  );
}

RecordDisplay.propTypes = propTypes;
RecordDisplay.defaultProps = defaultProps;

export default RecordDisplay;
