import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AddNewButton,
  MessageModal,
} from '@makeably/creativex-design-system';
import {
  addErrorToast,
  addToast,
} from 'components/organisms/Toasts';
import {
  patch,
  post,
} from 'utilities/requests';
import {
  adminStandardScorePath,
  adminStandardScoresPath,
} from 'utilities/routes';

const propTypes = {
  canManage: PropTypes.bool.isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
  details: PropTypes.shape({
    id: PropTypes.number,
    isProcessing: PropTypes.bool,
  }).isRequired,
  supportedForView: PropTypes.bool.isRequired,
};

async function updateScoreWeights(companyId, scoreId, isUpdate) {
  const params = { company_id: companyId };

  if (isUpdate) {
    return patch(adminStandardScorePath(scoreId, params));
  }
  return post(adminStandardScoresPath(params));
}

function StandardScore({
  canManage,
  company: {
    id: companyId,
    name: companyName,
  },
  details: {
    id: scoreId,
    isProcessing,
  },
  supportedForView,
}) {
  const [actionDisabled, setActionDisabled] = useState(isProcessing);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isUpdate = Boolean(scoreId);

  if (!supportedForView) return null;

  const handleClose = () => setIsModalOpen(false);

  const handleUpdateScore = async () => {
    handleClose();
    setActionDisabled(true);

    const { isError } = await updateScoreWeights(companyId, scoreId, isUpdate);
    const options = { size: 'large' };

    if (isError) {
      addErrorToast('Unable to process Standard Score, please try again', options);
      setActionDisabled(false);
    } else {
      addToast(isUpdate ? 'Processing updates to Standard Score' : 'Creating Standard Score', options);
    }
  };

  return (
    <>
      <AddNewButton
        disabled={!canManage || actionDisabled}
        label={isUpdate ? 'Update Standard Score' : 'Create Standard Score'}
        onClick={() => setIsModalOpen(true)}
      />
      <MessageModal
        actionButtonLabel="Confirm"
        isOpen={isModalOpen}
        size="medium"
        title={`Are you sure you want to ${isUpdate ? 'update the' : 'create a'} Standard Score for ${companyName}?`}
        onActionButtonClick={handleUpdateScore}
        onClose={handleClose}
      >
        {
          `No changes will be made to any pre-existing scores, but doing so will
          ${isUpdate ? 'update the' : 'add a'} Standard Score ${isUpdate ? 'on' : 'to'}
          their dashboard and trigger a recalcuation of their content.`
        }
      </MessageModal>
    </>
  );
}

StandardScore.propTypes = propTypes;

export default StandardScore;
