import React from 'react';
import PropTypes from 'prop-types';
import Pills from 'components/reusable/Pills';

const PLAYBACK_RATE_OPTIONS = [
  {
    value: '0.5',
    label: '0.5',
  },
  {
    value: '1',
    label: '1',
  },
  {
    value: '1.5',
    label: '1.5',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '5',
    label: '5',
  },
];

export const defaultPlaybackRate = 1;

const propTypes = {
  playbackRate: PropTypes.number.isRequired,
  onRateChange: PropTypes.func.isRequired,
};

function VideoPlaybackRateControls({ playbackRate, onRateChange }) {
  // @note: Pills need to be strings, hence the conversions between string/float
  return (
    <div className="videoPlayer-playbackRateControls u-flexColumn u-flexAlignCenter">
      <div className="t-label-1" id="video-speed">Video Speed:</div>
      <Pills
        groupLabelId="video-speed"
        options={PLAYBACK_RATE_OPTIONS}
        selected={playbackRate.toString()}
        onChange={onRateChange}
      />
    </div>
  );
}

VideoPlaybackRateControls.propTypes = propTypes;

export default VideoPlaybackRateControls;
