import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import VideoControls from 'components/molecules/VideoControls';
import VideoWrapper from 'components/molecules/VideoWrapper';

const propTypes = {
  source: PropTypes.string.isRequired,
  hasOptions: PropTypes.bool,
  height: PropTypes.string,
  progressStep: PropTypes.number,
  volumeStep: PropTypes.number,
  width: PropTypes.string,
  onError: PropTypes.func,
  onReady: PropTypes.func,
};

const defaultProps = {
  hasOptions: true,
  height: '100%',
  onError: undefined,
  onReady: undefined,
  progressStep: 100,
  volumeStep: 0.1,
  width: '100%',
};

function VideoPlayer({
  hasOptions,
  height,
  onError,
  onReady,
  progressStep,
  volumeStep,
  source,
  width,
}) {
  const containerRef = useRef(null);
  const videoRef = useRef(null);

  const containerStyle = {
    height,
    width,
  };

  return (
    <div
      ref={containerRef}
      className="u-flexColumn u-justifyCenter"
      style={containerStyle}
    >
      <VideoWrapper
        source={source}
        videoRef={videoRef}
      />
      <VideoControls
        containerRef={containerRef}
        hasOptions={hasOptions}
        progressStep={progressStep}
        videoRef={videoRef}
        volumeStep={volumeStep}
        onError={onError}
        onReady={onReady}
      />
    </div>
  );
}

VideoPlayer.propTypes = propTypes;
VideoPlayer.defaultProps = defaultProps;

export default VideoPlayer;
