import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Dropdown,
  Search,
} from '@makeably/creativex-design-system';
import Results from 'components/internal/explorer/Results';
import { renderBetaTag } from 'components/internal/shared';
import {
  getParams,
  redirectWithParamsObj,
  setParam,
} from 'utilities/url';

const resultProps = PropTypes.shape({
  id: PropTypes.number.isRequired,
  path: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
});

const optionProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
});

const propTypes = {
  input: PropTypes.string.isRequired,
  results: PropTypes.arrayOf(resultProps).isRequired,
  searchByOptions: PropTypes.arrayOf(optionProps).isRequired,
};

const NOTION_REFERENCE = 'https://www.notion.so/creativex/Data-Explorer-111f4499093d80eaa6b7fa4030aec0af';

function Explorer({
  input,
  results,
  searchByOptions,
}) {
  const params = getParams(window);
  const [search, setSearch] = useState(input);
  const [selectedOption, setSelectedOption] = useState(searchByOptions[0]);

  useEffect(() => {
    const searchBy = params.get('search_by');

    setSelectedOption((last) => {
      const found = searchByOptions.find(({ value }) => value === searchBy);
      return found ?? last;
    });
  }, [params]);

  const handleSearchBy = (option) => {
    setSelectedOption(option);
    setParam('search_by', option.value, params, window);
  };

  const handleSearch = () => {
    const paramsObj = {
      input: search,
      search_by: selectedOption.value,
    };
    redirectWithParamsObj(paramsObj, params, window);
  };

  const handleKeyDown = (key) => {
    if (key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <Card className="u-flexColumn u-gap-24">
      <div className="u-flexRow u-alignCenter u-gap-8">
        { renderBetaTag() }
        <a
          href={NOTION_REFERENCE}
          rel="noreferrer"
          target="_blank"
        >
          Questions?
        </a>
      </div>
      <div className="u-flexRow u-alignEnd u-gap-16">
        <Dropdown
          label="Search For"
          menuProps={{ size: 'medium' }}
          options={searchByOptions}
          selected={selectedOption}
          size="medium"
          onChange={handleSearchBy}
        />
        <Search
          placeholder="Enter Search"
          size="large"
          value={search}
          onChange={setSearch}
          onKeyDown={handleKeyDown}
        />
        <Button
          disabled={search?.length === 0}
          label="Search"
          onClick={handleSearch}
        />
      </div>
      <div>
        <div className="t-overline u-marginBottom-8">Results</div>
        <Results records={results} />
      </div>
    </Card>
  );
}

Explorer.propTypes = propTypes;

export default Explorer;
