import React, {
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  Stepper,
} from '@makeably/creativex-design-system';
import SelectableItemsTable from 'components/molecules/SelectableItemsTable';
import { useViewPage } from 'utilities/mixpanel';
import { getAuthenticityToken } from 'utilities/requests';
import { newSettingsLinkedPlatformAccountPath } from 'utilities/routes';
import styles from './Advertisers.module.css';

const stepProps = PropTypes.shape({
  label: PropTypes.string.isRequired,
  step: PropTypes.number.isRequired,
});
const advertiserProps = PropTypes.shape({
  advertiserId: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
});
const entityProps = PropTypes.shape({
  countryCode: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  profileId: PropTypes.string.isRequired,
});
const propTypes = {
  advertisers: PropTypes.arrayOf(advertiserProps).isRequired,
  currentStep: PropTypes.number.isRequired,
  entity: entityProps.isRequired,
  formUrl: PropTypes.string.isRequired,
  linkedAdvertisers: PropTypes.objectOf(PropTypes.bool).isRequired,
  nextUrl: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(stepProps).isRequired,
};

const headers = [
  {
    key: 'name',
    label: 'Advertiser Name',
  },
  {
    key: 'id',
    label: 'Advertiser ID',
  },
  {
    key: 'currency',
    label: 'Currency',
  },
  {
    key: 'countryCode',
    label: 'Country',
  },
];

function getItems(advertisers, linkedAdvertisers) {
  return advertisers.map((advertiser) => {
    const isLinked = Boolean(linkedAdvertisers[advertiser.advertiserId]);

    return {
      countryCode: { value: advertiser.country },
      currency: { value: advertiser.currency },
      disabled: { value: isLinked },
      name: { value: advertiser.name },
      id: { value: advertiser.advertiserId },
    };
  });
}

async function submitAdvertisers(entity, items, url) {
  if (items.length === 0) return;

  const advertisers = items.map((item) => ({
    api_id: item.id.value,
    name: item.name.value,
    currency: item.currency.value,
    country: item.countryCode.value,
  }));
  const entityData = {
    name: entity.name,
    profile_id: entity.profileId,
    country_code: entity.countryCode,
  };

  const formData = new FormData();
  formData.append('advertisers', JSON.stringify(advertisers));
  formData.append('authenticity_token', getAuthenticityToken());
  formData.append('entity', JSON.stringify(entityData));

  await fetch(url, {
    method: 'POST',
    body: formData,
  });
}

function Advertisers({
  advertisers,
  entity,
  currentStep,
  formUrl,
  linkedAdvertisers,
  nextUrl,
  steps,
}) {
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isDisabled = selectedItems.length === 0 || isSubmitting;

  useViewPage('advertisers');

  useEffect(() => {
    setItems(getItems(advertisers, linkedAdvertisers));
  }, [advertisers]);

  const handleContinue = async () => {
    setIsSubmitting(true);
    await submitAdvertisers(entity, selectedItems, formUrl);
    window.location.href = nextUrl;
  };

  return (
    <>
      <div className={styles.stepper}>
        <Stepper currentStep={currentStep} steps={steps} />
      </div>
      <h5>Select the Advertisers that you would like to connect to CreativeX</h5>
      <div className={styles.captions}>
        <div className="t-caption-1">Pre-checked advertisers have already been connected</div>
        <div className="t-caption-1">
          { `Advertisers Selected: ${selectedItems.length}` }
        </div>
      </div>
      <Card>
        <SelectableItemsTable
          headers={headers}
          items={items}
          selectedItems={selectedItems}
          checkDisabledItems
          onItemsSelect={setSelectedItems}
        />
      </Card>
      <div className={styles.buttons}>
        <div className="u-buttonGroup">
          <Button
            label="Cancel"
            url={newSettingsLinkedPlatformAccountPath()}
            variant="secondary"
          />
          <Button
            disabled={isDisabled}
            label="Continue"
            onClick={handleContinue}
          />
        </div>
      </div>
    </>
  );
}

Advertisers.propTypes = propTypes;

export default Advertisers;
