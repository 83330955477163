import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@makeably/creativex-design-system';
import styles from './SubmittedCoreAssets.module.css';

const propTypes = {
  erroredFilenames: PropTypes.arrayOf(PropTypes.string).isRequired,
  submitted: PropTypes.shape({
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        preview: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
    brand: PropTypes.string.isRequired,
    campaign: PropTypes.string.isRequired,
    partner: PropTypes.string.isRequired,
    measurementPartner: PropTypes.string,
  }).isRequired,
};

function icon(status) {
  if (status === 'failure') {
    return {
      color: 'red',
      name: 'exclamationCircle',
    };
  }

  return {
    color: 'green',
    name: 'checkCircle',
  };
}

function message(status) {
  if (status === 'failure') {
    return 'Core assets failed to upload: Previously uploaded with a different production partner.';
  }

  return 'Core assets successfully uploaded.';
}

function renderAssetSection(status, assetsByStatus, totalAssets) {
  const assets = assetsByStatus[status];

  if (assets.length === 0) return null;

  return (
    <>
      <div className={styles.section}>
        <Icon {...icon(status)} />
        <div className="t-label-1">
          { `(${assets.length}/${totalAssets}) ${message(status)}` }
        </div>
      </div>
      <div className={styles.assets}>
        { assets.map((asset) => (
          <img
            key={asset.url}
            alt="asset_preview"
            className={styles.asset}
            src={asset.preview || asset.url}
          />
        )) }
      </div>
    </>
  );
}

function SubmittedCoreAssets({
  erroredFilenames,
  submitted: {
    assets,
    brand,
    campaign,
    measurementPartner,
    partner,
  },
}) {
  const totalAssets = assets.length;
  const assetsByStatus = assets.reduce((assetType, asset) => {
    let type;
    if (erroredFilenames.includes(asset.filename)) {
      type = { failure: [...assetType.failure, asset] };
    } else {
      type = { success: [...assetType.success, asset] };
    }

    return {
      ...assetType,
      ...type,
    };
  }, {
    failure: [],
    success: [],
  });

  let subtitle = `${campaign} | ${brand} | ${partner}`;
  if (measurementPartner) {
    subtitle += ` | ${measurementPartner}`;
  }

  return (
    <div className={styles.container}>
      <div className="t-subtitle">
        { subtitle }
      </div>
      { renderAssetSection('failure', assetsByStatus, totalAssets) }
      { renderAssetSection('success', assetsByStatus, totalAssets) }
    </div>
  );
}

SubmittedCoreAssets.propTypes = propTypes;

export default SubmittedCoreAssets;
